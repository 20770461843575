import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  attachButton: {
    minWidth: '2rem',
    height: '2rem',
    padding: 0,
    background: '#F7F7F9',
    color: theme.palette.primary.main,
    marginRight: '1rem',
  },
  attachButtonFiles: {
    marginRight: 0,
  },
  icon: {
    height: '1rem',
  },
  buttonAndText: {
    display: 'flex',
    alignItems: 'center',
  },
  invoiceChip: {
    width: '12.8rem',
    height: '2rem',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'black',
    marginRight: '0.5rem',
  },

  deleteInvoiceIcon: {
    color: 'black',
    width: '1rem',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  files: {
    display: 'flex',
    flexDirection: 'column',
  },
  chipAndButton: {
    display: 'flex',
    '& .MuiChip-root': {
      backgroundColor: '#F7F7F9',
    },
    '&:not(:last-child)': {
      marginBottom: '0.5rem',
    },
  },
}));
