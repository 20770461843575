import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { DenyDialog } from 'components/DenyDialog';
import { withSnackbar } from 'notistack';
import useStyles from './RequestAction.css';

const RequestAction = ({
  userId,
  requestId,
  requestedBy,
  enqueueSnackbar,
  onApproveRequestAction,
  onDenyRequestAction,
  description,
  title,
}) => {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');

  const onApprove = useCallback(
    e => {
      e.preventDefault();
      setIsProcessing(true);
      onApproveRequestAction({
        requestId,
        userId,
        status: 'approved',
        requestedBy,
        description,
        title,
      })
        .then(() => {
          enqueueSnackbar('Hardware request successfully approved', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when approving hardware request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [enqueueSnackbar, onApproveRequestAction, requestId, userId, description, requestedBy, title],
  );

  const onDeny = useCallback(
    (e, addNote) => {
      e.preventDefault();
      setIsProcessing(true);
      onDenyRequestAction({
        requestId,
        userId,
        status: 'denied',
        requestedBy,
        description,
        title,
        note: addNote ? note : '',
      })
        .then(() => {
          enqueueSnackbar('Hardware request successfully denied', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when denying hardware request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      enqueueSnackbar,
      onDenyRequestAction,
      requestId,
      userId,
      description,
      requestedBy,
      title,
      note,
    ],
  );

  const onDenyOpenDialog = e => {
    e.preventDefault();
    setDialogOpen(true);
  };

  return (
    <div>
      <Button
        variant="contained"
        size="small"
        disabled={isProcessing}
        onClick={onApprove}
        className={classes.approveButton}
        data-cy="approveButton"
      >
        Approve
      </Button>
      <Button
        variant="contained"
        size="small"
        disabled={isProcessing}
        onClick={e => onDenyOpenDialog(e)}
        className={classes.denyButton}
        data-cy="denyButton"
      >
        Deny
      </Button>
      <DenyDialog
        setDialogOpen={setDialogOpen}
        type="hardware"
        setNote={setNote}
        onDeny={onDeny}
        isProcessing={isProcessing}
        isDialogOpen={isDialogOpen}
        note={note}
        requestedBy={requestedBy}
      />
    </div>
  );
};

RequestAction.propTypes = {
  userId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  onApproveRequestAction: PropTypes.func.isRequired,
  onDenyRequestAction: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  description: PropTypes.string,
  title: PropTypes.string,
  requestedBy: PropTypes.any,
};

export default withSnackbar(RequestAction);
