import React, { useEffect, useState } from 'react';
import { Typography, Paper } from '@material-ui/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useAuth } from '../../../auth';
import { StyledToggleButtonGroup, useStyles } from './VacationTile.css';
import VacationTileCurrent from './VacationTileCurrent';
import VacationTilePrevious from './VacationTilePrevious';

const tileStates = {
  PREVIOUS: 'Old',
  CURRENT: 'Current',
  NEXT: 'Next',
};

const VacationTile = () => {
  const classes = useStyles();
  const { user, tenant } = useAuth();
  const TOTAL_VACATION_DAYS = tenant?.vacation.totalDays;

  const defaultTileState =
    user.availableDays > TOTAL_VACATION_DAYS ? tileStates.PREVIOUS : tileStates.CURRENT;
  const [tileState, setTileState] = useState();

  useEffect(() => {
    setTileState(defaultTileState);
  }, [defaultTileState]);

  const handleTileState = (_event, newState) => {
    if (newState !== null) {
      setTileState(newState);
    }
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.header}>
        <Typography className={classes.title} variant="body1" component="div">
          Vacation
        </Typography>
        <StyledToggleButtonGroup
          value={tileState}
          exclusive
          onChange={handleTileState}
          aria-label="text alignment"
        >
          <ToggleButton
            value={tileStates.PREVIOUS}
            className={classes.toggleButton}
            data-cy="previousVacationTab"
          >
            {user.availableDays < 0 ? tileStates.CURRENT : tileStates.PREVIOUS}
          </ToggleButton>
          <ToggleButton
            value={tileStates.CURRENT}
            className={classes.toggleButton}
            data-cy="currentVacationTab"
          >
            {user.availableDays < 0 ? tileStates.NEXT : tileStates.CURRENT}
          </ToggleButton>
        </StyledToggleButtonGroup>
      </div>

      {tileState === tileStates.CURRENT ? (
        <div className={classes.wrapper}>
          <VacationTileCurrent user={user} />
        </div>
      ) : (
        <div className={classes.wrapper}>
          <VacationTilePrevious user={user} />
        </div>
      )}
    </Paper>
  );
};

export default VacationTile;
