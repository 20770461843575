import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { chain } from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Collapse,
  Box,
  Typography,
  Link,
} from '@material-ui/core';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getUsersAssignedManagerEmail } from 'utils/functions/userEmailsHelpers';
import { KeyboardArrowUp, KeyboardArrowDown, AccessTime, Done } from '@material-ui/icons';
import { v1 as uuidv1 } from 'uuid';
import { BudgetCosts, InvoiceUpload } from 'components/BudgetCosts';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from '../../../components/Portlets';
import { Status } from '../../../components/Status';
import { DarkTooltip } from '../../../components/DarkTooltip';
import { formatDate, snapToArray } from '../../../utils';
import { STATUS_COLORS } from '../../../constants/requestStatus';
import { FirebaseContext } from '../../../utils/firebase';
import { addEmailToCollection, formatEmailEduBudget } from '../../../utils/functions/emailHelpers';
import { useAuth } from '../../../auth';
import RequestAction from './RequestAction';
import useStyles from './TeamEduBudgetRequestsTable.css';
import Controls from './Controls';
import { useManagerEmails } from '../../../hooks/useManagerEmails';
import { InlineEdit } from '../../../components/InlineEdit';

const eduBudgetCollection = 'edu_budget_requests';

const Row = props => {
  const {
    status,
    requestedAt,
    type,
    title,
    link,
    description,
    requestId,
    onCancelRequestAction,
    onDenyRequestAction,
    onApproveRequestAction,
    onCompleteRequestAction,
    userId,
    totalAmount,
    requestedBy,
    filteredRequests,
    updateRequests,
    userAvailableEduBudget,
    hideActionsColumnAndCell,
    startDate,
    endDate,
    budgetCosts,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);
  const showAttachment =
    (status === 'approved' || status === 'completed' || status === 'canceled') &&
    !(type === 'Conference' || type === 'Workshop');

  const formatRequestedBy = ({ firstName, lastName } = {}) => {
    return firstName && lastName ? (
      <div>
        {firstName} {lastName}
      </div>
    ) : (
      <div>{firstName || lastName}</div>
    );
  };

  const updateEduBudgetRequests = (id, data) => {
    updateRequests({ requestId: id, ...data });
    return updateDoc(doc(database, eduBudgetCollection, id), data);
  };

  return (
    <>
      <TableRow className={classes.openRow} data-cy={description} hover>
        <TableCell className={cx(classes.openCell, classes.arrowCell)}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.openCell}>
          <div className={classes.statusWrapper}>
            <Status className={classes.status} color={STATUS_COLORS[status]} size="sm" />
            {status}
          </div>
        </TableCell>
        <TableCell className={classes.openCell}>{formatRequestedBy(requestedBy)}</TableCell>
        <TableCell className={classes.openCell}>{type}</TableCell>
        <TableCell className={classes.openCell}>{formatDate(requestedAt)}</TableCell>
        <TableCell className={classes.openCell}>{title}</TableCell>
        <TableCell className={classes.openCell}>
          <div className={classes.totalAmount}>
            {(status === 'pending' || status === 'approved') && (
              <DarkTooltip
                title="Preliminary amount - may differ until the 
              request is completed"
              >
                <AccessTime className={classes.accessTime} />
              </DarkTooltip>
            )}
            {status === 'completed' && <Done className={classes.done} />}

            {status === 'pending' || status === 'approved' ? (
              <InlineEdit
                id={requestId}
                name="totalAmount"
                value={totalAmount || 0}
                onUpdate={updateEduBudgetRequests}
                tooltipTitle="Click to edit."
                min={0}
              />
            ) : (
              totalAmount
            )}
          </div>
        </TableCell>
        <TableCell className={classes.openCell}>
          <DarkTooltip title="The available education budget at the moment of request creation.">
            <span>{userAvailableEduBudget}</span>
          </DarkTooltip>
        </TableCell>
        {hideActionsColumnAndCell(filteredRequests) && (
          <TableCell className={cx(classes.openCell, classes.actions)}>
            {status === 'pending' || status === 'approved' ? (
              <RequestAction
                userId={userId}
                requestId={requestId}
                status={status}
                type={type}
                title={title}
                link={link}
                totalAmount={totalAmount}
                requestedBy={requestedBy}
                onApproveRequestAction={onApproveRequestAction}
                onDenyRequestAction={onDenyRequestAction}
                onCancelRequestAction={onCancelRequestAction}
                onCompleteRequestAction={onCompleteRequestAction}
                description={description}
              />
            ) : (
              <div />
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow className={classes.expendableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.collapsed}>
              <Box className={showAttachment ? classes.titleWrapper : classes.titleNoAttachment}>
                {!showAttachment && (
                  <Typography variant="subtitle1" className={classes.collapseTitle}>
                    Request Details
                  </Typography>
                )}

                <Box className={classes.detailsWrapper}>
                  {showAttachment && (
                    <Typography variant="subtitle1" className={classes.collapseTitle}>
                      Request Details
                    </Typography>
                  )}

                  {startDate && endDate && (
                    <>
                      <Typography className={classes.sectionTitle} variant="body1">
                        Start date / End date
                      </Typography>
                      <Typography variant="body2" className={classes.section}>
                        {formatDate(startDate)} / {formatDate(endDate)}
                      </Typography>
                    </>
                  )}
                  <Typography className={classes.sectionTitle} variant="body1">
                    Link
                  </Typography>
                  <Typography variant="body2" className={classes.section}>
                    <Link underline="always" href={link}>
                      {link}
                    </Link>
                  </Typography>
                  <Typography className={classes.sectionTitle} variant="body1">
                    Description
                  </Typography>
                  <Typography variant="body2" className={classes.section}>
                    {description}
                  </Typography>
                </Box>
                {showAttachment && (
                  <Box className={classes.attachmentWrapper}>
                    <Typography variant="body1" className={classes.attachmentTitle}>
                      Attachment
                    </Typography>
                    {(budgetCosts[0] ? budgetCosts[0].invoiceIds.length === 0 : false) &&
                      status !== 'approved' && (
                        <Typography variant="body2" className={classes.noFiles}>
                          No files uploaded
                        </Typography>
                      )}
                    <InvoiceUpload
                      budgetCostName="Bill"
                      eduBudgetRequestId={requestId}
                      status={status}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {(type === 'Conference' || type === 'Workshop') && (
              <BudgetCosts
                budgetCosts={budgetCosts}
                eduBudgetRequestId={requestId}
                eduBudgetRequestStatus={status}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const TeamEduBudgetRequestsTable = () => {
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const { user: authenticatedUser } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState(['pending', 'approved']);
  const [nameFilter, setNameFilter] = useState('');
  const [eduBudgetPeriod, setEduBudgetPeriod] = useState('');
  const managerEmails = useManagerEmails(false, false);
  const emailsWithOfficeManager = useManagerEmails(true, false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const database = getFirestore(firebaseApp);

  const filterRequests = requests => {
    if (!requests) {
      return null;
    }

    const filterByName = ({ requestedBy: { firstName, lastName } }) => {
      const fields = [firstName, lastName, `${firstName} ${lastName}`];

      return fields.some(string => {
        return string.toLowerCase().startsWith(nameFilter.toLowerCase());
      });
    };

    const filterByPeriod = ({ requestedAt }) => {
      if (!eduBudgetPeriod) {
        return true;
      }

      return (
        eduBudgetPeriod.start.isBefore(requestedAt.toDate()) &&
        eduBudgetPeriod.end.isSameOrAfter(requestedAt.toDate())
      );
    };

    return chain(requests)
      .filter(filterByName)
      .filter(filterByPeriod)
      .value();
  };

  const getEduBudgetPeriods = years => {
    const year = moment().year();
    return Array.from(Array(years), (_, key) => {
      const start = moment([year, 0, 1]).subtract(key, 'year');
      const end = moment([year, 11, 31]).subtract(key, 'year');
      return { start, end, key };
    });
  };

  const updateRequests = input => {
    const update = requests.map(request => {
      return request.id === input.requestId ? { ...request, ...input } : request;
    });
    setRequests(update);
  };

  const updateRequestStatusWithNote = async (requestId, status, processedBy, note) => {
    updateDoc(doc(database, eduBudgetCollection, requestId), {
      status,
      processedBy,
      note,
    });
  };

  const updateRequestStatus = async (requestId, status, processedBy) => {
    updateDoc(doc(database, eduBudgetCollection, requestId), {
      status,
      processedBy,
    });
  };

  const updateUserWithNotification = async ({ userId, requestId, status }) => {
    const userRef = doc(database, 'users', userId);
    const userSnap = await getDoc(userRef);

    const user = userSnap.data();

    const createNotification = (status, requestId) => ({
      id: uuidv1(),
      status,
      requestId,
      isNew: true,
      createdAt: Date.now(),
      type: 'request',
      for: 'education budget',
    });

    const notification = createNotification(status, requestId);
    const notifications = [...user.notifications, notification];
    const userUpdate = { notifications };

    return updateDoc(doc(database, 'users', userId), userUpdate);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateUserEduBudget = useCallback(
    async (userId, amount) => {
      const calcEduBudget = available => available - amount;
      const userRef = doc(database, 'users', userId);
      const userSnap = await getDoc(userRef);

      const user = userSnap.data();

      return updateDoc(doc(database, 'users', userId), {
        availableEduBudget: calcEduBudget(user.availableEduBudget),
      });
    },
    [database],
  );

  const sendUpdateEmail = async (
    requestedBy,
    processedBy,
    type,
    title,
    link,
    description,
    status,
    note,
    addMessage = false,
    sendToOfficeManager = false,
  ) => {
    const relevantEmails = (sendToOfficeManager ? emailsWithOfficeManager : managerEmails) || [];
    const usersManager = await getUsersAssignedManagerEmail(
      requestedBy,
      authenticatedUser,
      database,
    );
    const emailObject = formatEmailEduBudget(
      [requestedBy.email, ...relevantEmails, ...usersManager],
      requestedBy.firstName,
      requestedBy.lastName,
      type,
      title,
      description,
      link,
      status,
      processedBy,
      note,
      addMessage,
    );
    addEmailToCollection(firebaseApp, emailObject);
  };

  const onApproveRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    title,
    link,
    type,
    description,
  }) => {
    try {
      await updateRequestStatus(requestId, status, authenticatedUser.email);
      await updateUserWithNotification({ userId, requestId, status });
      updateRequests({ requestId, status });
      sendUpdateEmail(
        requestedBy,
        authenticatedUser.email,
        type,
        title,
        link,
        description,
        status,
        '', // note
        false, // add reminder for making out of office request
        true, // send email fo office manager
      );
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onDenyRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    title,
    link,
    type,
    description,
    note,
  }) => {
    try {
      await updateRequestStatusWithNote(requestId, status, authenticatedUser.email, note);
      await updateUserWithNotification({ userId, requestId, status });
      updateRequests({ requestId, status });
      sendUpdateEmail(
        requestedBy,
        authenticatedUser.email,
        type,
        title,
        link,
        description,
        status,
        note,
      );
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onCancelRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    title,
    link,
    type,
    description,
  }) => {
    try {
      await updateRequestStatus(requestId, status, authenticatedUser.email);
      await updateUserWithNotification({ userId, requestId, status });
      updateRequests({ requestId, status });
      sendUpdateEmail(requestedBy, authenticatedUser.email, type, title, link, description, status);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onCompleteRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    title,
    link,
    type,
    description,
    totalAmount,
  }) => {
    const addMessage = type === 'Workshop' || type === 'Conference';

    try {
      await updateRequestStatus(requestId, status, authenticatedUser.email);
      await updateUserWithNotification({ userId, requestId, status });
      await updateUserEduBudget(userId, totalAmount);
      updateRequests({ requestId, status });
      sendUpdateEmail(
        requestedBy,
        authenticatedUser.email,
        type,
        title,
        link,
        description,
        status,
        '',
        addMessage,
        true,
      );
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    let queryRequests = query(
      collection(database, eduBudgetCollection),
      where('userTenant', '==', authenticatedUser.tenant.id),
      orderBy('requestedAt', 'desc'),
    );

    if (statusFilter.length !== 0) {
      queryRequests = query(
        collection(database, eduBudgetCollection),
        where('userTenant', '==', authenticatedUser.tenant.id),
        where('status', 'in', statusFilter),
        orderBy('requestedAt', 'desc'),
      );
    }

    getDocs(queryRequests)
      .then(snapshot => {
        setRequests(snapToArray(snapshot));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [database, statusFilter, page, rowsPerPage, authenticatedUser.tenant.id]);

  const filteredRequests = filterRequests(requests);

  const hideActionsColumnAndCell = filteredRequests =>
    filteredRequests.filter(
      request => request.status === 'pending' || request.status === 'approved',
    ).length > 0;

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      <PortletHeader className={classes.header} noDivider>
        <PortletLabel
          subtitle={`${filteredRequests.length} in total`}
          title="Latest Requests"
          className={classes.title}
        />
        <Controls
          periods={getEduBudgetPeriods(2)}
          statusFilter={statusFilter}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          setStatusFilter={setStatusFilter}
          eduBudgetPeriod={eduBudgetPeriod}
          setEduBudgetPeriod={setEduBudgetPeriod}
          setPage={setPage}
        />
      </PortletHeader>
      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} className={classes.portletContent} noPadding>
          {filteredRequests.length ? (
            <>
              <Table stickyHeader style={{ borderSpacing: '0px 0px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Status</TableCell>
                    <TableCell>Requested by</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Requested at</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Total Amount (EUR)</TableCell>
                    <TableCell>
                      <DarkTooltip title="The available education budget at the moment of request creation.">
                        <span>Available Edu Budget (EUR)</span>
                      </DarkTooltip>
                    </TableCell>

                    {(['pending', 'all', 'approved', 'completed'].some(item =>
                      statusFilter.includes(item),
                    ) ||
                      statusFilter.length === 0) &&
                      hideActionsColumnAndCell(filteredRequests) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      ({
                        id: requestId,
                        userId,
                        requestedBy = {},
                        description,
                        title,
                        type,
                        link,
                        requestedAt,
                        totalAmount = {},
                        status = 'pending',
                        userAvailableEduBudget,
                        startDate,
                        endDate,
                        budgetCosts,
                      }) => (
                        <Row
                          requestId={requestId}
                          userId={userId}
                          description={description}
                          requestedBy={requestedBy}
                          type={type}
                          title={title}
                          link={link}
                          requestedAt={requestedAt}
                          status={status}
                          totalAmount={totalAmount}
                          userAvailableEduBudget={userAvailableEduBudget}
                          onCancelRequestAction={onCancelRequestAction}
                          onApproveRequestAction={onApproveRequestAction}
                          onDenyRequestAction={onDenyRequestAction}
                          onCompleteRequestAction={onCompleteRequestAction}
                          updateRequests={updateRequests}
                          filteredRequests={filteredRequests}
                          hideActionsColumnAndCell={hideActionsColumnAndCell}
                          startDate={startDate}
                          endDate={endDate}
                          key={requestId}
                          budgetCosts={budgetCosts}
                        />
                      ),
                    )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={filteredRequests.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default TeamEduBudgetRequestsTable;
