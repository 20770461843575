import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { Divider, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  footerWrapper: {
    padding: '8px 32px',
  },
  company: {},
}));

const Footer = ({ className }) => {
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const currentYear = new Date().getFullYear();
  return (
    <div className={rootClassName}>
      <Divider />
      <div className={classes.footerWrapper}>
        <Typography className={classes.company} variant="body1">
          &copy; ProductDock {currentYear}
        </Typography>
        <Typography variant="caption" className={classes.infoLine}>
          Tech experts in digital transformation. We develop cutting-edge software.
        </Typography>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string.isRequired,
};

export default Footer;
