export const getPreviousVacationPeriod = () => {
  const currentDate = new Date();

  const previousPeriod = `01.01.${currentDate.getFullYear() -
    1} - 30.06.${currentDate.getFullYear()}`;

  return previousPeriod;
};

export const getCurrentVacationPeriod = () => {
  const currentDate = new Date();

  const currentPeriod = `01.01.${currentDate.getFullYear()} - 30.06.${currentDate.getFullYear() +
    1}`;

  return currentPeriod;
};

export const getNextVacationPeriod = () => {
  const currentDate = new Date();

  const nextPeriod = `01.01.${currentDate.getFullYear() + 1} - 30.06.${currentDate.getFullYear() +
    2}`;

  return nextPeriod;
};
