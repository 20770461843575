import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { DenyDialog } from 'components/DenyDialog';
import { withSnackbar } from 'notistack';
import useStyles from './RequestAction.css';

const RequestAction = ({
  userId,
  requestId,
  enqueueSnackbar,
  status,
  vacationFrom,
  vacationTo,
  onApproveRequestAction,
  onDenyRequestAction,
  onCancelRequestAction,
  noOfDays,
  requestedBy,
  description,
}) => {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');

  const onApprove = useCallback(
    e => {
      e.preventDefault();
      setIsProcessing(true);
      onApproveRequestAction({
        requestId,
        userId,
        status: 'approved',
        requestedBy,
        vacationFrom,
        vacationTo,
        noOfDays,
        description,
      })
        .then(() => {
          enqueueSnackbar('Vacation request successfully approved', {
            variant: 'success',
          });
        })
        .catch(error => {
          console.error(error);
          enqueueSnackbar('Error when approving vacation request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      onApproveRequestAction,
      requestId,
      userId,
      requestedBy,
      vacationFrom,
      vacationTo,
      noOfDays,
      description,
      enqueueSnackbar,
    ],
  );

  const onDeny = useCallback(
    (e, addNote) => {
      e.preventDefault();
      setIsProcessing(true);
      onDenyRequestAction({
        requestId,
        userId,
        status: 'denied',
        noOfDays,
        requestedBy,
        vacationFrom,
        vacationTo,
        description,
        note: addNote ? note : '',
      })
        .then(() => {
          enqueueSnackbar('Vacation request successfully denied', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when denying vacation request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      enqueueSnackbar,
      onDenyRequestAction,
      noOfDays,
      requestId,
      userId,
      description,
      requestedBy,
      vacationFrom,
      vacationTo,
      note,
    ],
  );

  const onDenyOpenDialog = e => {
    e.preventDefault();
    setDialogOpen(true);
  };

  const onCancel = useCallback(
    e => {
      e.preventDefault();
      setIsProcessing(true);
      onCancelRequestAction({
        requestId,
        userId,
        status: 'canceled',
        noOfDays,
        startDate: vacationFrom,
        endDate: vacationTo,
        requestedBy,
        description,
      })
        .then(() => {
          enqueueSnackbar('Vacation request successfully canceled', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when canceling vacation request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      enqueueSnackbar,
      onCancelRequestAction,
      noOfDays,
      requestId,
      userId,
      vacationFrom,
      vacationTo,
      description,
      requestedBy,
    ],
  );

  return (
    <div>
      {status === 'pending' && (
        <Button
          variant="contained"
          size="small"
          disabled={isProcessing}
          onClick={onApprove}
          className={classes.approveButton}
          data-cy="approveButton"
        >
          Approve
        </Button>
      )}
      <Button
        variant="contained"
        size="small"
        disabled={isProcessing}
        onClick={e => (status === 'pending' ? onDenyOpenDialog(e) : onCancel(e))}
        className={classes.denyButton}
        data-cy={status === 'pending' ? 'denyButton' : 'cancelButton'}
      >
        {status === 'pending' ? 'Deny' : 'Cancel'}
      </Button>
      <DenyDialog
        setDialogOpen={setDialogOpen}
        type="vacation"
        setNote={setNote}
        onDeny={onDeny}
        isProcessing={isProcessing}
        isDialogOpen={isDialogOpen}
        note={note}
        requestedBy={requestedBy}
      />
    </div>
  );
};

RequestAction.propTypes = {
  userId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  vacationFrom: PropTypes.object.isRequired,
  vacationTo: PropTypes.object.isRequired,
  onApproveRequestAction: PropTypes.func.isRequired,
  onDenyRequestAction: PropTypes.func.isRequired,
  onCancelRequestAction: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  noOfDays: PropTypes.number.isRequired,
  requestedBy: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
};

export default withSnackbar(RequestAction);
