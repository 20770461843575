import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  getAuth,
  signInWithPopup,
  onIdTokenChanged,
  signOut as firebaseSignOut,
  GoogleAuthProvider,
  getIdToken,
} from 'firebase/auth';
import { doc, getDoc, getFirestore, setDoc } from 'firebase/firestore';

const googleAuthProvider = new GoogleAuthProvider();
googleAuthProvider.addScope('https://www.googleapis.com/auth/calendar.events');

const defaultSession = { loading: true };

const createUser = _user => {
  const userKeys = 'displayName,email,emailVerified,isAnonymous,phoneNumber,photoURL,uid'.split(
    ',',
  );

  const user = userKeys.reduce(
    (prev, key) => ({
      ...prev,
      [key]: _user[key] === null ? undefined : _user[key],
    }),
    {},
  );

  const providerId = user.isAnonymous ? 'anonymous' : _user.providerData[0].providerId;

  user.authProvider = providerId;

  // Convert to Date objects
  const { creationTime, lastSignInTime } = _user.metadata;
  user.creationTime = new Date(creationTime);
  user.lastSignInTime = new Date(lastSignInTime);

  // Build a fake username
  if (!user.displayName) {
    user.displayName = `USER_${user.uid.substr(0, 6)}`;
  }
  return user;
};

const getUserDatabase = async ({ uid, ...rest }, database) => {
  const userRef = doc(database, 'users', uid);
  const userSnap = await getDoc(userRef);

  const user = userSnap.data();

  return { uid, ...rest, ...user };
};

const updateUserDatabase = async (user, database) => {
  const {
    displayName = '',
    email,
    phoneNumber = '',
    photoURL = '',
    uid,
    roles = { member: true },
    notifications = [],
    availableDays = 0,
  } = user;

  const [firstName, lastName] = displayName.split(' ');

  const userUpdate = {
    notifications,
    firstName,
    lastName,
    displayName,
    email,
    phoneNumber,
    photoURL,
    roles,
    availableDays,
  };

  await setDoc(doc(database, 'users', uid), userUpdate, { merge: true });
};

const loadUserFromDatabase = async (uid, database) => {
  const userRef = doc(database, 'users', uid);
  const userSnap = await getDoc(userRef);

  const user = userSnap.data();

  return userSnap.exists ? { ...user, uid: userSnap.id } : {};
};

const useFirebaseAuth = firebaseApp => {
  const [session, setSession] = useState(defaultSession);
  const [signInError, setSignInError] = useState(null);
  const [userToken, setUserToken] = useState('');
  const [tenant, setTenant] = useState(null);
  const database = getFirestore(firebaseApp);

  const auth = useMemo(() => getAuth(firebaseApp), [firebaseApp]);

  const onSignInError = useCallback(
    error => {
      setSignInError(error);
    },
    [setSignInError],
  );

  const signIn = useCallback(() => {
    setSignInError(null);
    return signInWithPopup(auth, googleAuthProvider).catch(onSignInError);
  }, [auth, setSignInError, onSignInError]);

  const signOut = useCallback(() => firebaseSignOut(auth), [auth]);

  const updateUserTenant = useCallback(
    async (uid, tenant) => {
      const userUpdate = {
        tenant,
      };

      await setDoc(doc(database, 'users', uid), userUpdate, { merge: true });

      window.location.reload(true);
    },
    [database],
  );

  useEffect(
    () =>
      onIdTokenChanged(auth, async _user => {
        const isSignedIn = !!_user;
        let user = isSignedIn ? createUser(_user) : {};
        // User is signed in or token was refreshed.
        if (isSignedIn) {
          const temp = await getIdToken(_user);
          setUserToken(temp);
          user = await getUserDatabase(user, database);
          await updateUserDatabase(user, database);
          user = await loadUserFromDatabase(user.uid, database);
        }
        const hasTenant = user.tenant || false;
        if (hasTenant) {
          const tenantRef = doc(database, 'tenants', user.tenant.id);
          const userSnap = await getDoc(tenantRef);

          const tenant = userSnap.data();
          setTenant(tenant);
        }
        const isManager =
          user.roles &&
          (user.roles.officeManager || user.roles.manager || user.roles.accountManager);
        setSession({ isSignedIn, hasTenant, isManager, user, _user });
      }),
    [auth, setSession, database],
  );

  const results = useMemo(
    () => ({
      ...session,
      signInError,
      firebaseApp,
      signIn,
      signOut,
      updateUserTenant,
      userToken,
      tenant,
    }),
    [session, signInError, firebaseApp, signIn, signOut, updateUserTenant, userToken, tenant],
  );

  return results;
};

export default useFirebaseAuth;
