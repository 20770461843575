import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  endDate: {
    display: 'block',
  },
  portletRoot: { borderTop: 'none', marginTop: '20%' },
  portletContent: {
    padding: '0 1.5rem',
    background: 'transparent',
    display: 'flex',
    flexDirection: 'column',
  },
  toolbarButtons: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 700,
    marginTop: theme.spacing(2),
    fontSize: '1rem',
  },
}));
