import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { TextField } from '@material-ui/core';
import useStyles from './NotesEdit.css';

const NotesEdit = ({ id, value, name, onUpdate, enqueueSnackbar, status }) => {
  const [editingValue, setEditingValue] = useState(value);
  const classes = useStyles();

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  const handleChange = event => {
    setEditingValue(event.target.value);
  };

  const update = async value => {
    try {
      await onUpdate(id, { [name]: value });
      enqueueSnackbar('Successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const onEnter = async event => {
    if (!event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      if (value !== event.target.value) {
        update(event.target.value);
      }
    } else if (event.shiftKey && event.key === 'Enter') {
      setEditingValue(event.target.value);
    }
  };

  const handleBlur = async event => {
    if (value !== event.target.value) {
      update(event.target.value);
    }
  };

  return status === 'approved' || status === 'pending' ? (
    <div className={classes.field}>
      <TextField
        multiline
        maxRows="6"
        value={editingValue || ''}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder="Enter a note"
        onKeyPress={onEnter}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ maxLength: 160 }}
        fullWidth
      />
      <div className={classes.counter}>{editingValue.length}/160</div>
    </div>
  ) : (
    <div className={editingValue === '' ? classes.notes : ''}>
      {editingValue === '' ? 'No notes added' : editingValue}
    </div>
  );
};

export default withSnackbar(NotesEdit);
