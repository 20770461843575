import React from 'react';
import { Grid } from '@material-ui/core';
import OutOfOfficesTable from './OutOfOfficeRequestsTable';
import useStyles from './OutOfOfficeRequestsView.css';

const OutOfOfficesView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <OutOfOfficesTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default OutOfOfficesView;
