import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'auth';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { getCurrentVacationPeriod, getPreviousVacationPeriod } from './getVacationPeriods';
import { useStyles } from './VacationTile.css';
import { DarkTooltip } from '../../../components/DarkTooltip';

const VacationTilePrevious = ({ user }) => {
  const { tenant } = useAuth();
  const classes = useStyles();
  const TOTAL_VACATION_DAYS = tenant?.vacation.totalDays;

  const [previousVacationPeriod, setPreviousVacationPeriod] = useState('');
  const [currentVacationPeriod, setCurrentVacationPeriod] = useState('');

  useEffect(() => {
    setCurrentVacationPeriod(getCurrentVacationPeriod());
  }, [setCurrentVacationPeriod]);

  useEffect(() => {
    setPreviousVacationPeriod(getPreviousVacationPeriod());
  }, [setPreviousVacationPeriod]);

  return (
    <>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Typography className={classes.value} variant="body1">
            <span>
              {user.availableDays > TOTAL_VACATION_DAYS
                ? TOTAL_VACATION_DAYS - user.availableDays - TOTAL_VACATION_DAYS * -1
                : TOTAL_VACATION_DAYS}
              {TOTAL_VACATION_DAYS - user.availableDays === 1 || user.availableDays === -1
                ? ' day '
                : ' days '}
              used
            </span>
          </Typography>
          {user.availableDays > TOTAL_VACATION_DAYS || user.availableDays >= 0 ? (
            <Typography className={classes.bottomLabel} variant="body2" component="div">
              {previousVacationPeriod}
            </Typography>
          ) : (
            user.availableDays < 0 && (
              <Typography className={classes.bottomLabel} variant="body2" component="div">
                {currentVacationPeriod}
              </Typography>
            )
          )}
        </div>
      </div>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={75}
          thickness={0.5}
          className={
            user.availableDays > TOTAL_VACATION_DAYS
              ? classes.progressSecondary
              : classes.progressTransparent
          }
          variant="determinate"
          value={((TOTAL_VACATION_DAYS - user.availableDays) * 100) / TOTAL_VACATION_DAYS}
        />
        <DarkTooltip
          title={(() => {
            if (user.availableDays > TOTAL_VACATION_DAYS) {
              return 'Total number of available days';
            }
            if (user.availableDays >= 0) {
              return 'No days left for the old period';
            }
            if (user.availableDays < 0) {
              return 'No days left for the current period';
            }

            return '';
          })()}
        >
          <Box
            top={7}
            left={7}
            bottom={7}
            right={7}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.counterCircle}
          >
            <Typography className={classes.counterUsed} variant="subtitle1" id="oldVacationDays">
              {user.availableDays > TOTAL_VACATION_DAYS ? (
                `${(TOTAL_VACATION_DAYS - user.availableDays) * -1}`
              ) : (
                <span className={classes.counterDisabled}>0</span>
              )}
            </Typography>
          </Box>
        </DarkTooltip>
      </Box>
    </>
  );
};

VacationTilePrevious.propTypes = {
  user: PropTypes.object,
};

VacationTilePrevious.defaultProps = {
  user: {
    availableDays: 0,
  },
};

export default VacationTilePrevious;
