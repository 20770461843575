import React from 'react';
import { Grid } from '@material-ui/core';
import VacationRequestsTable from './VacationRequestsTable';
import useStyles from './VacationRequestsView.css';

const VacationsView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <VacationRequestsTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default VacationsView;
