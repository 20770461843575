import React, { useState, useEffect, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

import { getApps, initializeApp } from 'firebase/app';

const getFirebaseApp = (firebaseConfig, firebaseAppName) => {
  const firebaseApps = getApps();
  return (
    firebaseApps.find(a => a.name === firebaseAppName) ||
    initializeApp(firebaseConfig, firebaseAppName)
  );
};

const useFirebaseApp = (firebaseConfig, firebaseAppName) => {
  const [firebaseApp, setFirebaseApp] = useState(() =>
    getFirebaseApp(firebaseConfig, firebaseAppName),
  );

  useEffect(() => {
    setFirebaseApp(() => getFirebaseApp(firebaseConfig, firebaseAppName));
  }, [firebaseConfig, firebaseAppName]);

  return firebaseApp;
};

const FirebaseContext = React.createContext();
const providerSignature = Symbol('FirebaseProvider');

const FirebaseProvider = ({ firebaseConfig, firebaseAppName, children }) => {
  const firebaseApp = useFirebaseApp(firebaseConfig, firebaseAppName);
  const firebaseContext = useMemo(() => ({ signature: providerSignature, firebaseApp }), [
    firebaseApp,
  ]);

  return <FirebaseContext.Provider value={firebaseContext}>{children}</FirebaseContext.Provider>;
};

FirebaseProvider.propTypes = {
  firebaseConfig: PropTypes.shape({
    apiKey: PropTypes.string.isRequired,
    authDomain: PropTypes.string.isRequired,
    projectId: PropTypes.string.isRequired,
  }).isRequired,
  firebaseAppName: PropTypes.string,
  children: PropTypes.element.isRequired,
};

FirebaseProvider.defaultProps = {
  firebaseAppName: '[DEFAULT]',
};

const useFirebase = () => {
  const { signature, firebaseApp } = useContext(FirebaseContext) || {};
  if (signature !== providerSignature) {
    throw new Error('useFirebase must be a descendant of <FirebaseProvider/>');
  }
  return firebaseApp;
};

export { useFirebase, FirebaseProvider, FirebaseContext };
