/* eslint-disable  no-param-reassign */
import React from 'react';
import {
  Button,
  TextField,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
  Typography,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';
import _ from 'lodash';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  Portlet,
  PortletToolbar,
  PortletContent,
  PortletFooter,
} from '../../../components/Portlets';
import { TextFieldComponent } from '../../../components/TextFieldComponent';
import { useStyles } from './OutOfOfficeForm.css';

const listRequestTypes = [
  'Sick Leave',
  'Conference',
  'Workshop',
  'Patron Saint Day',
  'Moving',
  'Wedding',
  'Funeral',
  'Other',
];

const OutOfOfficeForm = ({
  formProps: {
    values,
    errors,
    isValid,
    handleBlur,
    touched,
    dirty,
    setFieldValue,
    submitForm,
    handleChange,
    handleReset,
  },
}) => {
  const classes = useStyles();

  const onSubmitButtonClick = async () => {
    await submitForm();
  };

  const onStartDateChange = (startDate, endDate) => {
    setFieldValue('startDate', startDate);
    if (!endDate) {
      endDate = startDate;
      setFieldValue('endDate', endDate);
    } else if (endDate < startDate) {
      endDate = startDate;
      setFieldValue('endDate', endDate);
    }
  };

  const onEndDateChange = (startDate, endDate) => {
    setFieldValue('endDate', endDate);

    if (endDate < startDate) {
      startDate = endDate;
      setFieldValue('startDate', startDate);
    }
  };

  const handleSelectChange = event => {
    setFieldValue('requestType', event.target.value);
  };

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      <PortletContent className={classes.portletContent}>
        <Typography variant="body2" component="span" className={classes.title}>
          Out Of Office Request
        </Typography>
        <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel disabled id="select-standard-label">
            Type
          </InputLabel>
          <Select
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
            }}
            labelId="select-standard-label"
            id="select-standard"
            value={values.requestType || ''}
            onChange={handleSelectChange}
            label="Type"
            data-cy="outOfOfficeType"
          >
            {listRequestTypes.map(listRequestType => (
              <MenuItem key={listRequestType} value={listRequestType}>
                {listRequestType}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <KeyboardDatePicker
              fullWidth
              variant="inline"
              autoOk
              format="DD.MM.YYYY"
              placeholder="DD.MM.YYYY"
              margin="normal"
              id="start-date"
              name="startDate"
              label="Start Date (inclusive)"
              value={values.startDate || null}
              onChange={value => onStartDateChange(value, values.endDate)}
              TextFieldComponent={TextFieldComponent}
              KeyboardButtonProps={{
                'aria-label': 'change date',
                'data-cy': 'startDateIcon',
              }}
              error={touched.startDate && Boolean(errors.startDate)}
              onBlur={handleBlur}
              helperText={errors.startDate && touched.startDate ? errors.startDate : ''}
              InputLabelProps={{
                shrink: true,
              }}
              PopoverProps={{
                'data-cy': 'popover',
              }}
              rightArrowButtonProps={{
                'data-cy': 'nextMonthSwitch',
              }}
            />
          </div>
          {values.requestType === 'Patron Saint Day' ? null : (
            <div className={classes.endDate}>
              <KeyboardDatePicker
                fullWidth
                variant="inline"
                autoOk
                format="DD.MM.YYYY"
                placeholder="DD.MM.YYYY"
                margin="normal"
                id="end-date"
                name="endDate"
                label="End Date (inclusive)"
                value={values.endDate || null}
                onChange={value => onEndDateChange(values.startDate, value)}
                TextFieldComponent={TextFieldComponent}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                  'data-cy': 'endDateIcon',
                }}
                error={touched.endDate && Boolean(errors.endDate)}
                onBlur={handleBlur}
                helperText={errors.endDate && touched.endDate ? errors.endDate : ''}
                InputLabelProps={{
                  shrink: true,
                }}
                PopoverProps={{
                  'data-cy': 'popover',
                }}
              />
            </div>
          )}

          <div className={classes.description}>
            <TextField
              fullWidth
              id="description"
              label="Description"
              name="description"
              placeholder="Enter a description"
              multiline
              maxRows="4"
              value={values.description || ''}
              onChange={handleChange}
              className={classes.textField}
              margin="normal"
              error={touched.description && Boolean(errors.description)}
              onBlur={handleBlur}
              helperText={errors.description && touched.description ? errors.description : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </PortletContent>
      <PortletFooter className={classes.portletRoot}>
        <PortletToolbar className={classes.toolbarButtons}>
          <Button disabled={!dirty} onClick={handleReset} variant="contained">
            Reset
          </Button>
          <div className={classes.submitButton}>
            <Button
              disabled={_.isNil(values.endDate) || _.isNil(values.startDate) || !isValid}
              color="primary"
              variant="contained"
              onClick={onSubmitButtonClick}
              data-cy="outOfOfficeSubmit"
            >
              Submit
            </Button>
          </div>
        </PortletToolbar>
      </PortletFooter>
    </Portlet>
  );
};

export default OutOfOfficeForm;
