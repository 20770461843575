import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import cx from 'classnames';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
  IconButton,
  Collapse,
  Box,
  Typography,
  Link,
} from '@material-ui/core';
import { KeyboardArrowUp, KeyboardArrowDown, AccessTime, Done } from '@material-ui/icons';
import { withSnackbar } from 'notistack';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  query,
  updateDoc,
  where,
  orderBy,
} from 'firebase/firestore';
import { BudgetCosts, InvoiceUpload } from 'components/BudgetCosts';
import { DarkTooltip } from '../../components/DarkTooltip';
import { formatDate, snapToArray } from '../../utils';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from '../../components/Portlets';
import { Status } from '../../components/Status';
import { STATUS_COLORS } from '../../constants/requestStatus';
import { useAuth } from '../../auth';
import { FirebaseContext } from '../../utils/firebase';
import { addEmailToCollection, formatEmailEduBudget } from '../../utils/functions/emailHelpers';
import { useManagerEmails } from '../../hooks/useManagerEmails';
import useStyles from './EduBudgetRequestsTable.css';

const Row = props => {
  const {
    status,
    requestedAt,
    type,
    title,
    link,
    description,
    id,
    cancelRequest,
    totalAmount,
    requests,
    startDate,
    endDate,
    hideActionsColumnAndCell,
    budgetCosts,
  } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const showAttachment =
    (status === 'approved' || status === 'completed' || status === 'canceled') &&
    !(type === 'Conference' || type === 'Workshop');

  return (
    <>
      <TableRow className={classes.openRow} data-cy={description} hover>
        <TableCell className={cx(classes.openCell, classes.arrowCell)}>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell className={classes.openCell}>
          <div className={classes.statusWrapper}>
            <Status className={classes.status} color={STATUS_COLORS[status]} size="sm" />
            {status}
          </div>
        </TableCell>

        <TableCell className={classes.openCell}>{formatDate(requestedAt)}</TableCell>
        <TableCell className={classes.openCell}>{type}</TableCell>
        <TableCell className={classes.openCell}>
          <div className={classes.totalAmount}>
            {(status === 'pending' || status === 'approved') && (
              <DarkTooltip
                title="Preliminary amount - may differ until the 
              request is completed"
              >
                <AccessTime className={classes.accessTime} />
              </DarkTooltip>
            )}
            {status === 'completed' && <Done className={classes.done} />}
            {totalAmount}
          </div>
        </TableCell>
        <TableCell className={classes.openCell}>{title}</TableCell>
        {hideActionsColumnAndCell(requests) && (
          <TableCell className={classes.openCell}>
            {status === 'canceled' || status === 'denied' || status === 'completed' ? (
              <div />
            ) : (
              <Button
                size="small"
                onClick={() => {
                  cancelRequest(id, type, title, description, link);
                }}
                className={classes.cancelButton}
                data-cy="cancelButton"
              >
                CANCEL
              </Button>
            )}
          </TableCell>
        )}
      </TableRow>
      <TableRow className={classes.expendableRow}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.collapsed}>
              <Box className={showAttachment ? classes.titleWrapper : classes.titleNoAttachment}>
                {!showAttachment && (
                  <Typography variant="subtitle1" className={classes.collapseTitle}>
                    Request Details
                  </Typography>
                )}
                <Box className={classes.detailsWrapper}>
                  {showAttachment && (
                    <Typography variant="subtitle1" className={classes.collapseTitle}>
                      Request Details
                    </Typography>
                  )}

                  {startDate && endDate && (
                    <>
                      <Typography className={classes.sectionTitle} variant="body1">
                        Start date / End date
                      </Typography>
                      <Typography variant="body2" className={classes.section}>
                        {formatDate(startDate)} / {formatDate(endDate)}
                      </Typography>
                    </>
                  )}
                  <Typography className={classes.sectionTitle} variant="body1">
                    Link
                  </Typography>
                  <Typography variant="body2" className={classes.section}>
                    <Link underline="always" href={link}>
                      {link}
                    </Link>
                  </Typography>
                  <Typography className={classes.sectionTitle} variant="body1">
                    Description
                  </Typography>
                  <Typography variant="body2" className={classes.section}>
                    {description}
                  </Typography>
                </Box>
                {showAttachment && (
                  <Box className={classes.attachmentWrapper}>
                    <Typography variant="body1" className={classes.attachmentTitle}>
                      Attachment
                    </Typography>
                    {budgetCosts[0].invoiceIds.length === 0 && status !== 'approved' && (
                      <Typography variant="body2" className={classes.noFiles}>
                        No files uploaded
                      </Typography>
                    )}
                    <InvoiceUpload budgetCostName="Bill" eduBudgetRequestId={id} status={status} />
                  </Box>
                )}
              </Box>
            </Box>
            {(type === 'Conference' || type === 'Workshop') && (
              <BudgetCosts
                budgetCosts={budgetCosts}
                eduBudgetRequestId={id}
                eduBudgetRequestStatus={status}
              />
            )}
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const EduBudgetRequestsTable = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { firebaseApp } = useContext(FirebaseContext);
  const { user } = useAuth();
  const managerEmails = useManagerEmails(false, true);
  const database = getFirestore(firebaseApp);

  const loadRequests = useCallback(() => {
    const q = query(
      collection(database, 'edu_budget_requests'),
      where('userId', '==', user.uid),
      orderBy('requestedAt', 'desc'),
    );

    return getDocs(q)
      .then(snapshot => {
        const result = snapToArray(snapshot);
        setRequests(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [database, user.uid]);

  useEffect(() => {
    setLoading(true);
    loadRequests();
  }, [loadRequests]);

  const updateRequestStatus = useCallback(
    async (requestId, status) => {
      await updateDoc(doc(database, 'edu_budget_requests', requestId), { status });
      loadRequests();
    },
    [database, loadRequests],
  );

  const sendUpdateEmail = useCallback(
    (type, title, description, link, status) => {
      const emailObject = formatEmailEduBudget(
        [user.email, ...managerEmails],
        user.firstName,
        user.lastName,
        type,
        title,
        description,
        link,
        status,
        user.email,
      );
      addEmailToCollection(firebaseApp, emailObject);
    },
    [firebaseApp, user, managerEmails],
  );

  const cancelRequest = useCallback(
    async (id, type, title, description, link) => {
      try {
        await updateRequestStatus(id, 'canceled');

        enqueueSnackbar('Education budget request successfully canceled', {
          variant: 'success',
        });

        sendUpdateEmail(type, title, description, link, 'canceled');
      } catch (err) {
        enqueueSnackbar('Error when canceling education budget request', {
          variant: 'error',
        });
        console.error(err);
      }
    },
    [updateRequestStatus, enqueueSnackbar, sendUpdateEmail],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hideActionsColumnAndCell = requests =>
    requests.filter(request => request.status === 'pending' || request.status === 'approved')
      .length > 0;

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      {requests.length ? (
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${requests.length} in total`}
            title="Latest Requests"
            className={classes.title}
          />
        </PortletHeader>
      ) : (
        ''
      )}
      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} className={classes.portletContent} noPadding>
          {requests.length ? (
            <>
              <Table stickyHeader style={{ borderSpacing: '0px 0px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Status</TableCell>
                    <TableCell>Requested at</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Total Amount</TableCell>
                    <TableCell>Title</TableCell>
                    {hideActionsColumnAndCell(requests) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {requests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      ({
                        id,
                        description,
                        type,
                        title,
                        link,
                        requestedAt,
                        status = 'pending',
                        totalAmount,
                        startDate,
                        endDate,
                        budgetCosts,
                      }) => (
                        <Row
                          id={id}
                          description={description}
                          type={type}
                          title={title}
                          link={link}
                          requestedAt={requestedAt}
                          status={status}
                          totalAmount={totalAmount}
                          cancelRequest={cancelRequest}
                          hideActionsColumnAndCell={hideActionsColumnAndCell}
                          key={id}
                          requests={requests}
                          startDate={startDate}
                          endDate={endDate}
                          budgetCosts={budgetCosts}
                        />
                      ),
                    )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={requests.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No education budget requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default withSnackbar(EduBudgetRequestsTable);
