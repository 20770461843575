import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  approveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  denyButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#c62828',
    },
    marginLeft: '5px',
  },
  counter: {
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: '4px',
  },
  dialogClose: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
}));
