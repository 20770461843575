import React, { useState } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { withSnackbar } from 'notistack';
import { doc, getDoc, getFirestore } from 'firebase/firestore';

import { ListItem } from '@material-ui/core';

import { snapToObject } from 'utils';
import { useStyles } from './RequestItem.css';

import Request from './Request';
import Details from './Details';

const RequestItem = ({ item, firebaseApp, handleRemove, enqueueSnackbar }) => {
  const classes = useStyles();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [request, setRequest] = useState(null);
  const database = getFirestore(firebaseApp);

  const handleLinkClick = async () => {
    if (isLoaded) {
      setIsOpen(!isOpen);
      return;
    }

    setIsLoading(true);

    let collection = '';
    if (item.for === 'vacation') {
      collection = 'vacation_requests';
    } else if (item.for === 'hardware') {
      collection = 'hardware_requests';
    } else {
      collection = 'edu_budget_requests';
    }

    const queryRequests = doc(database, collection, item.requestId);

    try {
      const snapshot = await getDoc(queryRequests);
      setRequest(snapToObject(snapshot));
      setIsLoaded(true);
      setIsOpen(true);
      setIsLoading(false);
    } catch {
      enqueueSnackbar('Something went wrong', {
        variant: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    handleRemove(item.id);
  };

  return (
    <ListItem
      key={item.id}
      classes={{
        root: cx({ [classes.root]: true }),
      }}
    >
      <Details
        item={item}
        isLoading={isLoading}
        handleLinkClick={handleLinkClick}
        handleClose={handleClose}
      />

      <Request data={{ ...request, for: item.for }} isOpen={isOpen} />
    </ListItem>
  );
};

RequestItem.propTypes = {
  item: PropTypes.object,
  handleRemove: PropTypes.func.isRequired,
  firebaseApp: PropTypes.object.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

RequestItem.defaultProps = {
  item: {},
};

export default withSnackbar(RequestItem);
