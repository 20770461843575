import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: '100%',
    paddingBottom: 0,
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',

    '& .MuiTypography-body1': {
      fontSize: ['0.9rem', '!important'],
    },

    '& .MuiTypography-body2': {
      fontSize: ['0.75rem', '!important'],
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
    },
  },
}));
