import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  paper: { boxShadow: 'none' },
  noCostsWrapper: {
    backgroundColor: '#F7F7F9',
    width: '100%',
    padding: '1rem 0',
    marginBottom: '2rem',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  noticeWrapper: {
    display: 'flex',
    alignItems: ' center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '1rem 0',
  },
  noticeTitle: {
    fontSize: '18px',
    fontWeight: '600',
    marginTop: '1rem',
    color: 'black',
  },
  table: {
    marginBottom: '2rem',
    marginLeft: '0.5rem',
    marginTop: '2rem',
  },
  tableHead: {
    '& th': {
      fontWeight: '300',
    },
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
  },
  accessTime: {
    marginRight: '0.5rem',
    color: '#686868',
    width: '1.2rem',
    height: '1.2rem',
  },
  done: {
    color: '#65B32E',
    marginRight: '0.5rem',
    width: '1.2rem',
    height: '1.2rem',
  },
  addButton: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    textTransform: 'none',
    height: '2rem',
  },
  noCostsButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 1rem 1rem 1rem',
    borderBottom: `1px solid ${theme.palette.border}`,
  },
  deleteButton: {
    textTransform: 'none',
    height: '2rem',
    width: '2rem',
    color: 'rgb(217, 72, 68)',
    border: '1px solid rgba(217, 72, 68, .5)',
    borderRadius: '3px',
    marginRight: '1rem',
  },
  deleteButtonDisabled: {
    border: `1px solid ${theme.palette.border}`,
  },
  alignCell: {
    verticalAlign: 'top',
  },
  checkboxCell: {
    verticalAlign: 'top',
    paddingTop: '0.25rem',
  },
  counter: {
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '12px',
    marginTop: '0.25rem',
  },
  autocomplete: {
    marginTop: '-0.75rem',
  },
  invoiceTitle: {
    color: 'black',
  },
  deleteWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  selected: {
    color: '#686868',
  },
}));
