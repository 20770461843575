import { Tooltip, withStyles } from '@material-ui/core';

const DarkTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: '0.5rem',
    fontSize: 12,
    lineHeight: '1.12rem',
    marginTop: '1rem',
    maxWidth: '10rem',
  },
}))(Tooltip);

export default DarkTooltip;
