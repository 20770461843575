import React, { useState, forwardRef, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link, NavLink } from 'react-router-dom';
import {
  Divider,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Collapse,
  Badge,
} from '@material-ui/core';
import { collection, getFirestore, orderBy, onSnapshot, where, query } from 'firebase/firestore';
import {
  DashboardOutlined as DashboardIcon,
  PeopleOutlined as PeopleIcon,
  InfoOutlined as InfoIcon,
  ArrowDropDown as ArrowDown,
  ArrowDropUp as ArrowUp,
  DateRangeOutlined as Calendar,
  DescriptionOutlined as MyRequest,
  InboxOutlined as TeamAdmin,
} from '@material-ui/icons';
import { snapToArray } from '../../utils';
import { FirebaseContext } from '../../utils/firebase';
import { useAuth } from '../../auth';
import { useStyles } from './Sidebar.css';

const DashboardLink = forwardRef((props, ref) => <NavLink innerRef={ref} {...props} />);
const eduBudgetCollection = 'edu_budget_requests';
const vacationRequestCollection = 'vacation_requests';
const hardwareRequestCollection = 'hardware_requests';

const Sidebar = ({ className }) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const { isManager, user } = useAuth();
  const [openTeam, setOpenTeam] = useState(false);
  const [openRequests, setOpenRequests] = useState(!isManager);
  const [vacatioRequests, setVacatioRequests] = useState([]);
  const [eduRequests, setEduRequests] = useState([]);
  const [hardwareRequests, setHardwareRequests] = useState([]);

  const handleExpandTeam = () => {
    setOpenTeam(!openTeam);
  };

  const handleExpandRequests = () => {
    setOpenRequests(!openRequests);
  };

  const getPendingRequests = useCallback(
    async (collectionName, setRequests) => {
      const q = query(
        collection(database, collectionName),
        where('userTenant', '==', user.tenant.id),
        where('status', '==', 'pending'),
        orderBy('requestedAt', 'desc'),
      );

      onSnapshot(q, snapshot => {
        setRequests(snapToArray(snapshot));
      });
    },
    [database, user.tenant.id],
  );

  useEffect(() => {
    getPendingRequests(vacationRequestCollection, setVacatioRequests);
    getPendingRequests(eduBudgetCollection, setEduRequests);
    getPendingRequests(hardwareRequestCollection, setHardwareRequests);
  }, [getPendingRequests]);

  const count = vacatioRequests.length || eduRequests.length || hardwareRequests.length;
  const countVacationRequests = vacatioRequests.length;
  const countEduRequests = eduRequests.length;
  const countHardwareRequests = hardwareRequests.length;

  const badgeClasses = {
    dot: classes.dot,
    badge: classes.badgeDot,
  };

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <Link className={classes.logoLink} to="/">
          <img alt="productdock logo" className={classes.logoImage} src="/images/logo.svg" />
        </Link>
        <div className={classes.tenant}>
          <span className={classes.companyName}>ProductDock</span>
          <span className={classes.tenantName}>{user.tenant.name}</span>
        </div>
      </div>
      <Divider className={classes.profileDivider} />
      <List component="div" disablePadding>
        <ListItem
          activeClassName={classes.activeListItem}
          className={classes.listItem}
          component={DashboardLink}
          to="/dashboard"
          data-cy="dashboardSidebar"
        >
          <ListItemIcon className={classes.listItemIcon}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }} primary="Dashboard" />
        </ListItem>
        <Hidden smDown>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            to="/calendar"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <Calendar />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Calendar" />
          </ListItem>
        </Hidden>
        <Divider className={classes.listDivider} />
      </List>
      {isManager && (
        <List
          className={classes.listItemMenu}
          subheader={<ListSubheader className={classes.listSubheader}>Team</ListSubheader>}
        >
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.listItem}
            component={DashboardLink}
            to="/team-members"
            data-cy="teamMembersSidebar"
          >
            <ListItemIcon className={classes.listItemIcon}>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Team Members" />
          </ListItem>
          <ListItemIcon
            onClick={handleExpandTeam}
            className={classes.listItemTitle}
            data-cy="teamSidebarToggle"
          >
            <ListItemIcon className={classes.listMenuItemIcon}>
              <TeamAdmin />
            </ListItemIcon>
            <ListItemText classes={{ primary: classes.listItemText }} primary="Team Requests" />
            <Badge
              classes={badgeClasses}
              badgeContent={count}
              color="primary"
              variant="dot"
              overlap="rectangular"
            />
            {openTeam ? (
              <ArrowUp className={classes.listItemIconArrow} />
            ) : (
              <ArrowDown className={classes.listItemIconArrow} />
            )}
          </ListItemIcon>
          <Collapse in={openTeam} timeout="auto" unmountOnExit>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.nestedListItem}
              component={DashboardLink}
              to="/team-vacation-requests"
              data-cy="teamVacationRequestsSidebar"
            >
              <span className={classes.nestedListItemWrapper}>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Vacation" />
                <Badge
                  className={classes.badgeNumber}
                  badgeContent={countVacationRequests}
                  color="primary"
                  overlap="rectangular"
                />
              </span>
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.nestedListItem}
              component={DashboardLink}
              to="/team-education-budget-requests"
              data-cy="teamEducationBudgetSidebar"
            >
              <span className={classes.nestedListItemWrapper}>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary="Education Budget"
                />
                <Badge
                  className={classes.badgeNumber}
                  badgeContent={countEduRequests}
                  color="primary"
                  overlap="rectangular"
                />
              </span>
            </ListItem>
            <ListItem
              activeClassName={classes.activeListItem}
              className={classes.nestedListItem}
              component={DashboardLink}
              to="/team-hardware-requests"
              data-cy="teamHardwareRequestsSidebar"
            >
              <span className={classes.nestedListItemWrapper}>
                <ListItemText classes={{ primary: classes.listItemText }} primary="Hardware" />
                <Badge
                  className={classes.badgeNumber}
                  badgeContent={countHardwareRequests}
                  color="primary"
                  overlap="rectangular"
                />
              </span>
            </ListItem>
          </Collapse>
          <Divider className={classes.listDivider} />
        </List>
      )}
      <List
        className={classes.listItemMenu}
        subheader={<ListSubheader className={classes.listSubheader}>Requests</ListSubheader>}
      >
        <ListItemIcon
          onClick={handleExpandRequests}
          className={classes.listItemTitle}
          data-cy="requestsSidebarToggle"
        >
          <ListItemIcon className={classes.listMenuItemIcon}>
            <MyRequest />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.listItemText }} primary="My Requests" />
          {openRequests ? (
            <ArrowUp className={classes.listItemIconArrow} />
          ) : (
            <ArrowDown className={classes.listItemIconArrow} />
          )}
        </ListItemIcon>
        <Collapse in={openRequests} timeout="auto" unmountOnExit>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.nestedListItem}
            component={DashboardLink}
            to="/my-vacation-requests"
            data-cy="myVacationRequestsSidebar"
          >
            <ListItemText classes={{ primary: classes.listItemText }} primary="Vacation" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.nestedListItem}
            component={DashboardLink}
            to="/my-out-of-office"
            data-cy="outOfOfficeRequestsSidebar"
          >
            <ListItemText classes={{ primary: classes.listItemText }} primary="Out of Office" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.nestedListItem}
            component={DashboardLink}
            to="/my-education-budget"
            data-cy="myEduBudgetRequestsSidebar"
          >
            <ListItemText classes={{ primary: classes.listItemText }} primary="Education Budget" />
          </ListItem>
          <ListItem
            activeClassName={classes.activeListItem}
            className={classes.nestedListItem}
            component={DashboardLink}
            to="/my-hardware-requests"
            data-cy="myHardwareRequestsSidebar"
          >
            <ListItemText classes={{ primary: classes.listItemText }} primary="Hardware" />
          </ListItem>
        </Collapse>
      </List>
      <Divider className={classes.listDivider} />
      <ListItem
        className={classes.listItem}
        component="a"
        href="https://productdockworkspace.slack.com/archives/C03KGGQM19B"
        target="_blank"
      >
        <ListItemIcon className={classes.listItemIcon}>
          <InfoIcon />
        </ListItemIcon>
        <ListItemText classes={{ primary: classes.listItemText }} primary="Help & Support" />
      </ListItem>
    </nav>
  );
};

Sidebar.propTypes = {
  className: PropTypes.string,
};

export default Sidebar;
