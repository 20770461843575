import { collection, getDocs, where, query, orderBy, getFirestore } from 'firebase/firestore';
import { dateRangesOverlapWithinYear, snapToArray } from '../../../utils';

const getUserVacationRequests = async (firebaseApp, user) => {
  const database = getFirestore(firebaseApp);

  const queryRequests = query(
    collection(database, 'vacation_requests'),
    where('userId', '==', user.uid),
    orderBy('requestedAt', 'desc'),
  );

  const snapshot = await getDocs(queryRequests);
  return snapToArray(snapshot);
};

const isAnOverlapVacationRequest = async (startDate, endDate, firebaseApp, user) => {
  let isAnOverlap = false;
  const PENDING_STATUS = 'pending';
  const APPROVED_STATUS = 'approved';

  const vacationRequests = await getUserVacationRequests(firebaseApp, user);

  vacationRequests.forEach(request => {
    if (request.status === APPROVED_STATUS || request.status === PENDING_STATUS) {
      // determine whether two date ranges overlap
      if (
        dateRangesOverlapWithinYear(
          new Date(startDate),
          new Date(endDate),
          request.vacationFrom.toDate(),
          request.vacationTo.toDate(),
        )
      ) {
        console.error('There is an overlap between the date ranges');
        isAnOverlap = true;
      }
    }
  });
  return isAnOverlap;
};

export { isAnOverlapVacationRequest };
