/* eslint-disable  no-param-reassign */
import React, { useState } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import DateFnsUtils from '@date-io/moment';
import _ from 'lodash';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {
  Portlet,
  PortletToolbar,
  PortletContent,
  PortletFooter,
} from '../../../components/Portlets';
import { getNumberOfWorkingDays } from './getNumberOfWorkingDays';
import { TextFieldComponent } from '../../../components/TextFieldComponent';
import { useStyles } from './VacationRequestForm.css';

const VacationRequestForm = ({
  formProps: {
    values,
    errors,
    isValid,
    handleChange,
    handleBlur,
    touched,
    dirty,
    setFieldValue,
    submitForm,
    handleReset,
  },
}) => {
  const classes = useStyles();
  const [numberOfWorkingDays, setNumberOfWorkingDays] = useState('');

  const onSubmitButtonClick = async () => {
    await submitForm();
  };

  const onStartDateChange = (startDate, endDate) => {
    setFieldValue('startDate', startDate);
    if (!endDate) {
      endDate = startDate;
      setFieldValue('endDate', endDate);

      const workingDays = getNumberOfWorkingDays(new Date(startDate), new Date(endDate));
      setNumberOfWorkingDays(workingDays > 0 ? workingDays : '');
      setFieldValue('workingDays', workingDays);
    } else if (endDate < startDate) {
      endDate = startDate;
      setFieldValue('endDate', endDate);
      const workingDays = getNumberOfWorkingDays(new Date(startDate), new Date(endDate));
      setNumberOfWorkingDays(workingDays > 0 ? workingDays : '');
      setFieldValue('workingDays', workingDays);
    } else {
      const workingDays = getNumberOfWorkingDays(new Date(startDate), new Date(endDate));
      setNumberOfWorkingDays(workingDays);
      setFieldValue('workingDays', workingDays);
    }
  };

  const onEndDateChange = (startDate, endDate) => {
    setFieldValue('endDate', endDate);

    if (endDate < startDate) {
      startDate = endDate;
      setFieldValue('startDate', startDate);
    }

    const workingDays = getNumberOfWorkingDays(new Date(startDate), new Date(endDate));
    setNumberOfWorkingDays(workingDays);
    setFieldValue('workingDays', workingDays);
  };

  // eslint-disable-next-line consistent-return
  const onWorkingDaysChange = e => {
    // prevent inserting higher number than calculated
    if (numberOfWorkingDays > 0 && e.target.value > numberOfWorkingDays) {
      return 0;
    }
    handleChange(e);
  };

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      <PortletContent className={classes.portletContent}>
        <Typography variant="body2" component="span" className={classes.title}>
          Vacation Request
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <div>
            <KeyboardDatePicker
              fullWidth
              variant="inline"
              autoOk
              format="DD.MM.YYYY"
              placeholder="DD.MM.YYYY"
              margin="normal"
              id="start-date"
              name="startDate"
              label="Start Date (inclusive)"
              value={values.startDate || null}
              onChange={value => onStartDateChange(value, values.endDate)}
              TextFieldComponent={TextFieldComponent}
              KeyboardButtonProps={{
                'aria-label': 'change start date',
                'data-cy': 'startDateIcon',
              }}
              error={touched.startDate && Boolean(errors.startDate)}
              onBlur={handleBlur}
              helperText={errors.startDate && touched.startDate ? errors.startDate : ''}
              InputLabelProps={{
                shrink: true,
              }}
              PopoverProps={{
                'data-cy': 'popover',
              }}
              rightArrowButtonProps={{
                'data-cy': 'nextMonthSwitch',
              }}
            />
          </div>
          <div className={classes.endDate}>
            <KeyboardDatePicker
              fullWidth
              variant="inline"
              autoOk
              format="DD.MM.YYYY"
              placeholder="DD.MM.YYYY"
              margin="normal"
              id="end-date"
              name="endDate"
              label="End Date (inclusive)"
              value={values.endDate || null}
              onChange={value => onEndDateChange(values.startDate, value)}
              TextFieldComponent={TextFieldComponent}
              KeyboardButtonProps={{
                'aria-label': 'change end date',
                'data-cy': 'endDateIcon',
              }}
              error={touched.endDate && Boolean(errors.endDate)}
              onBlur={handleBlur}
              helperText={errors.endDate && touched.endDate ? errors.endDate : ''}
              InputLabelProps={{
                shrink: true,
              }}
              PopoverProps={{
                'data-cy': 'popover',
              }}
              rightArrowButtonProps={{
                'data-cy': 'nextMonthSwitch',
              }}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="working-days"
              label="Number Of Working Days"
              name="workingDays"
              type="number"
              placeholder="It will be calculated automatically skipping weekends"
              value={values.workingDays || ''}
              onChange={onWorkingDaysChange}
              margin="normal"
              variant="standard"
              error={touched.workingDays && Boolean(errors.workingDays)}
              onBlur={handleBlur}
              helperText={errors.workingDays && touched.workingDays ? errors.workingDays : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.description}>
            <TextField
              fullWidth
              id="description"
              label="Description"
              name="description"
              placeholder="Is the team or client informed?"
              multiline
              maxRows="4"
              value={values.description || ''}
              onChange={handleChange}
              className={classes.textField}
              margin="normal"
              error={touched.description && Boolean(errors.description)}
              onBlur={handleBlur}
              helperText={errors.description && touched.description ? errors.description : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
        </MuiPickersUtilsProvider>
      </PortletContent>
      <PortletFooter className={classes.portletRoot}>
        <PortletToolbar className={classes.toolbarButtons}>
          <Button disabled={!dirty} onClick={handleReset} variant="contained">
            Reset
          </Button>
          <div className={classes.submitButton}>
            <Button
              disabled={
                _.isNil(values.endDate) ||
                _.isNil(values.startDate) ||
                _.isNil(values.workingDays) ||
                _.isNil(values.description) ||
                !isValid
              }
              color="primary"
              variant="contained"
              name="submit"
              onClick={onSubmitButtonClick}
              data-cy="vacationSubmit"
            >
              Submit
            </Button>
          </div>
        </PortletToolbar>
      </PortletFooter>
    </Portlet>
  );
};

export default VacationRequestForm;
