import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  approveButton: {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#388e3c',
    },
  },
  denyButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#c62828',
    },
    marginLeft: '5px',
  },
}));
