import { useCallback, useContext, useEffect, useState } from 'react';
import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useAuth } from 'auth';
import { FirebaseContext } from '../utils/firebase';
import { snapToArray } from '../utils';

/**
 * Current user's manager email
 * Account manager email
 * Office manager email - only for approved edu budget requests
 */
const useManagerEmails = (sendToOfficeManager = false, withUserManagerEmail = false) => {
  const { firebaseApp } = useContext(FirebaseContext);
  const [emails, setEmails] = useState();
  const database = getFirestore(firebaseApp);
  const { user } = useAuth();

  const getAccountAndOfficeManagerEmails = useCallback(async () => {
    if (sendToOfficeManager) {
      const queryAccountManagers = query(
        collection(database, 'users'),
        where('tenant.id', '==', user.tenant.id),
        where('roles.accountManager', '==', true),
      );
      const queryOfficeManagers = query(
        collection(database, 'users'),
        where('tenant.id', '==', user.tenant.id),
        where('roles.officeManager', '==', true),
      );
      const snapshotAccount = await getDocs(queryAccountManagers);
      const snapshotOffice = await getDocs(queryOfficeManagers);
      const accountManagers = snapToArray(snapshotAccount);
      const officeManagers = snapToArray(snapshotOffice);
      const users = accountManagers.concat(officeManagers);
      return users.map(user => user.email);
    }
    const queryUsers = query(
      collection(database, 'users'),
      where('tenant.id', '==', user.tenant.id),
      where('roles.accountManager', '==', true),
    );
    const snapshot = await getDocs(queryUsers);
    const users = snapToArray(snapshot);
    return users.map(user => user.email);
  }, [database, sendToOfficeManager, user.tenant.id]);

  const getManagerEmails = useCallback(async () => {
    const queryUsers = query(
      collection(database, 'users'),
      where('tenant.id', '==', user.tenant.id),
      where('roles.manager', '==', true),
    );

    const snapshot = await getDocs(queryUsers);
    const users = snapToArray(snapshot);

    return users.map(user => user.email);
  }, [database, user.tenant.id]);

  useEffect(() => {
    async function fetchEmails() {
      const accountAndOfficeEmails = await getAccountAndOfficeManagerEmails();
      const managerEmails = await getManagerEmails();
      if (withUserManagerEmail && user.assignedManager) {
        if (Array.isArray(user.assignedManager)) {
          const emails =
            user.assignedManager.length === 0
              ? managerEmails
              : user.assignedManager.map(manager => manager.email);
          setEmails(accountAndOfficeEmails.concat(emails));
        } else {
          setEmails(accountAndOfficeEmails.concat([user.assignedManager.email]));
        }
      } else if (withUserManagerEmail && !user.assignedManager) {
        setEmails(accountAndOfficeEmails.concat(managerEmails));
      } else if (!withUserManagerEmail) {
        setEmails(accountAndOfficeEmails);
      }
    }
    fetchEmails();
  }, [
    getAccountAndOfficeManagerEmails,
    getManagerEmails,
    user.assignedManager,
    withUserManagerEmail,
  ]);

  return emails;
};

export { useManagerEmails };
