import React, { useContext } from 'react';
import { Formik } from 'formik';
import YupString from 'yup/lib/string';
import YupObject from 'yup/lib/object';
import { withSnackbar } from 'notistack';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { useAuth } from '../../../auth';
import { FirebaseContext } from '../../../utils/firebase';
import {
  addEmailToCollection,
  formatEmailOutOfOffice,
} from '../../../utils/functions/emailHelpers';
import OutOfOfficeForm from './OutOfOfficeForm';
import { isAnOverlapOutOfOfficeRequest } from './isAnOverlapOutOfOfficeRequest';
import { useManagerEmails } from '../../../hooks/useManagerEmails';
import { createTempoWorklog } from '../../../utils/functions/tempoHelpers';

const OUT_OF_OFFICES = 'out_of_offices';

const createOutOfOffice = (outOfOfficeObject, firebaseApp) => {
  const database = getFirestore(firebaseApp);

  return addDoc(collection(database, OUT_OF_OFFICES), outOfOfficeObject);
};

const mapValuesToOutOfOffice = async (
  values,
  user,
  firebaseApp,
  enqueueSnackbar,
  managerEmails,
  userToken,
) => {
  const isAnOverlap = await isAnOverlapOutOfOfficeRequest(
    values.startDate,
    values.endDate,
    firebaseApp,
    user,
  );

  if (!isAnOverlap) {
    const outOfOfficeObject = {
      userId: user.uid,
      userTenant: user.tenant.id,
      startDate: new Date(values.startDate),
      endDate: new Date(values.endDate),
      description: values.description,
      requestType: values.requestType,
      requestedAt: new Date(),
      createdBy: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
    };
    const emailObject = formatEmailOutOfOffice(
      [user.email, ...managerEmails],
      user.firstName,
      user.lastName,
      values.startDate,
      values.endDate,
      values.description,
      values.requestType,
    );

    createOutOfOffice(outOfOfficeObject, firebaseApp)
      .then(() => {
        enqueueSnackbar('Out of office request successfully submitted', {
          variant: 'success',
        });
        addEmailToCollection(firebaseApp, emailObject);
        createTempoWorklog(
          userToken,
          user.email,
          values.startDate,
          values.endDate,
          values.requestType.replace(/\s+/g, '-').toLowerCase(), // "sick-leave", "patron-saint-day" ...
        );
      })
      .catch(err => {
        enqueueSnackbar('Error when submitting out of office request', {
          variant: 'error',
        });
        console.error('Error writing document: ', err);
      });
  } else {
    enqueueSnackbar(
      'Sorry, there is already an out of office request that overlaps with this request',
      {
        variant: 'error',
      },
    );
  }
};

const VacationRequestFormContainer = ({ enqueueSnackbar }) => {
  const { user, userToken } = useAuth();
  const { firebaseApp } = useContext(FirebaseContext);
  const managerEmails = useManagerEmails(false, true);

  const validationSchema = YupObject().shape({
    startDate: YupString()
      .required('Start date is required')
      .nullable(),
    endDate: YupString()
      .required('End date is required')
      .nullable(),
    description: YupString()
      .required('Description is required')
      .nullable(),
    requestType: YupString()
      .required('Request type is required')
      .nullable(),
  });

  return (
    <Formik
      enableReinitialize
      initialErrors={[]}
      validationSchema={validationSchema}
      initialValues={{}}
      onSubmit={async (values, { resetForm }) => {
        await mapValuesToOutOfOffice(
          values,
          user,
          firebaseApp,
          enqueueSnackbar,
          managerEmails,
          userToken,
        );
        resetForm();
      }}
    >
      {form => <OutOfOfficeForm formProps={form} />}
    </Formik>
  );
};

export default withSnackbar(VacationRequestFormContainer);
