import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withSnackbar } from 'notistack';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import { DenyDialog } from 'components/DenyDialog';
import { FirebaseContext } from '../../../utils/firebase';
import useStyles from './RequestAction.css';

const RequestAction = ({
  userId,
  requestId,
  enqueueSnackbar,
  status,
  title,
  link,
  type,
  onApproveRequestAction,
  onDenyRequestAction,
  onCancelRequestAction,
  onCompleteRequestAction,
  requestedBy,
  description,
  totalAmount,
}) => {
  const classes = useStyles();
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [note, setNote] = useState('');
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);

  const onApprove = useCallback(
    e => {
      e.preventDefault();
      setIsProcessing(true);
      onApproveRequestAction({
        requestId,
        userId,
        status: 'approved',
        requestedBy,
        title,
        link,
        type,
        description,
      })
        .then(() => {
          enqueueSnackbar('Education budget request successfully approved', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when approving education budget request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      onApproveRequestAction,
      requestId,
      userId,
      title,
      link,
      type,
      requestedBy,
      description,
      enqueueSnackbar,
    ],
  );

  const onDeny = useCallback(
    (e, addNote) => {
      e.preventDefault();
      setIsProcessing(true);
      onDenyRequestAction({
        requestId,
        userId,
        status: 'denied',
        requestedBy,
        title,
        link,
        type,
        description,
        note: addNote ? note : '',
      })
        .then(() => {
          enqueueSnackbar('Education budget request successfully denied', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when denying education budget request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      onDenyRequestAction,
      requestId,
      userId,
      requestedBy,
      title,
      link,
      type,
      description,
      enqueueSnackbar,
      note,
    ],
  );

  const onDenyOpenDialog = e => {
    e.preventDefault();
    setDialogOpen(true);
  };

  const onCancel = useCallback(
    e => {
      e.preventDefault();
      setIsProcessing(true);
      onCancelRequestAction({
        requestId,
        userId,
        status: 'canceled',
        requestedBy,
        title,
        link,
        type,
        description,
      })
        .then(() => {
          enqueueSnackbar('Education budget request successfully canceled', {
            variant: 'success',
          });
        })
        .catch(() => {
          enqueueSnackbar('Error when canceling education budget request', {
            variant: 'error',
          });
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
    [
      onCancelRequestAction,
      requestId,
      userId,
      requestedBy,
      title,
      link,
      type,
      description,
      enqueueSnackbar,
    ],
  );

  const getUser = useCallback(
    async id => {
      const userRef = doc(database, 'users', id);
      const userSnap = await getDoc(userRef);
      const user = userSnap.data();
      return user;
    },
    [database],
  );

  const onComplete = useCallback(
    async e => {
      e.preventDefault();
      setIsProcessing(true);

      const user = await getUser(userId);

      if (totalAmount <= user.availableEduBudget) {
        onCompleteRequestAction({
          requestId,
          userId,
          status: 'completed',
          requestedBy,
          title,
          link,
          type,
          description,
          totalAmount,
        })
          .then(() => {
            enqueueSnackbar('Education budget request successfully completed', {
              variant: 'success',
            });
          })
          .catch(() => {
            enqueueSnackbar('Error when completing education budget request', {
              variant: 'error',
            });
          })
          .finally(() => {
            setIsProcessing(false);
          });
      } else {
        enqueueSnackbar('Sorry, there is not enough education budget for this request', {
          variant: 'error',
        });
        setIsProcessing(false);
      }
    },
    [
      getUser,
      userId,
      totalAmount,
      onCompleteRequestAction,
      requestId,
      requestedBy,
      title,
      link,
      type,
      description,
      enqueueSnackbar,
    ],
  );

  return (
    <div>
      {(status === 'pending' || status === 'approved') && (
        <Button
          variant="contained"
          size="small"
          disabled={isProcessing}
          onClick={e => (status === 'pending' ? onApprove(e) : onComplete(e))}
          className={classes.approveCompleteButton}
          data-cy={status === 'pending' ? 'approveButton' : 'completeButton'}
        >
          {status === 'pending' ? 'Approve' : 'Complete'}
        </Button>
      )}
      {status !== 'completed' && (
        <Button
          variant="contained"
          size="small"
          disabled={isProcessing}
          onClick={e => (status === 'pending' ? onDenyOpenDialog(e) : onCancel(e))}
          className={classes.denyCancelButton}
          data-cy={status === 'pending' ? 'denyButton' : 'cancelButton'}
        >
          {status === 'pending' ? 'Deny' : 'Cancel'}
        </Button>
      )}
      <DenyDialog
        setDialogOpen={setDialogOpen}
        type="education budget"
        setNote={setNote}
        onDeny={onDeny}
        isProcessing={isProcessing}
        isDialogOpen={isDialogOpen}
        note={note}
        requestedBy={requestedBy}
      />
    </div>
  );
};

RequestAction.propTypes = {
  userId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onApproveRequestAction: PropTypes.func.isRequired,
  onDenyRequestAction: PropTypes.func.isRequired,
  onCancelRequestAction: PropTypes.func.isRequired,
  onCompleteRequestAction: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
  requestedBy: PropTypes.any.isRequired,
  description: PropTypes.string.isRequired,
};

export default withSnackbar(RequestAction);
