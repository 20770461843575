import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '350px',
    maxWidth: '100%',
  },
  header: {
    backgroundColor: theme.palette.background.default,
    backgroundImage: 'url("/images/connected_world.svg")',
    backgroundPositionX: 'right',
    backgroundPositionY: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    padding: theme.spacing(2),
  },
  subtitle: {
    color: theme.palette.text.secondary,
  },
  content: {},
  list: {
    padding: 0,
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  listItemIcon: {
    backgroundColor: 'rgba(0, 0, 0, 0.03)',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: theme.spacing(2),
  },
  listItemTextSecondary: {
    marignTop: '4px',
    color: theme.palette.text.secondary,
  },
  footer: {
    paddingBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    width: 16,
    height: 16,
  },
}));
