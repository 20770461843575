import React, { useContext } from 'react';
import { Formik } from 'formik';
import YupString from 'yup/lib/string';
import YupObject from 'yup/lib/object';
import { withSnackbar } from 'notistack';
import { getFirestore, collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../../auth';
import { FirebaseContext } from '../../../utils/firebase';
import { addEmailToCollection, formatEmailVacation } from '../../../utils/functions/emailHelpers';
import VacationRequestForm from './VacationRequestForm';
import { getMaxEndDate } from './getMaxEndDate';
import { isAnOverlapVacationRequest } from './isAnOverlapVacationRequest';
import { useTenant } from '../../../hooks/useTenant';
import { useManagerEmails } from '../../../hooks/useManagerEmails';

const VACATION_REQUESTS = 'vacation_requests';
const PENDING_STATUS = 'pending';

const createVacationRequest = (vacationRequestObj, firebaseApp) => {
  const database = getFirestore(firebaseApp);

  return addDoc(collection(database, VACATION_REQUESTS), vacationRequestObj);
};

const reduceUserAvailableDays = (firebaseApp, user, duration) => {
  const userUpdate = {};
  userUpdate.availableDays = user.availableDays - duration;

  const database = getFirestore(firebaseApp);

  return updateDoc(doc(database, 'users', user.uid), userUpdate);
};

const mapValuesToVacationRequest = async (
  values,
  user,
  firebaseApp,
  enqueueSnackbar,
  managerEmails,
  NEXT_PERIOD_DAYS,
) => {
  if (user.availableDays + NEXT_PERIOD_DAYS >= values.workingDays) {
    if (
      // initialize a JavaScript Date to midnight
      new Date(new Date(values.endDate).setHours(0, 0, 0, 0)) <= getMaxEndDate(values.startDate)
    ) {
      const isAnOverlap = await isAnOverlapVacationRequest(
        values.startDate,
        values.endDate,
        firebaseApp,
        user,
      );

      if (!isAnOverlap) {
        const vacationRequestObj = {
          userId: user.uid,
          userTenant: user.tenant.id,
          description: values.description,
          noOfDays: values.workingDays,
          requestedAt: new Date(),
          requestedBy: {
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
          },
          vacationFrom: new Date(values.startDate),
          vacationTo: new Date(values.endDate),
          status: PENDING_STATUS,
          userAvailableDays: user.availableDays - values.workingDays,
        };

        const emailObject = formatEmailVacation(
          [user.email, ...managerEmails],
          user.firstName,
          user.lastName,
          values.startDate,
          values.endDate,
          values.workingDays,
          values.description,
          PENDING_STATUS,
        );

        createVacationRequest(vacationRequestObj, firebaseApp)
          .then(() => {
            reduceUserAvailableDays(firebaseApp, user, values.workingDays);
            enqueueSnackbar('Vacation request successfully submitted for approval', {
              variant: 'success',
            });
            addEmailToCollection(firebaseApp, emailObject);
          })
          .catch(err => {
            enqueueSnackbar('Error when submitting vacation request', {
              variant: 'error',
            });
            console.error('Error writing document: ', err);
          });
      } else {
        enqueueSnackbar(
          'Sorry, there is already a vacation request that overlaps with this request',
          {
            variant: 'error',
          },
        );
      }
    } else {
      enqueueSnackbar('Sorry, start and end dates should be within one calendar year', {
        variant: 'error',
      });
    }
  } else {
    enqueueSnackbar('Sorry, all days from the next vacation period are spent', {
      variant: 'error',
    });
  }
};

const VacationRequestFormContainer = ({ enqueueSnackbar }) => {
  const { user } = useAuth();
  const { firebaseApp } = useContext(FirebaseContext);
  const greaterThanZeroRegExp = /^[1-9][0-9]*$/;
  const tenant = useTenant();

  const managerEmails = useManagerEmails(false, true);
  const validationSchema = YupObject().shape({
    startDate: YupString()
      .required('Start date is required')
      .nullable(),
    endDate: YupString()
      .required('End date is required')
      .nullable(),
    description: YupString().required('Description is required'),
    workingDays: YupString()
      .matches(greaterThanZeroRegExp, 'The number should be greater than 0')
      .required('Number of working days is required'),
  });

  return (
    <Formik
      enableReinitialize
      initialErrors={[]}
      validationSchema={validationSchema}
      initialValues={{}}
      onSubmit={async (values, { resetForm }) => {
        await mapValuesToVacationRequest(
          values,
          user,
          firebaseApp,
          enqueueSnackbar,
          managerEmails,
          tenant?.vacation.totalDays,
        );
        resetForm();
      }}
    >
      {form => <VacationRequestForm formProps={form} />}
    </Formik>
  );
};

export default withSnackbar(VacationRequestFormContainer);
