import React from 'react';
import { Grid } from '@material-ui/core';
import { TeamHardwareRequestsTable } from './table';
import useStyles from './TeamHardwareRequests.css';

const TeamHardwareRequestsView = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <TeamHardwareRequestsTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default TeamHardwareRequestsView;
