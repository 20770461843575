export const eduBudgetTypes = [
  'Conference',
  'Workshop',
  'Course',
  'Book',
  'Language Classes',
  'Other',
];
export const budgetCostItems = [
  'Enrolment fee',
  'Flight tickets',
  'Accommodation',
  'Transfer to airport',
  'Daily allowances',
  'Travel insurance',
  'Other',
];
