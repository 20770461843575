// Palette
import palette from '../palette';

export default {
  root: {
    border: '1px solid',
    borderRadius: '0.5rem',
    borderColor: palette.common.neutral,
    background: palette.background.default,
    '&:hover': {
      borderColor: palette.primary.main,
      background: '#F5FBFF',
    },
  },
};
