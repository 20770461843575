import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    textAlign: 'center',
  },
  grid: {
    height: '100%',
    flexWrap: 'nowrap',
  },
  leftWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '0%',
    justifyContent: 'flex-end',
    backgroundColor: '#2B6096',
    borderRadius: '1rem',
    margin: '1rem',
    width: '30.5rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  leftSection: {
    backgroundColor: '#2B6096',
    width: '27.5rem',
    height: '27.5rem',
    backgroundImage: 'url(/images/pd-image.png)',
    backgroundSize: 'cover',
    margin: '1.5rem',
  },
  logo: {
    height: theme.spacing(5.25),
    width: theme.spacing(5.25),
    backgroundImage: 'url(/images/logo.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    marginLeft: theme.spacing(15.25),
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(8),
    flexBasis: '700px',
    alignSelf: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    marginTop: theme.spacing(3),
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
  },
  signIn: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
}));

export default useStyles;
