import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
    justifyContent: 'left',
    borderBottom: `1px solid ${theme.palette.border}`,
    borderRadius: 'unset',
  },
  text: {
    width: '4rem',
  },
}));
