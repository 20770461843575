import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Paper } from '@material-ui/core';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // border: `1px solid ${theme.palette.border}`,
    boxShadow: theme.custom.shadows.paper,
    border: 'none',
    borderRadius: '4px',
  },
}));

const Portlet = ({ children, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Paper
      {...rest}
      className={cx({
        [classes.root]: true,
        [className]: className,
      })}
      elevation={0}
      outlined="true"
      squared="false"
    >
      {children}
    </Paper>
  );
};

Portlet.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.any,
};

export default Portlet;
