import React from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import theme from 'theme';
import { AuthProvider } from 'auth';
import { FirebaseProvider } from 'utils/firebase';
import { firebaseConfig } from './config';
import { Routes } from './routes';
import './App.css';

const browserHistory = createBrowserHistory();

const App = () => {
  return (
    <FirebaseProvider firebaseConfig={firebaseConfig} firebaseAppName="oversight">
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <SnackbarProvider autoHideDuration={3000} maxSnack={3}>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </AuthProvider>
    </FirebaseProvider>
  );
};

export default App;
