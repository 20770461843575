import React from 'react';
import { Grid } from '@material-ui/core';
import TeamMembersTable from './TeamMembersTable';
import useStyles from './TeamMembersView.css';

const TeamMembersView = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <TeamMembersTable />
        </Grid>
      </Grid>
    </div>
  );
};

export default TeamMembersView;
