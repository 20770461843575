import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '400px',
    maxWidth: '100%',
    maxHeight: 400,
    position: 'relative',
    overflow: 'auto',
    paddingBottom: theme.spacing(1),
  },
  badge: {
    right: -15,
    top: 23,
    border: `2px solid white`,
    padding: 5,
    fontSize: 10,
  },
}));
