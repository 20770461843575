export const STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  DENIED: 'denied',
  COMPLETED: 'completed',
};

export const STATUS_COLORS = {
  pending: '#616161',
  approved: '#2196F3',
  denied: '#F44336',
  canceled: '#FF9800',
  completed: '#4CAF50',
};

export const EMAIL_COLLECTION = 'mail';

export const OVERSIGHT_LINK =
  process.env.REACT_APP_ENV === 'prod'
    ? 'https://oversight.productdock.rs/'
    : 'https://oversight-dev-908cf.firebaseapp.com/';
