import { budgetCostItems } from 'constants/eduBudget';

export const createNewBudgetCosts = budgetCosts => {
  const newBudgetCosts = [];

  budgetCosts.forEach(cost => {
    newBudgetCosts.push({ budgetCost: cost, invoiceIds: [], amount: 0 });
  });
  return newBudgetCosts;
};

export const areThereInvoices = budgetCosts => {
  const result = budgetCosts.filter(cost => cost.invoiceIds.length !== 0);
  if (result.length !== 0) return true;
  return false;
};

export const filterBudgetCostItems = excluded => {
  const result = budgetCostItems.filter(item => {
    const sth = excluded.filter(exc => exc.budgetCost === item);
    if (sth.length === 0) return true;
    return false;
  });
  return result;
};

export const budgetCostListIncludes = (budgetCosts, item) => {
  const result = budgetCosts.filter(cost => cost.budgetCost === item);
  if (result.length !== 0) return true;
  return false;
};

export const getDefaultBudgetCost = type => {
  if (type === 'Workshop' || type === 'Conference') {
    return [];
  }
  return [{ budgetCost: 'Bill', invoiceIds: [] }];
};
