import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  counter: {
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 300,
    fontSize: '10px',
    lineHeight: '12px',
    marginTop: '0.25rem',
  },
  notes: {
    color: '#979797',
  },
  field: {
    marginTop: '-0.75rem',
  },
}));
