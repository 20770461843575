import React, { useContext } from 'react';
import { Formik } from 'formik';
import YupString from 'yup/lib/string';
import YupObject from 'yup/lib/object';
import { withSnackbar } from 'notistack';
import { getFirestore, collection, addDoc } from 'firebase/firestore';
import { addEmailToCollection, formatEmailEduBudget } from 'utils/functions/emailHelpers';
import { getDefaultBudgetCost } from 'utils/functions/budgetCostsHelpers';
import { useAuth } from '../../../auth';
import { FirebaseContext } from '../../../utils/firebase';
import { useManagerEmails } from '../../../hooks/useManagerEmails';
import EduBudgetRequestForm from './EduBudgetRequestForm';

const EDU_BUDGET_REQUESTS = 'edu_budget_requests';

const createEduBudgetRequest = (eduBudgetObject, firebaseApp) => {
  const database = getFirestore(firebaseApp);

  return addDoc(collection(database, EDU_BUDGET_REQUESTS), eduBudgetObject);
};

const mapValuesToEduBudget = async (
  values,
  user,
  firebaseApp,
  enqueueSnackbar,
  managerEmails,
  userAvailableEduBudget,
  preliminaryEduBudget,
  setPreliminaryEduBudget,
) => {
  if (values.totalAmount <= userAvailableEduBudget) {
    const eduBudgetObject = {
      userId: user.uid,
      userTenant: user.tenant.id,
      type: values.type,
      budgetCosts: values.budgetCosts || getDefaultBudgetCost(values.type),
      title: values.title,
      description: values.description,
      link: values.link,
      requestedBy: {
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
      },
      requestedAt: new Date(),
      totalAmount: values.totalAmount,
      userAvailableEduBudget: user.availableEduBudget,
      startDate:
        values.type === 'Conference' || values.type === 'Workshop'
          ? new Date(values.startDate)
          : null,
      endDate:
        values.type === 'Conference' || values.type === 'Workshop'
          ? new Date(values.endDate)
          : null,
      status: 'pending',
    };

    const emailObject = formatEmailEduBudget(
      [user.email, ...managerEmails],
      user.firstName,
      user.lastName,
      values.type,
      values.title,
      values.description,
      values.link,
      'pending',
      '',
    );

    setPreliminaryEduBudget(preliminaryEduBudget + values.totalAmount);
    createEduBudgetRequest(eduBudgetObject, firebaseApp)
      .then(() => {
        enqueueSnackbar('Education budget request successfully submitted', {
          variant: 'success',
        });
        addEmailToCollection(firebaseApp, emailObject);
      })
      .catch(err => {
        enqueueSnackbar('Error when submitting education budget request', {
          variant: 'error',
        });
        console.error('Error writing document: ', err);
      });
  } else {
    enqueueSnackbar('Sorry, there is not enough education budget for this request', {
      variant: 'error',
    });
  }
};

const EduBudgetRequestFormContainer = ({
  enqueueSnackbar,
  userAvailableEduBudget,
  preliminaryEduBudget,
  setPreliminaryEduBudget,
}) => {
  const { user } = useAuth();
  const { firebaseApp } = useContext(FirebaseContext);
  const managerEmails = useManagerEmails(false, true);
  const greaterThanZeroRegExp = /^[1-9][0-9]*$/;

  const validationSchema = YupObject().shape({
    type: YupString()
      .required('Type is required')
      .nullable(),
    title: YupString()
      .required('Title is required')
      .nullable(),
    description: YupString()
      .required('Description is required')
      .nullable(),
    totalAmount: YupString()
      .matches(greaterThanZeroRegExp, 'The amount should be greater than 0')
      .required('Preliminary amount is required')
      .nullable(),
    link: YupString()
      .matches(
        // eslint-disable-next-line no-useless-escape
        /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&=]*)/,
        'Please use a valid link',
      )
      .required('Link is required')
      .nullable(),
    startDate: YupString()
      .when('type', (type, schema) => {
        if (type === 'Conference' || type === 'Workshop')
          return schema.required('Start date is required');
        return schema;
      })
      .nullable(),
    endDate: YupString()
      .when('type', (type, schema) => {
        if (type === 'Conference' || type === 'Workshop')
          return schema.required('End date is required');
        return schema;
      })
      .nullable(),
  });

  return (
    <Formik
      enableReinitialize
      initialErrors={[]}
      validationSchema={validationSchema}
      initialValues={{}}
      onSubmit={async (values, { resetForm }) => {
        await mapValuesToEduBudget(
          values,
          user,
          firebaseApp,
          enqueueSnackbar,
          managerEmails,
          userAvailableEduBudget,
          preliminaryEduBudget,
          setPreliminaryEduBudget,
        );
        resetForm();
      }}
    >
      {form => <EduBudgetRequestForm formProps={form} />}
    </Formik>
  );
};

export default withSnackbar(EduBudgetRequestFormContainer);
