import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { Portlet, PortletContent, PortletHeader, PortletLabel } from '../../components/Portlets';
import { Status } from '../../components/Status';
import { Cell } from '../../components/Cell';
import { STATUS_COLORS } from '../../constants/requestStatus';
import { formatDate, snapToArray } from '../../utils';
import { FirebaseContext } from '../../utils/firebase';
import { useAuth } from '../../auth';

import useStyles from './HardwareRequestsTable.css';

const HardwareRequestsTable = ({ requests, setRequests, enqueueSnackbar }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { firebaseApp } = useContext(FirebaseContext);
  const { user } = useAuth();
  const database = getFirestore(firebaseApp);

  const loadRequests = useCallback(async () => {
    const queryRequests = query(
      collection(database, 'hardware_requests'),
      where('userId', '==', user.uid),
      orderBy('requestedAt', 'desc'),
    );

    try {
      const snapshot = await getDocs(queryRequests);
      const result = snapToArray(snapshot);
      setRequests(result);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [database, setRequests, user.uid]);

  const deleteRequest = useCallback(
    async id => {
      try {
        await deleteDoc(doc(database, 'hardware_requests', id));
        enqueueSnackbar('Hardware request successfully deleted', {
          variant: 'success',
        });
        loadRequests();
      } catch (error) {
        enqueueSnackbar('Error when deleting hardware request', {
          variant: 'error',
        });
        console.error(error);
      }
    },
    [database, enqueueSnackbar, loadRequests],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hideActionsColumnAndCell = requests =>
    requests.filter(request => request.status === 'pending').length > 0;

  useEffect(() => {
    setLoading(true);
    loadRequests();
  }, [firebaseApp, loadRequests, user]);

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      {requests.length ? (
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${requests.length} in total`}
            title="Latest Requests"
            className={classes.title}
          />
        </PortletHeader>
      ) : (
        ''
      )}
      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} className={classes.portletContent} noPadding>
          {requests.length ? (
            <>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Requested at</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    {hideActionsColumnAndCell(requests) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {requests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(({ id, description, requestedAt, title, status = 'pending' }) => (
                      <TableRow className={classes.tableRow} hover key={id} data-cy={description}>
                        <Cell>
                          <div className={classes.statusWrapper}>
                            <Status
                              className={classes.status}
                              color={STATUS_COLORS[status]}
                              size="sm"
                            />
                            {status}
                          </div>
                        </Cell>
                        <Cell>{formatDate(requestedAt)}</Cell>
                        <Cell className={classes.tableCell}>{title}</Cell>
                        <Cell className={classes.tableCell}>{description}</Cell>
                        {hideActionsColumnAndCell(requests) && (
                          <Cell className={classes.tableCell}>
                            {status === 'pending' ? (
                              <Button
                                size="small"
                                onClick={() => {
                                  deleteRequest(id);
                                }}
                                className={classes.deleteButton}
                                data-cy="cancelButton"
                              >
                                DELETE
                              </Button>
                            ) : (
                              <div />
                            )}
                          </Cell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={requests.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No hardware requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default withSnackbar(HardwareRequestsTable);
