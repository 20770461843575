import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { withStyles } from '@material-ui/core/styles';
import { TableCell } from '@material-ui/core';
import styles from './Cell.css';

const Cell = ({ children, emptyElement = '-', width, classes }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <TableCell
      width={width}
      onClick={() => setOpen(!isOpen)}
      className={cx({
        [classes.tableCell]: true,
        [classes.oneLine]: !isOpen,
      })}
    >
      {children || emptyElement}
    </TableCell>
  );
};

Cell.propTypes = {
  children: PropTypes.any,
  emptyElement: PropTypes.any,
  width: PropTypes.number,
  classes: PropTypes.any,
};

export default withStyles(styles)(Cell);
