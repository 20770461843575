import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import cx from 'classnames';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useAuth } from '../../../auth';
import { apiClient } from '../../../api-client';
import { useStyles } from './Calendar.css';

const VACATION_CALENDAR_TYPE = 'vacation';
const OUT_OF_OFFICE_TYPE = 'out-of-office';

const localizer = momentLocalizer(moment);

const VacationCalendar = ({ enqueueSnackbar, newOutOfOffices }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [myEventsList, setMyEventsList] = useState([]);
  const { user, userToken } = useAuth();

  const addOutOfOfficeEventOptimistically = event => {
    const newEvent = {
      id: 'randomid',
      start: new Date(event.startDate.toISOString()),
      end: new Date(event.endDate.toISOString()),
      title: `${event.requestType}: ${user.displayName}`,
      type: 'out-of-office',
    };
    setMyEventsList([...myEventsList, newEvent]);
  };

  useEffect(() => {
    if (newOutOfOffices) {
      addOutOfOfficeEventOptimistically(newOutOfOffices);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newOutOfOffices]);

  const handleLoadEvents = async () => {
    try {
      const url = `/calendar/events?calendar=vacation&calendar=out-of-office&tenantId=${user.tenant.id}`;
      const res = await apiClient('GET', url, userToken);
      const allEvents = [...res.data.vacation, ...res.data.outOfOffice].map(e => ({
        ...e,
        start: new Date(e.start),
        end: new Date(e.end),
      }));

      setMyEventsList(allEvents);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(
        err.message || 'something went wrong', // TODO calendar error message only?
        {
          variant: 'error',
          autoHideDuration: 10000,
        },
      );
    }
  };

  useEffect(() => {
    handleLoadEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventStyleGetter = event => {
    let backgroundColor;
    if (event.type === VACATION_CALENDAR_TYPE) {
      backgroundColor = theme.palette.secondary.dark;
    } else if (event.type === OUT_OF_OFFICE_TYPE) {
      backgroundColor = theme.palette.danger.dark;
    }
    const style = {
      backgroundColor,
    };
    return {
      style,
    };
  };

  return (
    <div className={cx(classes.root, myEventsList.length === 0 ? classes.loading : '')}>
      <Grid item md={12} xl={12} xs={12}>
        <Calendar
          style={{
            boxShadow: theme.custom.shadows.paper,
            background: 'white',
            border: `none`,
            padding: 0,
          }}
          onDrillDown={null}
          drilldownView={null}
          localizer={localizer}
          events={myEventsList || []}
          startAccessor="start"
          endAccessor="end"
          popup
          eventPropGetter={eventStyleGetter}
        />
      </Grid>
    </div>
  );
};

export default withSnackbar(VacationCalendar);
