import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
  },
  title: {
    '@media (max-width:480px)': {
      '& h5': {
        fontSize: '1.3rem',
      },
      '& h6': {
        marginLeft: '0.5rem',
        fontSize: '0.7rem',
        width: '3rem',
      },
    },
  },
  portletContent: {
    minWidth: '600px',
    overflow: 'auto',
  },
  contained: {
    color: 'red',
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  tableRow: {
    cursor: 'pointer',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  empty: {
    padding: 24,
    paddingTop: 0,
    fontWeight: 300,
    color: '#777',
  },
  cancelButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#c62828',
    },
  },
}));
