/* eslint-disable  jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

import { PowerSettingsNew as SignoutIcon } from '@material-ui/icons';
import { useAuth } from 'auth';
import { useStyles } from './SettingsMenu.css';

const SettingsMenu = ({ className }) => {
  const classes = useStyles();
  const rootClassName = classNames(classes.root, className);
  const { user, signOut } = useAuth();

  return (
    <div className={rootClassName}>
      <div className={classes.header}>
        <Typography variant="h6">{user.displayName}</Typography>
        <Typography className={classes.subtitle} variant="body2">
          {user.email}
        </Typography>
      </div>
      <div className={classes.content}>
        <List className={classes.list} component="div">
          <Link to="#">
            <ListItem className={classes.listItem} component="div" onClick={signOut}>
              <ListItemIcon className={classes.listItemIcon}>
                <SignoutIcon className={classes.icon} />
              </ListItemIcon>
              <ListItemText
                classes={{ secondary: classes.listItemTextSecondary }}
                primary="Sign Out"
                secondary=""
              />
            </ListItem>
            <Divider />
          </Link>
        </List>
      </div>
    </div>
  );
};

SettingsMenu.propTypes = {
  className: PropTypes.string,
};

SettingsMenu.defaultProps = {
  className: '',
};

export default SettingsMenu;
