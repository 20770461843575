import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './DenyDialog.css';

const DenyDialog = ({
  setDialogOpen,
  type,
  setNote,
  onDeny,
  isProcessing,
  isDialogOpen,
  note = '',
  requestedBy = { firstName: '', lastName: '' },
}) => {
  const classes = useStyles();

  return (
    <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)} data-cy="deny-dialog">
      <DialogTitle>
        Do you want to add a note?
        <IconButton
          aria-label="close"
          onClick={() => setDialogOpen(false)}
          className={classes.dialogClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          The {`${type}`} request (requested by:{' '}
          {`${requestedBy.firstName} ${requestedBy.lastName}`}) has been denied. Please, write an
          explanation note.
        </DialogContentText>
        <TextField
          fullWidth
          id="note"
          label="Note"
          name="note"
          placeholder="Enter a note"
          multiline
          maxRows="6"
          value={note || ''}
          onChange={e => setNote(e.target.value)}
          margin="normal"
          inputProps={{ maxLength: 160 }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <div className={classes.counter}>{note.length}/160</div>
        <DialogActions>
          <Button onClick={e => onDeny(e, false)}>Proceed</Button>
          <Button disabled={isProcessing} onClick={e => onDeny(e, true)} data-cy="noteSubmit">
            Add a note
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DenyDialog;
