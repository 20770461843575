import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100vh',
    textAlign: 'center',
  },
  grid: {
    height: '100%',
    flexWrap: 'nowrap',
  },
  leftWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '0%',
    justifyContent: 'flex-end',
    backgroundColor: '#2B6096',
    borderRadius: '1rem',
    margin: '1rem',
    minWidth: '30.5rem',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  leftSection: {
    backgroundColor: '#2B6096',
    width: '27.5rem',
    height: '27.5rem',
    backgroundImage: 'url(/images/pd-image.png)',
    backgroundSize: 'cover',
    margin: '1.5rem',
  },
  logo: {
    height: theme.spacing(5.25),
    width: theme.spacing(5.25),
    backgroundImage: 'url(/images/logo.svg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  logoImage: {
    marginLeft: theme.spacing(2),
  },
  form: {
    padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexBasis: '700px',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  title: {
    width: '19.125rem',
    fontSize: '1.75rem',
  },
  subtitle: {
    color: theme.palette.text.secondary,
    marginTop: '2.1875rem',
  },
  info: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(4),
  },
  fields: {
    marginTop: theme.spacing(3),
  },
  textField: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2),
    },
  },
  progress: {
    display: 'block',
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  signUpButton: {
    marginTop: theme.spacing(2),
    width: '21.875rem',
    background: theme.palette.primary.main,
    boxShadow: '0px 1px 4px rgba(59, 59, 59, 0.1)',
    textTransform: 'none',
    borderRadius: theme.spacing(1),
  },
  signIn: {
    marginTop: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  signInUrl: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  fieldError: {
    color: theme.palette.danger.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  submitError: {
    color: theme.palette.danger.main,
    alignText: 'center',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  popper: {
    zIndex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  input: {
    width: '100%',
    '& + & ': {
      marginTop: theme.spacing(2),
    },
  },
  radioGroup: {
    width: '21.875rem',
  },
  radioButton: {
    margin: '0px',
    marginBottom: theme.spacing(1.5),
  },
}));

export default useStyles;
