import { apiClient } from '../../api-client';
import formatDate from './formatDate';

// creation of tempo worklogs is only available on prod
export const createTempoWorklog = (userToken, email, from, to, type) => {
  if (process.env.REACT_APP_ENV === 'prod') {
    const worklogData = {
      userEmail: email,
      from: formatDate(from, 'YYYY-MM-DD'),
      to: formatDate(to, 'YYYY-MM-DD'),
      type,
    };
    apiClient('POST', '/tempo/worklog', userToken, worklogData);
  }
};
