// Palette
import shadows from '../shadows';

export default {
  root: {
    border: 'none',
  },
  elevation1: {
    boxShadow: shadows.paper
  }
}
