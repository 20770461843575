import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { chain } from 'lodash';
import moment from 'moment';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Tooltip,
} from '@material-ui/core';
import {
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { v1 as uuidv1 } from 'uuid';
import { getUsersAssignedManagerEmail } from 'utils/functions/userEmailsHelpers';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from '../../components/Portlets';
import { Status } from '../../components/Status';
import { Cell } from '../../components/Cell';
import { formatDate, snapToArray, setDateHourToMidnight } from '../../utils';
import { STATUS_COLORS } from '../../constants/requestStatus';
import { FirebaseContext } from '../../utils/firebase';
import { addEmailToCollection, formatEmailVacation } from '../../utils/functions/emailHelpers';
import { useAuth } from '../../auth';
import RequestAction from './RequestAction';
import useStyles from './TeamVacationRequestsTable.css';
import Controls from './Controls';
import { apiClient } from '../../api-client';
import { useManagerEmails } from '../../hooks/useManagerEmails';
import { createTempoWorklog } from '../../utils/functions/tempoHelpers';

const TeamVacationRequestsTable = () => {
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const { user: authenticatedUser, userToken } = useAuth();

  const [isLoading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState(['pending']);
  const [nameFilter, setNameFilter] = useState('');
  const [vacationPeriod, setVacationPeriod] = useState('');
  const managerEmails = useManagerEmails(false, false) || [];
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const database = getFirestore(firebaseApp);

  const filterRequests = requests => {
    if (!requests) {
      return null;
    }

    const filterByName = ({ requestedBy: { firstName, lastName } }) => {
      const fields = [firstName, lastName, `${firstName} ${lastName}`];

      return fields.some(string => {
        return string.toLowerCase().startsWith(nameFilter.toLowerCase());
      });
    };

    const filterByPeriod = ({ vacationFrom, vacationTo }) => {
      if (!vacationPeriod) {
        return true;
      }

      return (
        vacationPeriod.start.isBefore(vacationFrom.toDate()) &&
        vacationPeriod.end.isSameOrAfter(vacationTo.toDate())
      );
    };

    return chain(requests)
      .filter(filterByName)
      .filter(filterByPeriod)
      .value();
  };

  const getVacationPeriods = years => {
    const year = moment().year();
    // const nextYear = moment()
    //   .add(1, 'year')
    //   .year();

    return Array.from(Array(years), (_, key) => {
      const start = moment([year, 0, 1]).subtract(key, 'year');
      const end = moment([year, 11, 31]).subtract(key, 'year');
      return { start, end, key };
    });
  };

  const formatRequestedBy = ({ firstName, lastName } = {}) => {
    return firstName && lastName ? (
      <div>
        {firstName} {lastName}
      </div>
    ) : (
      <div>{firstName || lastName}</div>
    );
  };

  const updateRequests = input => {
    const update = requests.map(request => {
      return request.id === input.requestId ? { ...request, ...input } : request;
    });

    setRequests(update);
  };

  const updateRequestStatusWithNote = async (requestId, status, processedBy, note) => {
    updateDoc(doc(database, 'vacation_requests', requestId), {
      status,
      processedBy,
      note,
    });
  };

  const updateRequestStatus = async (requestId, status, processedBy) => {
    updateDoc(doc(database, 'vacation_requests', requestId), {
      status,
      processedBy,
    });
  };

  const updateUserWithNotification = async ({ userId, requestId, status }) => {
    const userRef = doc(database, 'users', userId);
    const userSnap = await getDoc(userRef);

    const user = userSnap.data();

    const createNotification = (status, requestId) => ({
      id: uuidv1(),
      status,
      requestId,
      isNew: true,
      createdAt: Date.now(),
      type: 'request',
      for: 'vacation',
    });

    const notification = createNotification(status, requestId);
    const notifications = [...user.notifications, notification];
    const userUpdate = { notifications };

    return updateDoc(doc(database, 'users', userId), userUpdate);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateUserDays = useCallback(
    async (userId, duration) => {
      const calcDays = available => available + duration;
      const userRef = doc(database, 'users', userId);
      const userSnap = await getDoc(userRef);

      const user = userSnap.data();

      return updateDoc(doc(database, 'users', userId), {
        availableDays: calcDays(user.availableDays),
      });
    },
    [database],
  );

  const handleDeleteEvent = async (eventFrom, eventTo, userId) => {
    const url = '/calendar/event';
    const userRef = doc(database, 'users', userId);
    const userSnap = await getDoc(userRef);

    const user = userSnap.data();

    const body = {
      calendarType: 'vacation',
      title: 'Vacation',
      eventFrom: setDateHourToMidnight(eventFrom.toDate()),
      eventTo: setDateHourToMidnight(eventTo.toDate()),
      user: { name: user.displayName },
      tenantId: user.tenant.id,
    };

    const response = await apiClient('DELETE', url, userToken, body);
    if (response.statusCode > 204) {
      throw response;
    }
  };

  const sendUpdateEmail = async (
    requestedBy,
    vacationFrom,
    vacationTo,
    processedBy,
    noOfDays,
    description,
    status,
    note,
  ) => {
    const usersManager = await getUsersAssignedManagerEmail(
      requestedBy,
      authenticatedUser,
      database,
    );
    const emailObject = formatEmailVacation(
      [requestedBy.email, ...managerEmails, ...usersManager],
      requestedBy.firstName,
      requestedBy.lastName,
      vacationFrom,
      vacationTo,
      noOfDays,
      description,
      status,
      processedBy,
      note,
    );
    addEmailToCollection(firebaseApp, emailObject);
  };

  const onApproveRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    vacationFrom,
    vacationTo,
    noOfDays,
    description,
  }) => {
    try {
      await updateRequestStatus(requestId, status, authenticatedUser.email);
      await updateUserWithNotification({ userId, requestId, status });
      updateRequests({ requestId, status });
      sendUpdateEmail(
        requestedBy,
        vacationFrom,
        vacationTo,
        authenticatedUser.email,
        noOfDays,
        description,
        status,
      );
      createTempoWorklog(userToken, requestedBy.email, vacationFrom, vacationTo, 'vacation');

      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onDenyRequestAction = async ({
    requestId,
    userId,
    status,
    noOfDays,
    requestedBy,
    vacationFrom,
    vacationTo,
    description,
    note,
  }) => {
    try {
      await updateRequestStatusWithNote(requestId, status, authenticatedUser.email, note);
      await updateUserWithNotification({ userId, requestId, status });
      await updateUserDays(userId, noOfDays);
      updateRequests({ requestId, status });
      sendUpdateEmail(
        requestedBy,
        vacationFrom,
        vacationTo,
        authenticatedUser.email,
        noOfDays,
        description,
        status,
        note,
      );
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onCancelRequestAction = async ({
    requestId,
    userId,
    status,
    noOfDays,
    startDate,
    endDate,
    requestedBy,
    description,
  }) => {
    try {
      await updateRequestStatus(requestId, status, authenticatedUser.email);
      await updateUserWithNotification({ userId, requestId, status });
      await updateUserDays(userId, noOfDays);
      handleDeleteEvent(startDate, endDate, userId);
      updateRequests({ requestId, status });
      sendUpdateEmail(
        requestedBy,
        startDate,
        endDate,
        authenticatedUser.email,
        noOfDays,
        description,
        status,
      );
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const hideActionsColumnAndCell = filteredRequests =>
    filteredRequests.filter(
      request => request.status === 'pending' || request.status === 'approved',
    ).length > 0;

  useEffect(() => {
    setLoading(true);
    let queryRequests = query(
      collection(database, 'vacation_requests'),
      where('userTenant', '==', authenticatedUser.tenant.id),
      orderBy('requestedAt', 'desc'),
    );

    if (statusFilter.length !== 0) {
      queryRequests = query(
        collection(database, 'vacation_requests'),
        where('userTenant', '==', authenticatedUser.tenant.id),
        where('status', 'in', statusFilter),
        orderBy('requestedAt', 'desc'),
      );
    }

    getDocs(queryRequests)
      .then(snapshot => {
        setRequests(snapToArray(snapshot));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [database, statusFilter, page, rowsPerPage, authenticatedUser.tenant.id]);

  const filteredRequests = filterRequests(requests);

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      <PortletHeader className={classes.header} noDivider>
        <PortletLabel
          subtitle={`${filteredRequests.length} in total`}
          title="Latest Requests"
          className={classes.title}
        />

        <Controls
          periods={getVacationPeriods(2)}
          statusFilter={statusFilter}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          setStatusFilter={setStatusFilter}
          vacationPeriod={vacationPeriod}
          setVacationPeriod={setVacationPeriod}
          setPage={setPage}
        />
      </PortletHeader>

      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} noPadding>
          {filteredRequests.length ? (
            <>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Requested by</TableCell>
                    <TableCell>Duration</TableCell>
                    <Tooltip title="The number of available vacation days at the moment of request creation.">
                      <TableCell>Available days</TableCell>
                    </Tooltip>
                    <TableCell>Requested at</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Description</TableCell>
                    {(['pending', 'all', 'approved'].some(item => statusFilter.includes(item)) ||
                      statusFilter.length === 0) &&
                      hideActionsColumnAndCell(filteredRequests) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      ({
                        id: requestId,
                        userId,
                        requestedBy = {},
                        description,
                        noOfDays,
                        requestedAt,
                        vacationFrom,
                        vacationTo,
                        status = 'pending',
                        userAvailableDays,
                      }) => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={requestId}
                          data-cy={description}
                        >
                          <Cell>
                            <div className={classes.statusWrapper}>
                              <Status
                                className={classes.status}
                                color={STATUS_COLORS[status]}
                                size="sm"
                              />
                              {status}
                            </div>
                          </Cell>
                          <Cell>{formatRequestedBy(requestedBy)}</Cell>
                          <Cell>{noOfDays}</Cell>
                          <Cell>
                            <Tooltip title="The number of available vacation days at the moment of request creation.">
                              <div>{userAvailableDays}</div>
                            </Tooltip>
                          </Cell>
                          <Cell>{formatDate(requestedAt, 'MMM Do YY')}</Cell>
                          <Cell>{formatDate(vacationFrom, 'MMM Do YY')}</Cell>
                          <Cell>{formatDate(vacationTo, 'MMM Do YY')}</Cell>
                          <Cell>{description}</Cell>
                          {hideActionsColumnAndCell(filteredRequests) && (
                            <Cell width={150}>
                              {status === 'pending' || status === 'approved' ? (
                                <RequestAction
                                  userId={userId}
                                  noOfDays={+noOfDays}
                                  requestId={requestId}
                                  status={status}
                                  requestedBy={requestedBy}
                                  vacationFrom={vacationFrom}
                                  vacationTo={vacationTo}
                                  onApproveRequestAction={onApproveRequestAction}
                                  onDenyRequestAction={onDenyRequestAction}
                                  onCancelRequestAction={onCancelRequestAction}
                                  description={description}
                                />
                              ) : (
                                <div />
                              )}
                            </Cell>
                          )}
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={filteredRequests.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default TeamVacationRequestsTable;
