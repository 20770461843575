import { React, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { withSnackbar } from 'notistack';
import cx from 'classnames';

import { Button, Chip, CircularProgress } from '@material-ui/core';
import { AttachFile, SaveAlt, Close } from '@material-ui/icons';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage';

import { FirebaseContext } from 'utils/firebase';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';

import { useStyles } from './InvoiceUpload.css';

const InvoiceUpload = props => {
  const classes = useStyles();
  const { budgetCostName, eduBudgetRequestId, enqueueSnackbar, status } = props;

  const { firebaseApp } = useContext(FirebaseContext);
  const storage = getStorage(firebaseApp);
  const database = getFirestore(firebaseApp);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const inputRef = useRef(null);

  const getUploadedInvoices = useCallback(async () => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;
    const budgetCost = budgetCosts.find(item => item.budgetCost === budgetCostName);
    if (!budgetCost) return [];
    return budgetCost.invoiceIds;
  }, [budgetCostName, database, eduBudgetRequestId]);

  useEffect(() => {
    const fetchData = async () => {
      const invoiceIds = await getUploadedInvoices();
      setFiles(invoiceIds);
    };
    fetchData();
  }, [getUploadedInvoices]);

  const handleUploadClick = () => {
    inputRef.current?.click();
  };

  const updateBudgetCostInvoiceIds = async invoiceId => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;

    const updatedBudgetCosts = budgetCosts.map(cost => {
      if (cost.budgetCost === budgetCostName) {
        cost.invoiceIds.push(invoiceId);
      }
      return cost;
    });

    setFiles(files.concat([invoiceId]));

    updateDoc(doc(database, 'edu_budget_requests', eduBudgetRequestId), {
      budgetCosts: updatedBudgetCosts,
    });
  };

  const checkIfFileExists = async invoiceId => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;

    let foundCost = false;

    budgetCosts.forEach(cost => {
      if (cost.invoiceIds.includes(invoiceId)) foundCost = true;
    });

    return foundCost;
  };

  const handleFileChange = async e => {
    setLoading(true);
    if (!e.target.files) {
      return;
    }
    const currentFile = e.target.files[0];
    let invoiceId = `invoices/${eduBudgetRequestId}/${currentFile.name}`;

    const exists = await checkIfFileExists(invoiceId);
    if (exists) {
      const splitName = currentFile.name.split('.');
      invoiceId = `invoices/${eduBudgetRequestId}/${splitName[0]}_${Date.now()}.${splitName[1]}`;
    }

    const invoiceRef = ref(storage, invoiceId);
    await uploadBytes(invoiceRef, currentFile);
    await updateBudgetCostInvoiceIds(invoiceId);
    setLoading(false);
    e.target.value = null;
  };

  const deleteBudgetCostInvoiceIds = async invoiceId => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;

    const updatedBudgetCosts = budgetCosts.map(cost => {
      if (cost.budgetCost === budgetCostName) {
        const newInvoiceIds = cost.invoiceIds.filter(id => id !== invoiceId);
        return { ...cost, invoiceIds: newInvoiceIds };
      }
      return cost;
    });

    const newFiles = files.filter(file => file !== invoiceId);
    setFiles(newFiles);

    updateDoc(doc(database, 'edu_budget_requests', eduBudgetRequestId), {
      budgetCosts: updatedBudgetCosts,
    });
  };

  const downloadInvoice = async invoiceId => {
    const invoiceRef = ref(storage, invoiceId);
    await getDownloadURL(invoiceRef)
      .then(url => {
        const element = document.createElement('a');
        element.href = url;

        document.body.appendChild(element);

        element.click();
      })
      .catch(error => {
        console.error('Error downloading the file', error);
        enqueueSnackbar('Error downloading the file', {
          variant: 'error',
        });
      });
  };

  const deleteInvoice = invoiceId => {
    const invoiceRef = ref(storage, invoiceId);

    deleteObject(invoiceRef)
      .then(async () => {
        console.log('Deleted successfully');
        await deleteBudgetCostInvoiceIds(invoiceId);
      })
      .catch(error => {
        console.error('Error deleting the invoice', error);
        enqueueSnackbar('Error deleting the invoice', {
          variant: 'error',
        });
      });
  };

  return (
    <div className={classes.wrapper}>
      {files.length !== 0 && (
        <div className={classes.files}>
          {files.map(file => {
            return (
              <div key={file} className={classes.chipAndButton}>
                <Chip
                  label={file.split('/')[2]}
                  className={classes.invoiceChip}
                  onDelete={status === 'approved' ? () => deleteInvoice(file) : undefined}
                  deleteIcon={<Close className={classes.deleteInvoiceIcon} />}
                  onClick={() => {}}
                />
                <Button
                  className={classes.attachButton}
                  type="button"
                  onClick={() => downloadInvoice(file)}
                >
                  <SaveAlt className={classes.icon} fontSize="small" />
                </Button>
              </div>
            );
          })}
        </div>
      )}

      {status === 'approved' ? (
        <div className={classes.buttonAndText}>
          <Button
            className={
              files.length === 0
                ? classes.attachButton
                : cx(classes.attachButton, classes.attachButtonFiles)
            }
            type="button"
            onClick={handleUploadClick}
          >
            {// TODO use LoadingButton component after material-ui update
            loading ? (
              <CircularProgress color="secondary" size={15} />
            ) : (
              <AttachFile className={classes.icon} fontSize="small" />
            )}
          </Button>
          {files.length === 0 && <div>Upload your file</div>}
        </div>
      ) : (
        <div />
      )}

      <input type="file" ref={inputRef} onChange={handleFileChange} style={{ display: 'none' }} />
    </div>
  );
};

export default withSnackbar(InvoiceUpload);
