export const renderCapitalised = value => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const renderStatusFilter = (statusFilterList, includeCompleted) => {
  if (statusFilterList.length <= 2) {
    return statusFilterList.map(filter => renderCapitalised(filter)).join(', ');
  }
  if (includeCompleted ? statusFilterList.length >= 5 : statusFilterList.length >= 4) {
    return 'All';
  }
  return `${[renderCapitalised(statusFilterList[0]), renderCapitalised(statusFilterList[1])].join(
    ', ',
  )} 
  \u00A0\u00A0+${statusFilterList.length - 2}`;
};

export const SELECT_ALL_VALUE = 'Select All';
