import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  topbar: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 'auto',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    zIndex: 1300,
  },
  topbarShift: {
    marginLeft: '271px',
    width: 'calc(-271px + 100vw)',
  },
  drawerPaper: {
    zIndex: 1200,
    width: '271px',
    border: 'none',
    boxShadow: theme.custom.shadows.sidebar,
  },
  sidebar: {},
  content: {
    // paddingTop: '64px',
    // marginTop: '64px',
    backgroundColor: theme.palette.background.default,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
  },
  contentWrapper: {
    flex: 1,
    paddingTop: '64px',
  },
  contentShift: {
    marginLeft: '270px',
  },
}));
