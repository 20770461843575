import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Divider, List, ListItem, ListItemText } from '@material-ui/core';

import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { FirebaseContext } from 'utils/firebase';
import { SubHeader } from './SubHeader';
import { RequestItem } from './RequestItem';

import { useStyles } from './NotificationList.css';

const itemMapper = {
  request: {
    component: args => <RequestItem key={args.item.id} {...args} />,
  },
};

const NotificationList = ({ className, notifications, user }) => {
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);
  const rootClassName = cx(classes.root, className);

  const handleRemove = async id => {
    const update = user.notifications.filter(e => e.id !== id);
    return updateDoc(doc(database, 'users', user.uid), { notifications: update });
  };

  const Notifications = ({ data }) => {
    return data
      .map(item =>
        itemMapper[item.type].component({
          firebaseApp,
          handleRemove,
          item,
        }),
      )
      .reverse();
  };

  return (
    <List
      className={rootClassName}
      subheader={<SubHeader notifications={notifications} user={user} />}
      aria-labelledby="Notifications"
    >
      <Divider />

      {notifications.length ? (
        <Notifications data={notifications} />
      ) : (
        <ListItem>
          <ListItemText>No new notifications</ListItemText>
        </ListItem>
      )}
    </List>
  );
};

NotificationList.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
  notifications: PropTypes.arrayOf(PropTypes.object).isRequired,
};

NotificationList.defaultProps = {
  className: '',
  user: {},
};

export default NotificationList;
