import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { formatDate } from '../../../utils';
import { useStyles } from './Request.css';

const Item = ({ label, value, classes }) => (
  <div className={classes.item}>
    <Typography variant="body2" component="span">
      {label}:
    </Typography>
    <Typography variant="body2" component="span">
      {value}
    </Typography>
  </div>
);

const Request = ({ isOpen, data }) => {
  const classes = useStyles();

  if (!isOpen) {
    return null;
  }

  const requestedAt = formatDate(data.requestedAt, 'MMM Do YY');

  let startDate = '';
  let endDate = '';
  if (data.for === 'vacation') {
    startDate = formatDate(data.vacationFrom, 'MMM Do YY');
    endDate = formatDate(data.vacationTo, 'MMM Do YY');
  }

  return (
    <div className={classes.root}>
      <Item classes={classes} value={requestedAt} label="Requested at" />

      {data.for === 'vacation' && (
        <>
          <Item classes={classes} value={`${startDate} - ${endDate}`} label="From-to" />
          <Item classes={classes} value={data.noOfDays} label="Duration" />
        </>
      )}
    </div>
  );
};

Request.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.object,
};

Request.defaultProps = {
  data: {},
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  classes: PropTypes.any.isRequired,
};

export default Request;
