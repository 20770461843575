/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from 'react';
import cx from 'classnames';
import { withSnackbar } from 'notistack';
import { doc, getDoc, getFirestore, updateDoc } from 'firebase/firestore';
import { FirebaseContext } from 'utils/firebase';
import { deleteObject, getStorage, ref } from 'firebase/storage';
import { AccessTime, DeleteOutline, Done, AddOutlined } from '@material-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  areThereInvoices,
  budgetCostListIncludes,
  filterBudgetCostItems,
} from 'utils/functions/budgetCostsHelpers';

import { DarkTooltip } from 'components/DarkTooltip';
import { InlineEdit } from 'components/InlineEdit';

import { useStyles } from './BudgetCosts.css';
import { InvoiceUpload } from './InvoiceUpload';
import { NotesEdit } from './NotesEdit';

const EnhancedTableHead = props => {
  const {
    onSelectAllClick,
    numSelected,
    rowCount,
    onDelete,
    onAddNewRow,
    status,
    currentBudgetCosts,
  } = props;
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {status === 'approved' && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              color="primary"
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}

        <TableCell>
          {status === 'approved' ? (
            <Box className={classes.deleteWrapper}>
              <IconButton
                onClick={onDelete}
                variant="outlined"
                disabled={numSelected === 0}
                className={
                  numSelected === 0
                    ? cx(classes.deleteButton, classes.deleteButtonDisabled)
                    : classes.deleteButton
                }
              >
                <DeleteOutline />
              </IconButton>
              <Typography variant="body2" className={classes.selected}>
                {numSelected} Selected
              </Typography>
            </Box>
          ) : (
            <div />
          )}
        </TableCell>

        <TableCell align="right" colSpan={3}>
          {status === 'approved' ? (
            <Button
              onClick={onAddNewRow}
              variant="outlined"
              startIcon={<AddOutlined />}
              className={classes.addButton}
              disabled={budgetCostListIncludes(currentBudgetCosts, '')}
            >
              Add budget cost
            </Button>
          ) : (
            <div />
          )}
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

const BudgetCosts = props => {
  const { budgetCosts, eduBudgetRequestId, eduBudgetRequestStatus, enqueueSnackbar } = props;
  const { firebaseApp } = useContext(FirebaseContext);
  const storage = getStorage(firebaseApp);
  const database = getFirestore(firebaseApp);
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [currentBudgetCosts, setCurrentBudgetCosts] = useState([]);
  const [newBudgetCostName, setNewBudgetCostName] = useState('');
  const [filteredItems, setFilteredItems] = useState([]);

  useEffect(() => {
    setCurrentBudgetCosts(budgetCosts);
  }, [setCurrentBudgetCosts, budgetCosts]);

  useEffect(() => {
    const items = filterBudgetCostItems(currentBudgetCosts);
    setFilteredItems(items);
  }, [currentBudgetCosts]);

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = currentBudgetCosts.map(n => n.budgetCost);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const deleteInvoices = invoices => {
    invoices.forEach(invoice => {
      const invoiceRef = ref(storage, invoice);

      deleteObject(invoiceRef)
        .then(async () => {
          console.log('Deleted successfully');
        })
        .catch(error => {
          console.error('Error deleting the invoice', error);
          enqueueSnackbar('Error deleting the invoice', {
            variant: 'error',
          });
        });
    });
  };

  const handleDeleteSelected = async () => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;
    const invoicesToDelete = [];
    setSelected([]);

    const updatedBudgetCosts = budgetCosts.filter(cost => {
      if (!selected.includes(cost.budgetCost)) {
        return true;
      }
      if (cost.invoiceIds.length !== 0) {
        cost.invoiceIds.forEach(invoice => {
          invoicesToDelete.push(invoice);
        });
      }

      return false;
    });

    const newBudgetCosts = budgetCosts.filter(
      budgetCost => !selected.includes(budgetCost.budgetCost),
    );
    setCurrentBudgetCosts(newBudgetCosts);

    updateDoc(doc(database, 'edu_budget_requests', eduBudgetRequestId), {
      budgetCosts: updatedBudgetCosts,
    });
    if (invoicesToDelete.length !== 0) deleteInvoices(invoicesToDelete);
  };

  const handleAddNewRow = () => {
    const newBudgetCost = { budgetCost: '', amount: 0, invoiceIds: [] };
    setCurrentBudgetCosts(currentBudgetCosts.concat([newBudgetCost]));
  };

  const saveNewBudgetCost = async () => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;

    const updatedBudgetCosts = budgetCosts.concat([
      { budgetCost: newBudgetCostName, amount: 0, invoiceIds: [] },
    ]);

    const isNameUnique = !budgetCostListIncludes(currentBudgetCosts, newBudgetCostName);

    if (newBudgetCostName !== '' && isNameUnique) {
      updateDoc(doc(database, 'edu_budget_requests', eduBudgetRequestId), {
        budgetCosts: updatedBudgetCosts,
      });
      setCurrentBudgetCosts(updatedBudgetCosts);
      setNewBudgetCostName('');
    }
  };

  const updateBudgetCostField = async (id, data) => {
    const eduBudgetRequestRef = doc(database, 'edu_budget_requests', eduBudgetRequestId);
    const eduBudgetRequestSnap = await getDoc(eduBudgetRequestRef);

    const eduBudgetRequest = eduBudgetRequestSnap.data();

    const { budgetCosts } = eduBudgetRequest;

    const updatedBudgetCosts = budgetCosts.map(cost => {
      if (cost.budgetCost === id) {
        return { ...cost, ...data };
      }
      return cost;
    });

    updateDoc(doc(database, 'edu_budget_requests', eduBudgetRequestId), {
      budgetCosts: updatedBudgetCosts,
    });
    setCurrentBudgetCosts(updatedBudgetCosts);
  };

  const onEnter = event => {
    if (event.keyCode === 13) {
      saveNewBudgetCost();
    }
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  if (!currentBudgetCosts.length) {
    return (
      eduBudgetRequestStatus === 'approved' && (
        <div className={classes.noCostsWrapper}>
          <div className={classes.noCostsButton}>
            {eduBudgetRequestStatus === 'approved' ? (
              <Button
                onClick={handleAddNewRow}
                variant="outlined"
                startIcon={<AddOutlined />}
                className={classes.addButton}
                disabled={budgetCostListIncludes(currentBudgetCosts, '')}
              >
                Add budget cost
              </Button>
            ) : (
              <div />
            )}
          </div>
          <div className={classes.noticeWrapper}>
            <Typography className={classes.noticeTitle}>
              There are no budget cost details yet
            </Typography>
            <Typography variant="subtitle1">
              Added invoices and payment details will appear here
            </Typography>
          </div>
        </div>
      )
    );
  }
  return (
    <Box>
      <Typography variant="subtitle1" className={classes.invoiceTitle}>
        Invoices
      </Typography>
      <Paper className={classes.paper}>
        <TableContainer className={classes.table}>
          <Table aria-labelledby="tableTitle" aria-label="enhanced table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={currentBudgetCosts.length}
              onDelete={handleDeleteSelected}
              onAddNewRow={handleAddNewRow}
              status={eduBudgetRequestStatus}
              currentBudgetCosts={currentBudgetCosts}
            />
            <TableHead className={classes.tableHead}>
              <TableRow>
                {eduBudgetRequestStatus === 'approved' && (
                  <TableCell className={classes.alignCell} />
                )}
                <TableCell className={classes.alignCell}>Budget costs</TableCell>
                <TableCell className={classes.alignCell}>Amount (EUR)</TableCell>
                <TableCell align="left" className={classes.alignCell}>
                  Notes
                </TableCell>
                {(eduBudgetRequestStatus === 'approved' ||
                  areThereInvoices(currentBudgetCosts)) && (
                  <TableCell className={classes.alignCell} align="left" width="338px">
                    <DarkTooltip title="Attached files: invoices, tickets, bills, etc.">
                      <span>Attachment</span>
                    </DarkTooltip>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {currentBudgetCosts.map((row, index) => {
                const isItemSelected = isSelected(row.budgetCost);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow tabIndex={-1} key={row.budgetCost} selected={isItemSelected}>
                    {eduBudgetRequestStatus === 'approved' && (
                      <TableCell className={classes.checkboxCell} padding="checkbox">
                        <Checkbox
                          role="checkbox"
                          color="primary"
                          aria-checked={isItemSelected}
                          onClick={event => handleClick(event, row.budgetCost)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                    )}

                    <TableCell
                      className={classes.alignCell}
                      align="left"
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      {row.budgetCost === '' && (
                        <div className={classes.autocomplete}>
                          <Autocomplete
                            id="new-budget-cost"
                            freeSolo
                            options={filteredItems}
                            onChange={(e, data) => {
                              setNewBudgetCostName(data);
                            }}
                            onInputChange={(e, data) => {
                              setNewBudgetCostName(data);
                            }}
                            onBlur={() => saveNewBudgetCost()}
                            onKeyDown={onEnter}
                            renderInput={props => (
                              <TextField
                                {...props}
                                placeholder="Purpose of payment"
                                inputProps={{ ...props.inputProps, maxLength: 60 }}
                                error={
                                  newBudgetCostName !== '' &&
                                  budgetCostListIncludes(currentBudgetCosts, newBudgetCostName)
                                }
                                helperText={
                                  newBudgetCostName !== '' &&
                                  budgetCostListIncludes(currentBudgetCosts, newBudgetCostName) &&
                                  'Enter unique budget cost name for this request'
                                }
                              />
                            )}
                          />
                          <div className={classes.counter}>{newBudgetCostName.length}/60</div>
                        </div>
                      )}
                      {row.budgetCost === 'Enrolment fee' ? (
                        <div>
                          {row.budgetCost} <span>(Final amount may include tax/VAT)</span>
                        </div>
                      ) : (
                        <div>{row.budgetCost} </div>
                      )}
                    </TableCell>
                    <TableCell
                      className={classes.alignCell}
                      align="left"
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      <div className={classes.totalAmount}>
                        {(eduBudgetRequestStatus === 'pending' ||
                          eduBudgetRequestStatus === 'approved') && (
                          <DarkTooltip
                            title="Preliminary amount - may differ until the 
              request is completed"
                          >
                            <AccessTime className={classes.accessTime} />
                          </DarkTooltip>
                        )}
                        {eduBudgetRequestStatus === 'completed' && (
                          <Done className={classes.done} />
                        )}

                        {eduBudgetRequestStatus === 'pending' ||
                        eduBudgetRequestStatus === 'approved' ? (
                          <InlineEdit
                            id={row.budgetCost}
                            name="amount"
                            value={row.amount || 0}
                            onUpdate={updateBudgetCostField}
                            tooltipTitle="Click to edit."
                            min={0}
                          />
                        ) : (
                          row.amount
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      className={classes.alignCell}
                      align="left"
                      component="th"
                      id={labelId}
                      scope="row"
                    >
                      <NotesEdit
                        id={row.budgetCost}
                        name="notes"
                        value={row.notes || ''}
                        onUpdate={updateBudgetCostField}
                        status={eduBudgetRequestStatus}
                      />
                    </TableCell>
                    {(eduBudgetRequestStatus === 'approved' ||
                      areThereInvoices(currentBudgetCosts)) && (
                      <TableCell align="left" className={classes.alignCell}>
                        <InvoiceUpload
                          budgetCostName={row.budgetCost}
                          eduBudgetRequestId={eduBudgetRequestId}
                          status={eduBudgetRequestStatus}
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};

export default withSnackbar(BudgetCosts);
