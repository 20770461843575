import { collection, getDocs, query, where } from 'firebase/firestore';
import snapToArray from './snapToArray';

export const getManagerEmails = async (authenticatedUser, database) => {
  const queryUsers = query(
    collection(database, 'users'),
    where('tenant.id', '==', authenticatedUser.tenant.id),
    where('roles.manager', '==', true),
  );

  const snapshot = await getDocs(queryUsers);
  const users = snapToArray(snapshot);

  return users.map(user => user.email);
};

export const getUsersAssignedManagerEmail = async (requestedBy, authenticatedUser, database) => {
  const queryUser = query(collection(database, 'users'), where('email', '==', requestedBy.email));

  const userSnap = await getDocs(queryUser);

  const user = snapToArray(userSnap);
  const sendToAll = Array.isArray(user[0].assignedManager) && user[0].assignedManager.length === 0;

  if (user[0].assignedManager && !sendToAll) {
    if (Array.isArray(user[0].assignedManager)) {
      return user[0].assignedManager.map(manager => manager.email);
    }
    return [user[0].assignedManager.email];
  }
  const emails = await getManagerEmails(authenticatedUser, database);
  return emails;
};
