import React, { useState, useCallback, useContext, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  Paper,
} from '@material-ui/core';
import { collection, getDocs, getFirestore, query, where, orderBy } from 'firebase/firestore';
import { snapToArray } from '../../utils';
import { FirebaseContext } from '../../utils/firebase';
import { VacationRequestFormContainer } from './VacationRequestForm';
import { HardwareRequestsFormContainer } from './HardwareRequestForm';
import { OutOfOfficeFormContainer } from './OutOfOfficeForm';
import { VacationTile } from './VacationTile';
import { BillableDaysTile } from './BillableDaysTile';
import { EduBudgetTile } from './EduBudgetTile';
import { useStyles } from './DashboardView.css';
import { EduBudgetRequestFormContainer } from './EduBudgetRequestForm';
import { useAuth } from '../../auth';

const DashboardView = () => {
  const classes = useStyles();
  const [requestForm, setRequestForm] = useState('vacation');
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);
  const { user } = useAuth();
  const [isLoading, setLoading] = useState(false);
  const [eduBudgetRequests, setEduBudgetRequests] = useState([]);
  const [preliminaryEduBudget, setPreliminaryEduBudget] = useState();

  const userAvailableEduBudget = user.availableEduBudget - preliminaryEduBudget || 0;

  const handleRequestForm = event => {
    setRequestForm(event.target.value);
  };

  const loadRequestsWithPreliminaryAmount = useCallback(async () => {
    const pendingQueries = query(
      collection(database, 'edu_budget_requests'),
      where('userId', '==', user.uid),
      where('status', '==', 'pending'),
      orderBy('requestedAt', 'desc'),
    );

    const approvedQueries = query(
      collection(database, 'edu_budget_requests'),
      where('userId', '==', user.uid),
      where('status', '==', 'approved'),
      orderBy('requestedAt', 'desc'),
    );

    try {
      const [pendingQueriesSnapshot, approvedQueriesSnapshot] = await Promise.all([
        getDocs(pendingQueries),
        getDocs(approvedQueries),
      ]);

      const pendingRequestsArray = snapToArray(pendingQueriesSnapshot);
      const approvedRequestsArray = snapToArray(approvedQueriesSnapshot);

      const result = pendingRequestsArray.concat(approvedRequestsArray);

      setEduBudgetRequests(result);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [database, user.uid]);

  useEffect(() => {
    setLoading(true);
    loadRequestsWithPreliminaryAmount();
  }, [loadRequestsWithPreliminaryAmount]);

  useEffect(() => {
    setPreliminaryEduBudget(
      !isLoading &&
        eduBudgetRequests.reduce((accumulator, item) => accumulator + item.totalAmount, 0),
    );
  }, [eduBudgetRequests, isLoading]);

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={4} md={12} sm={12} xs={12} className={classes.forms}>
          <Paper className={(classes.root, classes.selectHeader)} elevation={1}>
            <Typography variant="body2" component="span" className={classes.titleColor}>
              Create New Request
            </Typography>
            <FormControl
              variant="standard"
              sx={{
                m: 1,
                minWidth: 120,
                background: '#fff',
              }}
            >
              <InputLabel id="select-standard-label" className={classes.labelColor}>
                Request Type
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                labelId="select-standard-label"
                className={classes.select}
                id="select-standard"
                value={requestForm}
                onChange={handleRequestForm}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem value="vacation" name="vacation">
                  Vacation
                </MenuItem>
                <MenuItem value="outofoffice" name="outofoffice">
                  Out of Office
                </MenuItem>
                <MenuItem value="hardware" name="hardware">
                  Hardware
                </MenuItem>
                <MenuItem value="edubudget" name="edubudget">
                  Education Budget
                </MenuItem>
              </Select>
            </FormControl>
          </Paper>
          {(() => {
            if (requestForm === 'vacation') {
              return <VacationRequestFormContainer />;
            }
            if (requestForm === 'outofoffice') {
              return <OutOfOfficeFormContainer />;
            }
            if (requestForm === 'hardware') {
              return <HardwareRequestsFormContainer />;
            }
            if (requestForm === 'edubudget') {
              return (
                <EduBudgetRequestFormContainer
                  userAvailableEduBudget={userAvailableEduBudget}
                  preliminaryEduBudget={preliminaryEduBudget}
                  setPreliminaryEduBudget={setPreliminaryEduBudget}
                />
              );
            }
            return <VacationRequestFormContainer />;
          })()}
        </Grid>
        <Grid item lg={8} md={12} sm={12} xs={12}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <VacationTile />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <BillableDaysTile />
            </Grid>
            <Grid item lg={6} md={12} sm={12} xs={12}>
              <EduBudgetTile
                preliminaryEduBudget={preliminaryEduBudget}
                userAvailableEduBudget={userAvailableEduBudget}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardView;
