const padTo2Digits = num => {
  return num.toString().padStart(2, '0');
};

// 01/2022/18 (MM/DD/YYYY)
const formatOverlapDate = date => {
  return [padTo2Digits(date.getMonth() + 1), date.getFullYear(), padTo2Digits(date.getDate())].join(
    '/',
  );
};

export default (startDate1, endDate1, startDate2, endDate2) => {
  const year1 = startDate1.getFullYear();
  const year2 = startDate2.getFullYear();

  if (year1 !== year2) {
    return false; // Date ranges are not within the same calendar year
  }

  const formatedStartDate1 = formatOverlapDate(startDate1);
  const formatedEndDate1 = formatOverlapDate(endDate1);
  const formatedStartDate2 = formatOverlapDate(startDate2);
  const formatedEndDate2 = formatOverlapDate(endDate2);

  return formatedStartDate1 <= formatedEndDate2 && formatedEndDate1 >= formatedStartDate2;
};
