import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    background: 'transparent',
    border: 'none',
    boxShadow: 'none',
  },
  header: {
    '@media (max-width:480px)': {
      display: 'table',
      paddingBottom: '1.5rem',
    },
  },
  title: {
    '@media (max-width:480px)': {
      '& h5': {
        fontSize: '1.3rem',
      },
      '& h6': {
        marginLeft: '0.5rem',
        fontSize: '0.7rem',
        width: '3rem',
      },
    },
  },
  newEntryButton: {
    marginLeft: theme.spacing(1),
    boxShadow: 'none',
    color: theme.palette.text.primary,
  },
  contained: {
    color: 'red',
  },
  tableRow: {
    cursor: 'pointer',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  empty: {
    padding: 24,
    paddingTop: 0,
    fontWeight: 300,
    color: '#777',
  },
  table: {
    '& th': {
      whiteSpace: 'nowrap',
    },
  },
}));
