import { addDoc, collection, getFirestore } from 'firebase/firestore';
import { EMAIL_COLLECTION, STATUS_COLORS, OVERSIGHT_LINK } from '../../constants/emails';

export const formatEmailVacation = (
  emails,
  firstName,
  lastName,
  startDate,
  endDate,
  workingDays,
  description,
  status,
  processedBy,
  note,
) => {
  return {
    to: emails,
    template: {
      name: 'vacation',
      data: {
        name: firstName,
        surname: lastName,
        startDate: new Date(startDate.toDate()).toLocaleDateString(),
        endDate: new Date(endDate.toDate()).toLocaleDateString(),
        workingDays,
        description,
        status,
        statusColor: STATUS_COLORS[status],
        processedBy: processedBy ?? '',
        oversightLink: OVERSIGHT_LINK,
        note: note ?? '',
      },
    },
  };
};

export const formatEmailHardware = (
  emails,
  firstName,
  lastName,
  title,
  description,
  status,
  processedBy,
  note,
) => {
  return {
    to: emails,
    template: {
      name: 'hardware',
      data: {
        name: firstName,
        surname: lastName,
        title,
        description,
        status,
        statusColor: STATUS_COLORS[status],
        processedBy: processedBy ?? '',
        oversightLink: OVERSIGHT_LINK,
        note: note ?? '',
      },
    },
  };
};

export const formatEmailOutOfOffice = (
  emails,
  firstName,
  lastName,
  startDate,
  endDate,
  description,
  requestType,
) => {
  return {
    to: emails,
    template: {
      name: 'out_of_office',
      data: {
        name: firstName,
        surname: lastName,
        startDate: new Date(startDate.toDate()).toLocaleDateString(),
        endDate: new Date(endDate.toDate()).toLocaleDateString(),
        description,
        requestType,
        oversightLink: OVERSIGHT_LINK,
      },
    },
  };
};

export const formatEmailEduBudget = (
  emails,
  firstName,
  lastName,
  type,
  title,
  description,
  link,
  status,
  processedBy,
  note,
  addMessage = false,
) => {
  return {
    to: emails,
    template: {
      name: 'edu_budget',
      data: {
        name: firstName,
        surname: lastName,
        type,
        title,
        description,
        link,
        status,
        processedBy,
        oversightLink: OVERSIGHT_LINK,
        statusColor: STATUS_COLORS[status],
        addMessage,
        note: note ?? '',
      },
    },
  };
};

export const addEmailToCollection = (firebaseApp, emailObject) => {
  const database = getFirestore(firebaseApp);
  return addDoc(collection(database, EMAIL_COLLECTION), emailObject);
};
