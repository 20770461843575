import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: '1.915rem 1.5rem 0.875rem 1.5rem',
    borderRadius: '0.25rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0',
    gap: '1.5rem',
    flex: 'none',
    order: '0',
    flexGrow: '0',
  },
  content: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    justifyContent: 'space-between',
    display: 'flex',
    flexDirection: 'row',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '1.1rem',
    paddingBottom: '1.175rem',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  title: {
    color: '#000000',
    fontSize: '1.125rem',
    fontWeight: 600,
  },
  bottomLabel: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    color: '#4c4c4c',
  },
  value: {
    marginTop: '0.313rem',
    marginBottom: '0.313rem',
    fontWeight: 600,
    fontStyle: 'normal',
    color: '#000000',
  },
  counterTotal: {
    fontSize: '1rem',
    fontWeight: 300,
  },
  counterDisabled: {
    color: '#CCCCCC',
  },
  counterUsed: {
    fontWeight: 600,
  },
  counterCircle: {
    borderRadius: '50%',
    backgroundColor: '#F7F7F9',
    cursor: 'pointer',
  },
  progressPrimary: {
    color: theme.palette.primary.main,
  },
  progressSecondary: {
    color: '#CCCCCC',
  },
  progressTransparent: {
    color: 'transparent',
  },
  preliminaryAmount: {
    color: '#000000',
    display: 'flex',
    fontWeight: 300,
    cursor: 'pointer',
  },
  accessTime: {
    color: '#686868',
    marginRight: '0.5rem',
    width: '1.2rem',
    height: '1.2rem',
  },
}));
