import { white, primary, grey } from './colors';

export default {
  common: {
    black: '#031811',
    white,
    neutral: '#E4E7EB',
    muted: '#9EA0A4',
  },
  primary: {
    contrastText: white,
    ...primary,
  },
  secondary: {
    contrastText: white,
    main: '#c3c3c3',
    light: '#e6fafd',
    dark: '#00789e',
  },
  success: {
    contrastText: white,
    main: '#45B880',
    light: '#F1FAF5',
    dark: '#00783E',
  },
  info: {
    contrastText: white,
    main: '#1070CA',
    light: '#F1FBFC',
    dark: '#007489',
  },
  warning: {
    contrastText: white,
    main: '#FFB822',
    light: '#FDF8F3',
    dark: '#95591E',
  },
  danger: {
    contrastText: white,
    main: '#ED4740',
    light: '#FEF6F6',
    dark: '#BF0E08',
  },
  pending: {
    main: grey[700],
  },
  text: {
    primary: '#4c4c4c',
    secondary: '#626262',
    disabled: '#A6B1BB',
    white,
  },
  background: {
    default: '#F9F9F9',
    dark: '#172B4D',
    paper: white,
  },
  border: '#e5e5e5',
  divider: '#e5e5e5',
  portfolio: {
    contrastText: white,
    main: '#47d9cb',
    light: '#edfbfa',
    dark: '#2b827a',
  },
};
