import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core';
import { withSnackbar } from 'notistack';
import {
  collection,
  doc,
  getDocs,
  getFirestore,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { formatDate, snapToArray, setDateHourToMidnight } from '../../utils';
import { Cell } from '../../components/Cell';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from '../../components/Portlets';
import { Status } from '../../components/Status';
import { STATUS_COLORS } from '../../constants/requestStatus';
import { useAuth } from '../../auth';
import { FirebaseContext } from '../../utils/firebase';
import { addEmailToCollection, formatEmailVacation } from '../../utils/functions/emailHelpers';
import { apiClient } from '../../api-client';
import { useManagerEmails } from '../../hooks/useManagerEmails';
import useStyles from './VacationRequestsTable.css';

const VacationRequestsTable = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { firebaseApp } = useContext(FirebaseContext);
  const { user, userToken } = useAuth();
  const managerEmails = useManagerEmails(false, true);
  const database = getFirestore(firebaseApp);

  const loadRequests = useCallback(() => {
    const q = query(
      collection(database, 'vacation_requests'),
      where('userId', '==', user.uid),
      orderBy('requestedAt', 'desc'),
    );

    return getDocs(q)
      .then(snapshot => {
        const result = snapToArray(snapshot);
        setRequests(result);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [database, user.uid]);

  useEffect(() => {
    setLoading(true);
    loadRequests();
  }, [loadRequests]);

  const updateRequestStatus = useCallback(
    async (requestId, status) => {
      await updateDoc(doc(database, 'vacation_requests', requestId), { status });
      loadRequests();
    },
    [database, loadRequests],
  );

  const updateUserDays = useCallback(
    async (userId, duration) => {
      const calcDays = available => available + duration;

      return updateDoc(doc(database, 'users', userId), {
        availableDays: calcDays(user.availableDays),
      });
    },
    [database, user.availableDays],
  );

  const handleDeleteEvent = useCallback(
    async (eventFrom, eventTo) => {
      const url = '/calendar/event';
      const body = {
        calendarType: 'vacation',
        title: 'Vacation',
        eventFrom: setDateHourToMidnight(eventFrom.toDate()),
        eventTo: setDateHourToMidnight(eventTo.toDate()),
        user: { name: user.displayName },
        tenantId: user.tenant.id,
      };
      const response = await apiClient('DELETE', url, userToken, body);
      if (response.statusCode > 204) {
        throw response;
      }
    },
    [user, userToken],
  );

  const sendUpdateEmail = useCallback(
    (vacationFrom, vacationTo, noOfDays, description, status) => {
      const emailObject = formatEmailVacation(
        [user.email, ...managerEmails],
        user.firstName,
        user.lastName,
        vacationFrom,
        vacationTo,
        noOfDays,
        description,
        status,
        user.email,
      );
      addEmailToCollection(firebaseApp, emailObject);
    },
    [firebaseApp, user, managerEmails],
  );

  const cancelRequest = useCallback(
    async (id, noOfDays, startDate, endDate, description, status) => {
      try {
        await updateRequestStatus(id, 'canceled');
        await updateUserDays(user.uid, noOfDays);

        if (status !== 'pending') await handleDeleteEvent(startDate, endDate);

        enqueueSnackbar('Vacation request successfully canceled', {
          variant: 'success',
        });
        sendUpdateEmail(startDate, endDate, noOfDays, description, 'canceled');
      } catch (err) {
        enqueueSnackbar('Error when canceling vacation request', {
          variant: 'error',
        });
        console.error(err);
      }
    },
    [
      user,
      updateRequestStatus,
      updateUserDays,
      enqueueSnackbar,
      handleDeleteEvent,
      sendUpdateEmail,
    ],
  );

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hideActionsColumnAndCell = requests =>
    requests.filter(
      request =>
        request.status === 'pending' ||
        (request.vacationFrom.toDate() > new Date() && request.status === 'approved'),
    ).length > 0;

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      {requests.length ? (
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${requests.length} in total`}
            title="Latest Requests"
            className={classes.title}
          />
        </PortletHeader>
      ) : (
        ''
      )}
      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} className={classes.portletContent} noPadding>
          {requests.length ? (
            <>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Duration</TableCell>
                    <TableCell>Requested at</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Description</TableCell>
                    {hideActionsColumnAndCell(requests) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {requests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      ({
                        id,
                        description,
                        noOfDays,
                        requestedAt,
                        vacationFrom,
                        vacationTo,
                        status = 'pending',
                      }) => (
                        <TableRow className={classes.tableRow} hover key={id} data-cy={description}>
                          <Cell>
                            <div className={classes.statusWrapper}>
                              <Status
                                className={classes.status}
                                color={STATUS_COLORS[status]}
                                size="sm"
                              />
                              {status}
                            </div>
                          </Cell>
                          <Cell>{noOfDays}</Cell>
                          <Cell>{formatDate(requestedAt)}</Cell>
                          <Cell>{formatDate(vacationFrom)}</Cell>
                          <Cell>{formatDate(vacationTo)}</Cell>
                          <Cell className={classes.tableCell}>{description}</Cell>
                          {hideActionsColumnAndCell(requests) && (
                            <Cell className={classes.tableCell}>
                              {status === 'canceled' ||
                              status === 'denied' ||
                              (vacationFrom.toDate() <= new Date() && status === 'approved') ? (
                                <div />
                              ) : (
                                <Button
                                  size="small"
                                  onClick={() => {
                                    cancelRequest(
                                      id,
                                      noOfDays,
                                      vacationFrom,
                                      vacationTo,
                                      description,
                                      status,
                                    );
                                  }}
                                  className={classes.cancelButton}
                                  data-cy="cancelButton"
                                >
                                  CANCEL
                                </Button>
                              )}
                            </Cell>
                          )}
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={requests.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No vacation requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default withSnackbar(VacationRequestsTable);
