import React, { useState, useEffect, useContext } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from '@material-ui/core';
import { chain } from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  getDoc,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { getUsersAssignedManagerEmail } from 'utils/functions/userEmailsHelpers';
import { Portlet, PortletHeader, PortletLabel, PortletContent } from '../../../components/Portlets';
import { Status } from '../../../components/Status';
import { Cell } from '../../../components/Cell';
import { addEmailToCollection, formatEmailHardware } from '../../../utils/functions/emailHelpers';
import { STATUS_COLORS } from '../../../constants/requestStatus';
import { formatDate, snapToArray } from '../../../utils';
import { FirebaseContext } from '../../../utils/firebase';
import { useAuth } from '../../../auth';
import { RequestAction } from './request-action';
import { Controls } from './controls';

import useStyles from './TeamHardwareRequestsTable.css';
import { useManagerEmails } from '../../../hooks/useManagerEmails';

const TeamHardwareRequestsTable = () => {
  const classes = useStyles();

  const { firebaseApp } = useContext(FirebaseContext);
  const { user: authenticatedUser } = useAuth();
  const managerEmails = useManagerEmails(false, false) || [];
  const [isLoading, setLoading] = useState(false);
  const [requests, setRequests] = useState([]);
  const [statusFilter, setStatusFilter] = useState(['pending']);
  const [nameFilter, setNameFilter] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const database = getFirestore(firebaseApp);

  const filterRequests = requests => {
    if (!requests) {
      return null;
    }

    const filterByName = ({ requestedBy: { firstName, lastName } }) => {
      const fields = [firstName, lastName, `${firstName} ${lastName}`];

      return fields.some(string => {
        return string.toLowerCase().startsWith(nameFilter.toLowerCase());
      });
    };

    return chain(requests)
      .filter(filterByName)
      .value();
  };

  const formatRequestedBy = ({ firstName, lastName } = {}) => {
    return firstName && lastName ? (
      <div>
        {firstName} {lastName}
      </div>
    ) : (
      <div>{firstName || lastName}</div>
    );
  };

  const updateRequests = input => {
    const update = requests.map(request => {
      return request.id === input.requestId ? { ...request, ...input } : request;
    });

    setRequests(update);
  };

  const updateRequestStatusWithNote = (requestId, status, processedBy, note) => {
    return updateDoc(doc(database, 'hardware_requests', requestId), {
      status,
      processedBy,
      note,
    });
  };
  const updateRequestStatus = (requestId, status, processedBy) => {
    return updateDoc(doc(database, 'hardware_requests', requestId), {
      status,
      processedBy,
    });
  };

  const updateUserWithNotification = async ({ userId, requestId, status }) => {
    const userRef = doc(database, 'users', userId);
    const userSnap = await getDoc(userRef);
    const user = userSnap.data();

    const createNotification = (status, requestId) => ({
      id: uuidv1(),
      status,
      requestId,
      isNew: true,
      createdAt: Date.now(),
      type: 'request',
      for: 'hardware',
    });

    const notification = createNotification(status, requestId);
    const notifications = [...user.notifications, notification];
    const userUpdate = { notifications };

    return updateDoc(doc(database, 'users', userId), userUpdate);
  };

  const sendHardwareRequestEmail = async (requestedBy, description, title, status, note) => {
    const usersManager = await getUsersAssignedManagerEmail(
      requestedBy,
      authenticatedUser,
      database,
    );
    const emailObject = formatEmailHardware(
      [requestedBy.email, ...managerEmails, ...usersManager],
      requestedBy.firstName,
      requestedBy.lastName,
      title,
      description,
      status,
      authenticatedUser.email,
      note,
    );

    addEmailToCollection(firebaseApp, emailObject);
  };

  const onApproveRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    description,
    title,
  }) => {
    try {
      await updateRequestStatus(requestId, status, authenticatedUser.email);
      await updateUserWithNotification({ userId, requestId, status });
      updateRequests({ requestId, status });
      sendHardwareRequestEmail(requestedBy, description, title, status);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const onDenyRequestAction = async ({
    requestId,
    userId,
    status,
    requestedBy,
    description,
    title,
    note,
  }) => {
    try {
      await updateRequestStatusWithNote(requestId, status, authenticatedUser.email, note);
      await updateUserWithNotification({ userId, requestId, status });
      updateRequests({ requestId, status });
      sendHardwareRequestEmail(requestedBy, description, title, status);
      return Promise.resolve(true);
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRequests = filterRequests(requests);

  const hideActionsColumnAndCell = filteredRequests =>
    filteredRequests.filter(request => request.status === 'pending').length > 0;

  useEffect(() => {
    setLoading(true);

    let queryRequests = query(
      collection(database, 'hardware_requests'),
      where('userTenant', '==', authenticatedUser.tenant.id),
      orderBy('requestedAt', 'desc'),
    );

    if (statusFilter.length !== 0) {
      queryRequests = query(
        collection(database, 'hardware_requests'),
        where('userTenant', '==', authenticatedUser.tenant.id),
        where('status', 'in', statusFilter),
        orderBy('requestedAt', 'desc'),
      );
    }

    getDocs(queryRequests)
      .then(snapshot => {
        setRequests(snapToArray(snapshot));
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [authenticatedUser.tenant.id, database, statusFilter]);

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      <PortletHeader className={classes.header} noDivider>
        <PortletLabel
          subtitle={`${filteredRequests.length} in total`}
          title="Latest Requests"
          className={classes.title}
        />

        <Controls
          statusFilter={statusFilter}
          nameFilter={nameFilter}
          setNameFilter={setNameFilter}
          setStatusFilter={setStatusFilter}
          setPage={setPage}
        />
      </PortletHeader>

      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} noPadding>
          {filteredRequests.length ? (
            <>
              <Table className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell>Request by</TableCell>
                    <TableCell>Requested at</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Description</TableCell>
                    {(['pending', 'all', 'approved'].some(item => statusFilter.includes(item)) ||
                      statusFilter.length === 0) &&
                      hideActionsColumnAndCell(filteredRequests) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {filteredRequests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(
                      ({
                        id: requestId,
                        userId,
                        requestedBy = {},
                        description,
                        title,
                        requestedAt,
                        status = 'pending',
                      }) => (
                        <TableRow
                          className={classes.tableRow}
                          hover
                          key={requestId}
                          data-cy={description}
                        >
                          <Cell>
                            <div className={classes.statusWrapper}>
                              <Status
                                className={classes.status}
                                color={STATUS_COLORS[status]}
                                size="sm"
                              />
                              {status}
                            </div>
                          </Cell>
                          <Cell>{formatRequestedBy(requestedBy)}</Cell>
                          <Cell>{formatDate(requestedAt, 'MMM Do YY')}</Cell>
                          <Cell>{title}</Cell>
                          <Cell>{description}</Cell>
                          {hideActionsColumnAndCell(filteredRequests) && (
                            <Cell width={150}>
                              {status === 'pending' ? (
                                <RequestAction
                                  userId={userId}
                                  requestId={requestId}
                                  requestedBy={requestedBy}
                                  description={description}
                                  title={title}
                                  onApproveRequestAction={onApproveRequestAction}
                                  onDenyRequestAction={onDenyRequestAction}
                                />
                              ) : (
                                <div />
                              )}
                            </Cell>
                          )}
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={filteredRequests.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default TeamHardwareRequestsTable;
