import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from './HardwareRequestsView.css';

import HardwareRequestsTable from './HardwareRequestsTable';

const HardwareRequests = () => {
  const classes = useStyles();
  const [requests, setRequests] = useState([]);

  return (
    <div className={classes.root}>
      <Grid item lg={12} md={12} xl={12} xs={12}>
        <HardwareRequestsTable requests={requests} setRequests={setRequests} />
      </Grid>
    </div>
  );
};

export default HardwareRequests;
