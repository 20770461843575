import React, { useState, useMemo } from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import {
  Portlet,
  PortletToolbar,
  PortletContent,
  PortletFooter,
} from '../../../components/Portlets';
import { useStyles } from './HardwareRequestsForm.css';

const HardwareRequestForm = ({ onSubmitButtonClick }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [titleTouched, setTitleTouched] = useState(false);
  const [descriptionTouched, setDescriptionTouched] = useState(false);
  const classes = useStyles();

  const titleHelperText = useMemo(() => (titleTouched && !title ? 'Title is required' : ''), [
    titleTouched,
    title,
  ]);

  const descriptionHelperText = useMemo(
    () => (descriptionTouched && !description ? 'Description is required' : ''),
    [descriptionTouched, description],
  );

  const handleReset = () => {
    setTitle('');
    setDescription('');
    setTitleTouched(false);
    setDescriptionTouched(false);
  };

  const handleSubmit = () => {
    const payload = {
      title,
      description,
    };
    onSubmitButtonClick(payload);
    handleReset();
  };

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      <PortletContent className={classes.portletContent}>
        <Typography variant="body2" component="span" className={classes.title}>
          Hardware Request
        </Typography>
        <div className={classes.title}>
          <TextField
            fullWidth
            id="title"
            label="Title"
            name="title"
            placeholder="Enter a title"
            multiline
            maxRows="4"
            value={title}
            onChange={e => setTitle(e.target.value)}
            className={classes.textField}
            margin="normal"
            error={titleTouched && !title}
            onBlur={() => setTitleTouched(true)}
            helperText={titleHelperText}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div className={classes.description}>
          <TextField
            fullWidth
            id="description"
            label="Description"
            name="description"
            placeholder="Describe the request"
            multiline
            maxRows="4"
            value={description}
            onChange={e => setDescription(e.target.value)}
            onBlur={() => setDescriptionTouched(true)}
            error={descriptionTouched && !description}
            className={classes.textField}
            margin="normal"
            helperText={descriptionHelperText}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
      </PortletContent>
      <PortletFooter className={classes.portletRoot}>
        <PortletToolbar className={classes.toolbarButtons}>
          <Button disabled={!title && !description} onClick={handleReset} variant="contained">
            Reset
          </Button>
          <div className={classes.submitButton}>
            <Button
              disabled={!title || !description}
              color="primary"
              variant="contained"
              data-cy="hardwareSubmit"
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </div>
        </PortletToolbar>
      </PortletFooter>
    </Portlet>
  );
};

export default HardwareRequestForm;
