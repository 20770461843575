import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  counter: {
    display: 'flex',
    justifyContent: 'end',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '14px',
    marginTop: '4px',
  },
  dialogClose: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
}));
