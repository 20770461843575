import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  badge: {
    right: -15,
    top: 23,
    border: `2px solid white`,
    padding: 5,
    fontSize: 10,
  },
  item: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  isNew: {
    background: theme.palette.primary.xlight,
  },
  listItemIcon: {
    backgroundColor: 'transparent',
    marginRight: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    padding: 0,
    width: 30,
    height: 30,

    '& svg': {
      width: 14,
      height: 14,
    },
  },
  chip: {
    fontSize: 12,
    height: 'auto',
    border: 'none',
    backgroundColor: 'rgba(64, 111, 209, 0.08)',
  },
  closeButton: {
    '& svg': {
      width: 14,
      height: 14,
    },
  },
  progress: {
    marginLeft: 5,
  },
  subText: {
    display: 'flex',
  },
}));
