import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Typography, Paper } from '@material-ui/core';
import { AccessTime } from '@material-ui/icons';
import { getCurrentEduBudgetPeriod } from './getEduBudgetPeriod';
import { useStyles } from './EduBudgetTile.css';
import { DarkTooltip } from '../../../components/DarkTooltip';
import { useAuth } from '../../../auth';
import { useTenant } from '../../../hooks/useTenant';

const EduBudgetTile = ({ preliminaryEduBudget, userAvailableEduBudget }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const [currentEduBudgetPeriod, setCurrentEduBudgetPeriod] = useState('');
  const tenant = useTenant();

  const showPreliminaryEduBudget = preliminaryEduBudget > 0;
  const EDU_BUDGET_AMOUNT = tenant?.educationBudget.amount;
  const TOTAL_EDU_BUDGET =
    userAvailableEduBudget > EDU_BUDGET_AMOUNT ? userAvailableEduBudget : EDU_BUDGET_AMOUNT;

  useEffect(() => {
    setCurrentEduBudgetPeriod(getCurrentEduBudgetPeriod());
  }, [setCurrentEduBudgetPeriod]);

  return (
    <Paper className={classes.root} elevation={1}>
      <div className={classes.header}>
        <Typography className={classes.title} variant="body1" component="div">
          Education Budget
        </Typography>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {showPreliminaryEduBudget && (
              <DarkTooltip title="Preliminary amount, there are not completed requests">
                <Typography className={classes.preliminaryAmount} variant="body2" component="div">
                  <AccessTime className={classes.accessTime} />
                  {preliminaryEduBudget} EUR
                </Typography>
              </DarkTooltip>
            )}
            <Typography className={classes.value} variant="body1">
              <span>
                {user.availableEduBudget <= TOTAL_EDU_BUDGET
                  ? Math.round((TOTAL_EDU_BUDGET - user.availableEduBudget) * 100) / 100
                  : Math.round(TOTAL_EDU_BUDGET * 100) / 100}{' '}
                EUR used
              </span>
            </Typography>

            <Typography className={classes.bottomLabel} variant="body2" component="div">
              {currentEduBudgetPeriod}
            </Typography>
          </div>
        </div>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            size={75}
            thickness={0.5}
            className={(() => {
              if (userAvailableEduBudget === 0) {
                return classes.progressTransparent;
              }
              return classes.progressPrimary;
            })()}
            variant="determinate"
            value={(() => {
              if (userAvailableEduBudget === TOTAL_EDU_BUDGET) {
                return 100;
              }

              return 100 - ((TOTAL_EDU_BUDGET + userAvailableEduBudget) * 100) / TOTAL_EDU_BUDGET;
            })()}
          />
          <DarkTooltip
            title={(() => {
              if (userAvailableEduBudget === 0) {
                return 'No budget left for the current period';
              }
              return 'Total amount of budget left';
            })()}
          >
            <Box
              top={7}
              left={7}
              bottom={7}
              right={7}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.counterCircle}
            >
              <Typography
                className={classes.counterUsed}
                variant="subtitle1"
                id="availableEduBudget"
              >
                {(() => {
                  if (userAvailableEduBudget > TOTAL_EDU_BUDGET) {
                    return `${Math.round(TOTAL_EDU_BUDGET * 100) / 100}`;
                  }
                  if (userAvailableEduBudget === 0) {
                    return (
                      <span className={classes.counterDisabled}>
                        {Math.round(userAvailableEduBudget * 100) / 100}
                      </span>
                    );
                  }

                  return `${Math.round(userAvailableEduBudget * 100) / 100}`;
                })()}
              </Typography>
            </Box>
          </DarkTooltip>
        </Box>
      </div>
    </Paper>
  );
};

export default EduBudgetTile;
