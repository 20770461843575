const firebase = {
  /* PROD */
  prod: {
    // TODO: make new firebase project!
    apiKey: 'AIzaSyAJ46aSo1Zom0G-b1MHkF8o7-_W2h-Qb6w',
    authDomain: 'oversight-prod-43a01.firebaseapp.com',
    databaseURL: 'https://oversight-prod-43a01.firebaseio.com',
    projectId: 'oversight-prod-43a01',
    storageBucket: 'oversight-prod-43a01.appspot.com',
    messagingSenderId: '436105003928',
    appId: '1:436105003928:web:20bbfef5bf52b2a0b8a1ed',
    measurementId: 'G-T4F2BPDL6D',
  },
  /* NONPROD */
  dev: {
    apiKey: 'AIzaSyAjXWgf3iOhPvRl0F0zZVTizZCFKRQN-Z4',
    authDomain: 'oversight-dev-908cf.firebaseapp.com',
    databaseURL: 'https://oversight-dev-908cf.firebaseio.com',
    projectId: 'oversight-dev-908cf',
    storageBucket: 'oversight-dev-908cf.appspot.com',
    messagingSenderId: '143394371691',
    appId: '1:143394371691:web:97c15214394146b259d92e',
    measurementId: 'G-X4KW744JL2',
  },
};

const firebaseConfig = process.env.REACT_APP_ENV === 'prod' ? firebase.prod : firebase.dev;

export default firebaseConfig;
