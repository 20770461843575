export const getNumberOfWorkingDays = (startDate, endDate) => {
  // Validate input
  if (endDate < startDate) return 0;

  // Calculate days between dates
  const millisecondsPerDay = 86400 * 1000; // Day in milliseconds
  startDate.setHours(0, 0, 0, 1); // Start just after midnight
  endDate.setHours(23, 59, 59, 999); // End just before midnight
  const diff = endDate - startDate; // Milliseconds between datetime objects
  let days = Math.ceil(diff / millisecondsPerDay);

  // Subtract two weekend days for every week in between
  const weeks = Math.floor(days / 7);
  days -= weeks * 2;

  // Handle special cases
  const startDay = startDate.getDay();
  const endDay = endDate.getDay();

  // Remove weekend not previously removed.
  if (startDay - endDay > 1) days -= 2;

  // Remove start day if span starts on Sunday but ends before Saturday
  if (startDay === 0 && endDay !== 6) days -= 1;

  // Remove end day if span ends on Saturday but starts after Sunday
  if (endDay === 6 && startDay !== 0) days -= 1;

  return days;
};
