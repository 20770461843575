import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Avatar, Badge, AppBar, Toolbar, Typography, IconButton, Popover } from '@material-ui/core';

import { Menu, MenuOpen, NotificationsOutlined as NotificationsIcon } from '@material-ui/icons';

import { useAuth } from 'auth';

import { NotificationList } from '../NotificationList';
import { SettingsMenu } from '../SettingsMenu';
import { useStyles } from './Topbar.css';

const Topbar = ({ className, title, onToggleSidebar, isSidebarOpen }) => {
  const classes = useStyles();
  const auth = useAuth();
  const profileImageUrl = auth.user.photoURL || '/images/avatar-blank.png';

  // const { keycloak } = useAuthContext();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const rootClassName = classNames(classes.root, className);

  // const onLogout = useCallback(() => keycloak.logout('/'), [keycloak]);
  const handleMenu = useCallback(e => setAnchorEl(e.currentTarget), []);
  const handleClose = useCallback(() => setAnchorEl(null), []);

  // const [globalState, dispatch] = useGlobalState();
  const [notificationsEl, setNotificationsEl] = useState(null);
  const showNotifications = Boolean(notificationsEl);

  const handleShowNotifications = useCallback(event => setNotificationsEl(event.currentTarget), []);
  const handleCloseNotifications = useCallback(() => setNotificationsEl(null), []);

  const { notifications } = auth.user;
  const count = notifications.length;

  const badgeClasses = {
    dot: classes.dot,
  };

  return (
    <div className={rootClassName}>
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            className={classes.menuButton}
            aria-label="Menu"
            onClick={onToggleSidebar}
            data-cy="sidebarToggle"
          >
            {isSidebarOpen ? <MenuOpen /> : <Menu />}
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
          <IconButton className={classes.notificationsButton} onClick={handleShowNotifications}>
            <Badge
              classes={badgeClasses}
              badgeContent={count}
              color="primary"
              variant="dot"
              overlap="rectangular"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <div>
            <IconButton onClick={handleMenu}>
              <Avatar
                alt="avatar"
                className={classes.avatar}
                src={profileImageUrl}
                style={{ borderRadius: 10 }}
              />
            </IconButton>
          </div>
        </Toolbar>
        <Popover
          anchorEl={notificationsEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleCloseNotifications}
          open={showNotifications}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <NotificationList
            user={auth.user}
            notifications={notifications}
            onSelect={handleCloseNotifications}
          />
        </Popover>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={handleClose}
          open={open}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <SettingsMenu />
        </Popover>
      </AppBar>
    </div>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onToggleSidebar: PropTypes.func,
  isSidebarOpen: PropTypes.bool,
  title: PropTypes.string,
};

Topbar.defaultProps = {
  className: 'topbar',
  title: '',
  onToggleSidebar: () => {},
};

export default Topbar;
