import { useContext, useEffect, useState, useCallback } from 'react';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import { FirebaseContext } from '../utils/firebase';
import { useAuth } from '../auth';

const useTenant = () => {
  const { user } = useAuth();
  const { firebaseApp } = useContext(FirebaseContext);
  const [tenant, setTenant] = useState();
  const database = getFirestore(firebaseApp);

  const getTenant = useCallback(async () => {
    const tenantRef = doc(database, 'tenants', user.tenant.id);
    const userSnap = await getDoc(tenantRef);

    const tenant = userSnap.data();

    return tenant;
  }, [database, user.tenant.id]);

  useEffect(() => {
    async function fetchTenant() {
      setTenant(await getTenant());
    }
    fetchTenant();
  }, [getTenant]);

  return tenant;
};

export { useTenant };
