import React from 'react';

import { Grid, Typography, CircularProgress } from '@material-ui/core';
import GoogleButton from 'react-google-button';

import { useAuth } from 'auth';
import useStyles from './styles';

const SignIn = () => {
  const classes = useStyles();

  const auth = useAuth();
  const { loading } = auth;

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.leftWrapper} item lg={4}>
          <div className={classes.leftSection} />
        </Grid>
        <Grid className={classes.content} item lg={8} xs={12}>
          <div className={classes.instructions}>
            {loading ? (
              <>
                <Typography className={classes.title} variant="h4">
                  Loading...
                </Typography>
                <div className={classes.signIn}>
                  <CircularProgress className={classes.progress} />
                </div>
              </>
            ) : (
              <>
                <div className={classes.logo} />
                <Typography className={classes.title} variant="h4">
                  Sign In
                </Typography>
                <Typography className={classes.subtitle} variant="body1">
                  Please use ProductDock Google account.
                </Typography>
                <div className={classes.signIn}>
                  <GoogleButton onClick={auth.signIn} />
                </div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignIn;
