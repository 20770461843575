/* eslint-disable no-param-reassign */
import React, { useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import cx from 'classnames';
import { DescriptionOutlined as DescriptionIcon } from '@material-ui/icons';
import DateFnsUtils from '@date-io/moment';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { createNewBudgetCosts } from 'utils/functions/budgetCostsHelpers';
import { budgetCostItems, eduBudgetTypes } from 'constants/eduBudget';
import {
  Portlet,
  PortletToolbar,
  PortletContent,
  PortletFooter,
} from '../../../components/Portlets';
import { TextFieldComponent } from '../../../components/TextFieldComponent';
import { useStyles } from './EduBudgetRequestForm.css';

const EduBudgetRequestForm = ({
  formProps: {
    values,
    errors,
    isValid,
    handleBlur,
    touched,
    dirty,
    setFieldValue,
    submitForm,
    handleChange,
    handleReset,
  },
}) => {
  const classes = useStyles();
  const [selectedBudgetCosts, setSelectedBudgetCosts] = useState([]);
  const isEduBudgetFormEnabled = new Date().getMonth() !== 11;

  const onSubmitButtonClick = async () => {
    await submitForm();
  };

  const handleBugdetCostChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedBudgetCosts(typeof value === 'string' ? value.split(',') : value);
  };

  const resetForm = () => {
    setSelectedBudgetCosts([]);
    handleReset();
  };

  const handleSelectChange = event => {
    resetForm();
    setFieldValue('type', event.target.value);
  };

  const onStartDateChange = (startDate, endDate) => {
    setFieldValue('startDate', startDate);
    if (!endDate) {
      endDate = startDate;
      setFieldValue('endDate', endDate);
    } else if (endDate < startDate) {
      endDate = startDate;
      setFieldValue('endDate', endDate);
    }
  };

  const onEndDateChange = (startDate, endDate) => {
    setFieldValue('endDate', endDate);
    if (endDate < startDate) {
      startDate = endDate;
      setFieldValue('startDate', startDate);
    }
  };

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      {isEduBudgetFormEnabled ? (
        <>
          <PortletContent className={classes.portletContent}>
            <Typography variant="body2" component="span" className={classes.title}>
              Education Budget Request
            </Typography>
            <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel disabled id="select-standard-label">
                Type
              </InputLabel>
              <Select
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
                labelId="select-standard-label"
                id="select-standard"
                value={values.type || ''}
                onChange={handleSelectChange}
                label="Type"
                data-cy="eduBudgetType"
              >
                {eduBudgetTypes.map(type => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {(values.type === 'Conference' || values.type === 'Workshop') && (
              <>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div>
                    <KeyboardDatePicker
                      fullWidth
                      variant="inline"
                      autoOk
                      format="DD.MM.YYYY"
                      placeholder="DD.MM.YYYY"
                      margin="normal"
                      id="start-date"
                      name="startDate"
                      label={
                        values.type === 'Conference'
                          ? 'Conference Start Date (inclusive)'
                          : 'Workshop Start Date (inclusive)'
                      }
                      value={values.startDate || null}
                      onChange={value => onStartDateChange(value, values.endDate)}
                      TextFieldComponent={TextFieldComponent}
                      KeyboardButtonProps={{
                        'aria-label': 'change start date',
                        'data-cy': 'startDateIcon',
                      }}
                      error={touched.startDate && Boolean(errors.startDate)}
                      onBlur={handleBlur}
                      helperText={errors.startDate && touched.startDate ? errors.startDate : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      PopoverProps={{
                        'data-cy': 'popover',
                      }}
                      rightArrowButtonProps={{
                        'data-cy': 'nextMonthSwitch',
                      }}
                    />
                  </div>
                  <div className={classes.endDate}>
                    <KeyboardDatePicker
                      fullWidth
                      variant="inline"
                      autoOk
                      format="DD.MM.YYYY"
                      placeholder="DD.MM.YYYY"
                      margin="normal"
                      id="end-date"
                      name="endDate"
                      label={
                        values.type === 'Conference'
                          ? 'Conference End Date (inclusive)'
                          : 'Workshop End Date (inclusive)'
                      }
                      value={values.endDate || null}
                      onChange={value => onEndDateChange(values.startDate, value)}
                      TextFieldComponent={TextFieldComponent}
                      KeyboardButtonProps={{
                        'aria-label': 'change end date',
                        'data-cy': 'endDateIcon',
                      }}
                      error={touched.endDate && Boolean(errors.endDate)}
                      onBlur={handleBlur}
                      helperText={errors.endDate && touched.endDate ? errors.endDate : ''}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      PopoverProps={{
                        'data-cy': 'popover',
                      }}
                      rightArrowButtonProps={{
                        'data-cy': 'nextMonthSwitch',
                      }}
                    />
                  </div>
                </MuiPickersUtilsProvider>
                <FormControl
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                  className={classes.budgetCosts}
                >
                  <InputLabel disabled id="select-standard-label">
                    Budget Costs
                  </InputLabel>
                  <Select
                    labelId="budgetCostsLabel"
                    id="budgetCosts"
                    multiple
                    value={selectedBudgetCosts}
                    onChange={handleBugdetCostChange}
                    renderValue={selected => selected.join(', ')}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                    onBlur={() =>
                      setFieldValue('budgetCosts', createNewBudgetCosts(selectedBudgetCosts))
                    }
                  >
                    {budgetCostItems.map(item => (
                      <MenuItem key={item} value={item}>
                        <Checkbox
                          checked={selectedBudgetCosts.indexOf(item) > -1}
                          color="primary"
                        />
                        {item === 'Enrolment fee' ? (
                          <div className={classes.enrolmentFee}>
                            <ListItemText primary={item} />
                            <span>(Final amount may include tax/VAT)</span>
                          </div>
                        ) : (
                          <ListItemText primary={item} />
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </>
            )}
            <TextField
              fullWidth
              id="total-amount"
              label="Preliminary Amount"
              name="totalAmount"
              type="number"
              placeholder="0 EUR"
              value={values.totalAmount || ''}
              onChange={handleChange}
              margin="normal"
              variant="standard"
              error={touched.totalAmount && Boolean(errors.totalAmount)}
              onBlur={handleBlur}
              helperText={errors.totalAmount && touched.totalAmount ? errors.totalAmount : ''}
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              id="title"
              label="Title"
              name="title"
              placeholder="Enter a title"
              multiline
              maxRows="4"
              value={values.title || ''}
              error={touched.title && Boolean(errors.title)}
              onBlur={handleBlur}
              margin="normal"
              helperText={errors.title && touched.title ? errors.title : ''}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />

            <TextField
              fullWidth
              id="link"
              label="Link"
              name="link"
              placeholder="Enter a link"
              multiline
              maxRows="4"
              value={values.link || ''}
              error={touched.link && Boolean(errors.link)}
              onBlur={handleBlur}
              helperText={errors.link && touched.link ? errors.link : ''}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <TextField
              fullWidth
              id="description"
              label="Description"
              name="description"
              placeholder="Enter in a description if you already have the necessary account or some other relevant information"
              multiline
              maxRows="4"
              value={values.description || ''}
              error={touched.description && Boolean(errors.description)}
              onBlur={handleBlur}
              helperText={errors.description && touched.description ? errors.description : ''}
              onChange={handleChange}
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </PortletContent>
          <PortletFooter className={classes.portletRoot}>
            <PortletToolbar className={classes.toolbarButtons}>
              <Button disabled={!dirty} onClick={() => resetForm()} variant="contained">
                Reset
              </Button>
              <div className={classes.submitButton}>
                <Button
                  disabled={
                    _.isNil(values.type) ||
                    _.isNil(values.title) ||
                    _.isNil(values.description) ||
                    _.isNil(values.link) ||
                    !isValid
                  }
                  color="primary"
                  variant="contained"
                  onClick={onSubmitButtonClick}
                  data-cy="eduBudgetSubmit"
                >
                  Submit
                </Button>
              </div>
            </PortletToolbar>
          </PortletFooter>
        </>
      ) : (
        <PortletContent className={cx(classes.portletContent, classes.disabledForm)}>
          <div>
            <DescriptionIcon className={classes.icon} fontSize="large" />
          </div>
          <Typography variant="body1" component="span" className={classes.title}>
            Can&apos;t create a new request
          </Typography>

          <Typography variant="body2" component="span" className={classes.description}>
            The Education Budget Request form was disabled in December due to current year budget
            reconciliation.
          </Typography>
          <br />
          <Typography variant="body2" component="span" className={classes.description}>
            Get ready! The new budget will be available on 1st January.
          </Typography>
        </PortletContent>
      )}
    </Portlet>
  );
};

export default EduBudgetRequestForm;
