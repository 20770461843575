const BASE_URL =
  process.env.REACT_APP_ENV === 'prod'
    ? 'https://europe-west1-oversight-prod-43a01.cloudfunctions.net/api'
    : 'https://europe-west1-oversight-dev-908cf.cloudfunctions.net/api';

const apiClient = async (method, url, token, data = {}) => {
  const options = {
    method,
    headers: new Headers({
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
    }),
    body: JSON.stringify(data),
  };

  if (method === 'GET') {
    delete options.body;
  }

  const response = await fetch(`${BASE_URL}${url}`, options).then(res => res.json());

  if (response.statusCode === 500) {
    throw response;
  }

  return response;
};

export { apiClient };
