import React, { useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';

import { Drawer, withWidth } from '@material-ui/core';
import { AuthContext } from 'auth';
import { doc, getFirestore, onSnapshot } from 'firebase/firestore';
import { FirebaseContext } from 'utils/firebase';
import { useStyles } from './Layout.css';
import { Sidebar } from '../Sidebar';
import { Topbar } from '../Topbar';
import { Footer } from '../Footer';

const DashboardLayout = ({ viewName, children, width }) => {
  const classes = useStyles();
  const isMobile = ['xs', 'sm', 'md'].includes(width);
  const [isOpen, setIsOpen] = useState(!isMobile);
  const shiftTopbar = isOpen && !isMobile;
  const shiftContent = isOpen && !isMobile;
  const context = useContext(AuthContext);
  const { firebaseApp } = useContext(FirebaseContext);

  const database = getFirestore(firebaseApp);

  const handleClose = useCallback(() => setIsOpen(false), []);
  const handleToggleOpen = useCallback(() => setIsOpen(prevState => !prevState), []);

  const {
    auth: { user },
    updateUser,
  } = context;
  const getUser = snap => ({ ...snap.data(), uid: snap.id });

  onSnapshot(
    doc(database, 'users', user.uid),
    snapshot => {
      const update = getUser(snapshot);

      if (!_.isEqual(update, user)) {
        updateUser(context, update);
      }
    },
    err => {
      console.log(`Encountered error: ${err}`);
    },
  );

  return (
    <>
      <Topbar
        className={classNames(classes.topbar, {
          [classes.topbarShift]: shiftTopbar,
        })}
        isSidebarOpen={isOpen}
        onToggleSidebar={handleToggleOpen}
        title={viewName}
      />
      <Drawer
        anchor="left"
        classes={{ paper: classes.drawerPaper }}
        onClose={handleClose}
        open={isOpen}
        variant={isMobile ? 'temporary' : 'persistent'}
      >
        <Sidebar className={classes.sidebar} />
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: shiftContent,
        })}
      >
        <div className={classes.contentWrapper}>{children}</div>
        <Footer className="footer" />
      </main>
    </>
  );
};

DashboardLayout.propTypes = {
  viewName: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default withWidth()(DashboardLayout);
