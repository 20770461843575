import React from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    justifyContent: 'flex-end',
    alignItems: 'center',
    display: 'flex',
  },
}));

const PortletToolbar = props => {
  const { className, children, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(classes.root, className);

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

PortletToolbar.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

PortletToolbar.defaultProps = {
  children: undefined,
  className: 'portlet-toolbar',
};

export default PortletToolbar;
