import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    paddingTop: 5,
    borderTop: '1px solid rgba(0, 0, 0, 0.1)',
  },
  item: {
    display: 'flex',
    alignItems: 'center',

    '& :first-child': {
      marginRight: 5,
    },
  },
}));
