import React from 'react';
import PropTypes from 'prop-types';

import { Chip, ListItemText, IconButton, CircularProgress } from '@material-ui/core';

import { Close as CloseIcon } from '@material-ui/icons';
import { useStyles } from './Details.css';

const Details = ({ item, isLoading, handleClose, handleLinkClick }) => {
  const classes = useStyles();

  return (
    <div className={classes.item}>
      <ListItemText>
        <div>
          <span>Your {item.for} request has been </span>
          <strong>{item.status}</strong>
        </div>

        <div className={classes.subText}>
          <Chip
            size="small"
            color="primary"
            variant="outlined"
            component="span"
            onClick={handleLinkClick}
            label="Click to see details"
            classes={{ root: classes.chip }}
          />

          {isLoading && <CircularProgress className={classes.progress} color="primary" size={10} />}
        </div>
      </ListItemText>

      <IconButton
        size="small"
        onClick={handleClose}
        className={classes.closeButton}
        aria-label="close"
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

Details.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  item: PropTypes.object.isRequired,
  handleLinkClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default Details;
