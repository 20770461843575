import { makeStyles } from '@material-ui/styles';

export default makeStyles(() => ({
  formControl: {
    width: '11.5rem',

    '@media (max-width:480px)': {
      width: '100%',
      marginTop: '0.75rem',
    },

    '&:not(:last-child)': {
      marginRight: 15,
    },
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    marginTop: '16px',
  },
  separator: {
    margin: '0 5px',
  },
  date: {
    fontSize: '0.8rem',
  },
}));
