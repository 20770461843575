import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
  },
  title: {
    '@media (max-width:480px)': {
      '& h5': {
        fontSize: '1.3rem',
      },
      '& h6': {
        marginLeft: '0.5rem',
        fontSize: '0.7rem',
        width: '3rem',
      },
    },
  },
  portletContent: {
    minWidth: '600px',
    overflow: 'auto',
  },
  contained: {
    color: 'red',
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
  tableRow: {
    cursor: 'pointer',
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  status: {
    marginRight: theme.spacing(1),
  },
  empty: {
    padding: 24,
    paddingTop: 0,
    fontWeight: 300,
    color: '#777',
  },
  cancelButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#c62828',
    },
  },
  expendableRow: {
    height: '0px',
    padding: 0,
    borderBottom: 'unset',
    borderTop: 'unset',
    boxShadow: 'none',
  },
  totalAmount: {
    display: 'flex',
    alignItems: 'center',
  },
  accessTime: {
    marginRight: '0.5rem',
    color: '#686868',
    width: '1.2rem',
    height: '1.2rem',
  },
  done: {
    color: '#65B32E',
    marginRight: '0.5rem',
    width: '1.2rem',
    height: '1.2rem',
  },
  detailsWrapper: {
    marginTop: '1.5rem',
    marginBottom: '2rem',
    marginRight: '1rem',
    padding: '1rem',
    border: `1px solid ${theme.palette.border}`,
    width: '100%',
  },
  sectionTitle: {
    marginBottom: '0.5rem',
    color: 'black',
    fontWeight: '300',
    fontSize: '0.875rem',
  },
  openCell: {
    borderBottom: 'unset',
    boxShadow: 'none',
  },
  openRow: {
    boxShadow: 'none',
  },
  section: {
    marginBottom: '1rem',
    fontWeight: '400',
    color: 'black',
  },
  collapseTitle: {
    color: 'black',
    marginBottom: '1rem',
  },
  arrowCell: {
    width: '1rem',
  },
  collapsed: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  attachmentWrapper: {
    marginTop: '1.5rem',
    padding: '1.5rem 1rem',
    border: `1px solid ${theme.palette.border}`,
    width: '30rem',
    marginBottom: '2rem',
  },
  attachmentTitle: {
    borderBottom: `1px solid ${theme.palette.border}`,
    marginBottom: '1rem',
    paddingBottom: '1rem',
  },
  noFiles: {
    color: ' #979797',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  titleNoAttachment: {
    width: '100%',
    marginRight: '2rem',
  },
}));
