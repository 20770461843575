import React, { useContext } from 'react';
import { Badge, Button, ListSubheader } from '@material-ui/core';
import { doc, getFirestore, updateDoc } from 'firebase/firestore';
import { FirebaseContext } from 'utils/firebase';
import { useStyles } from './SubHeader.css';

const SubHeader = ({ notifications, user }) => {
  const classes = useStyles();
  const { firebaseApp } = useContext(FirebaseContext);
  const database = getFirestore(firebaseApp);

  const badgeClass = {
    badge: classes.badge,
  };

  const handleClear = async () => {
    return updateDoc(doc(database, 'users', user.uid), { notifications: [] });
  };

  return (
    <ListSubheader className={classes.heading} component="div">
      <Badge
        badgeContent={notifications.length || 0}
        classes={badgeClass}
        color="primary"
        overlap="rectangular"
      >
        Notifications
      </Badge>

      <Button onClick={handleClear} className={classes.link}>
        Clear all
      </Button>
    </ListSubheader>
  );
};

export default SubHeader;
