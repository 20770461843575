import React, { useState, useEffect, useContext, useCallback } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Button,
} from '@material-ui/core';
import {
  getFirestore,
  collection,
  query,
  where,
  orderBy,
  getDocs,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { withSnackbar } from 'notistack';
import { formatDate, snapToArray, setDateHourToMidnight } from '../../utils';
import { Portlet, PortletContent, PortletHeader, PortletLabel } from '../../components/Portlets';
import { Cell } from '../../components/Cell';
import { useAuth } from '../../auth';
import { FirebaseContext } from '../../utils/firebase';
import { apiClient } from '../../api-client';
import useStyles from './OutOfOfficeRequestsTable.css';

const OutOfOfficesTable = ({ enqueueSnackbar }) => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [outOfOffices, setOutOfOffices] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { firebaseApp } = useContext(FirebaseContext);
  const { user, userToken } = useAuth();
  const database = getFirestore(firebaseApp);

  const loadOutOfOffices = useCallback(async () => {
    const queryRequests = query(
      collection(database, 'out_of_offices'),
      where('userId', '==', user.uid),
      orderBy('requestedAt', 'desc'),
    );

    try {
      const snapshot = await getDocs(queryRequests);
      const result = snapToArray(snapshot);
      setOutOfOffices(result);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  }, [database, user.uid]);

  useEffect(() => {
    setLoading(true);
    loadOutOfOffices();
  }, [firebaseApp, loadOutOfOffices, user]);

  const handleDeleteEvent = async (eventFrom, eventTo, eventType) => {
    const url = '/calendar/event';
    const body = {
      calendarType: 'out-of-office',
      title: eventType,
      eventFrom: setDateHourToMidnight(eventFrom.toDate()),
      eventTo: setDateHourToMidnight(eventTo.toDate()),
      user: { name: user.displayName },
      tenantId: user.tenant.id,
    };
    const response = await apiClient('DELETE', url, userToken, body);
    if (response.statusCode > 204) {
      throw response;
    }
  };

  const deleteOutOfOffice = async (id, startDate, endDate, requestType) => {
    try {
      await deleteDoc(doc(database, 'out_of_offices', id));
      enqueueSnackbar('Out of office request successfully deleted', {
        variant: 'success',
      });
      loadOutOfOffices();
      try {
        await handleDeleteEvent(startDate, endDate, requestType);
      } catch (err) {
        enqueueSnackbar('Error when deleting event', {
          variant: 'error',
        });
        console.error(err);
      }
    } catch (error) {
      enqueueSnackbar('Error when deleting an out of office request', {
        variant: 'error',
      });
      console.error(error);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const hideActionsColumnAndCell = requests =>
    requests.filter(request => request.startDate.toDate() > new Date()).length > 0;

  return (
    <Portlet className={classes.root} outlined={1} squared={false}>
      {outOfOffices.length ? (
        <PortletHeader noDivider>
          <PortletLabel
            subtitle={`${outOfOffices.length} in total`}
            title="Latest Requests"
            className={classes.title}
          />
        </PortletHeader>
      ) : (
        ''
      )}
      <PerfectScrollbar>
        <PortletContent isLoading={isLoading} className={classes.portletContent} noPadding>
          {outOfOffices.length ? (
            <>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Request Type</TableCell>
                    <TableCell>Requested at</TableCell>
                    <TableCell>From</TableCell>
                    <TableCell>To</TableCell>
                    <TableCell>Description</TableCell>
                    {hideActionsColumnAndCell(outOfOffices) && <TableCell>Actions</TableCell>}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {outOfOffices
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(({ id, description, startDate, endDate, requestType, requestedAt }) => (
                      <TableRow className={classes.tableRow} hover key={id} data-cy={description}>
                        <Cell className={classes.tableCell}>{requestType}</Cell>
                        <Cell>{formatDate(requestedAt)}</Cell>
                        <Cell>{formatDate(startDate)}</Cell>
                        <Cell>{formatDate(endDate)}</Cell>
                        <Cell className={classes.tableCell}>{description}</Cell>
                        {hideActionsColumnAndCell(outOfOffices) && (
                          <Cell className={classes.tableCell}>
                            {startDate.toDate() > new Date() ? (
                              <Button
                                size="small"
                                onClick={() => {
                                  deleteOutOfOffice(id, startDate, endDate, requestType);
                                }}
                                className={classes.deleteButton}
                                data-cy="deleteButton"
                              >
                                DELETE
                              </Button>
                            ) : (
                              <div />
                            )}
                          </Cell>
                        )}
                      </TableRow>
                    ))}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                component="div"
                count={outOfOffices.length}
                page={page}
              />
            </>
          ) : (
            <div className={classes.empty}>No out of office requests found</div>
          )}
        </PortletContent>
      </PerfectScrollbar>
    </Portlet>
  );
};

export default withSnackbar(OutOfOfficesTable);
