import React, { useState, useEffect } from 'react';
import { withSnackbar } from 'notistack';
import { Button, TextField } from '@material-ui/core';
import { DarkTooltip } from '../DarkTooltip';
import useStyles from './InlineEdit.css';

const InlineEdit = ({ id, value, name, onUpdate, enqueueSnackbar, tooltipTitle, min }) => {
  const [editingValue, setEditingValue] = useState(value);
  const [clicked, setClicked] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setEditingValue(value);
  }, [value]);

  const handleChange = event => {
    if (event.target.value < min) return;
    setEditingValue(Number(event.target.value));
  };

  const handleClick = () => {
    setClicked(true);
  };

  const update = async value => {
    try {
      await onUpdate(id, { [name]: value });
      enqueueSnackbar('Successfully updated', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const onEnter = async event => {
    if (event.key === 'Enter') {
      if (value !== Number(event.target.value)) {
        update(Number(event.target.value));
      }
      setClicked(false);
    }
  };

  const handleBlur = async event => {
    setClicked(false);
    if (value !== Number(event.target.value)) {
      update(Number(event.target.value));
    }
  };

  const editComponent = tooltipTitle ? (
    <DarkTooltip title={tooltipTitle}>
      <Button type="button" onClick={handleClick} className={classes.button} data-cy={name}>
        {value}
      </Button>
    </DarkTooltip>
  ) : (
    <Button type="button" onClick={handleClick} className={classes.button} data-cy={name}>
      {value}
    </Button>
  );

  return clicked ? (
    <TextField
      value={editingValue || ''}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder="123"
      type="number"
      onKeyPress={onEnter}
      className={classes.text}
      InputProps={{
        inputProps: {
          min,
        },
      }}
      autoFocus
    />
  ) : (
    editComponent
  );
};

export default withSnackbar(InlineEdit);
