import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { CircularProgress } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    flexGrow: 1,
  },
  noPadding: {
    padding: 0,
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
  },
}));

const PortletContent = ({ className, children, noPadding, isLoading, ...rest }) => {
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes.noPadding]: noPadding,
    },
    className,
  );

  if (isLoading) {
    return (
      <div className={classes.progressWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div {...rest} className={rootClassName}>
      {children}
    </div>
  );
};

PortletContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  noPadding: PropTypes.bool,
  isLoading: PropTypes.bool,
};

PortletContent.defaultProps = {
  children: undefined,
  className: 'portlet-content',
  noPadding: true,
};

export default PortletContent;
