import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import { Checkbox, Divider, InputLabel, ListItemText } from '@material-ui/core';
import {
  renderCapitalised,
  renderStatusFilter,
  SELECT_ALL_VALUE,
} from 'utils/functions/filterHelpers';
import { STATUS_ITEMS } from 'constants/requestStatus';
import useStyles from './Controls.css';

const Controls = ({
  statusFilter,
  setStatusFilter,
  setPage,
  setNameFilter,
  nameFilter,
  vacationPeriod,
  setVacationPeriod,
  periods,
}) => {
  const classes = useStyles();

  const value = vacationPeriod || vacationPeriod.key === 0 ? vacationPeriod.key : '';

  const statusItems = STATUS_ITEMS.filter(status => status !== 'completed');

  const renderDate = value => {
    const period = periods.find(({ key }) => key === value);

    return (
      <>
        <span className={classes.date}>{period.start.format('DD.MM.YYYY')}</span>
        <span className={classes.separator}> - </span>
        <span className={classes.date}>{period.end.format('DD.MM.YYYY')} </span>
      </>
    );
  };

  const handleChange = event => {
    const {
      target: { value },
    } = event;
    const newStatusFilter = typeof value === 'string' ? value.split(',') : value;

    if (!statusFilter.includes(SELECT_ALL_VALUE) && newStatusFilter.includes(SELECT_ALL_VALUE)) {
      setStatusFilter([...STATUS_ITEMS, SELECT_ALL_VALUE]);
    } else if (
      statusFilter.includes(SELECT_ALL_VALUE) &&
      !newStatusFilter.includes(SELECT_ALL_VALUE)
    ) {
      setStatusFilter([]);
    } else if (
      statusFilter.includes(SELECT_ALL_VALUE) &&
      newStatusFilter.includes(SELECT_ALL_VALUE) &&
      statusFilter.length !== newStatusFilter.length
    ) {
      const index = newStatusFilter.indexOf(SELECT_ALL_VALUE);
      if (index > -1) {
        newStatusFilter.splice(index, 1);
      }
      setStatusFilter(newStatusFilter);
    } else {
      setStatusFilter(newStatusFilter);
    }

    setPage(0);
  };

  const handleNameFilterChange = event => {
    setNameFilter(event.target.value);
  };

  const handleVacationPeriodFilterChange = ({ target: { value } }) => {
    let result = periods.find(({ key }) => key === value);

    if (value === '') {
      result = '';
    }

    setVacationPeriod(result);
  };

  return (
    <div className={classes.controls}>
      <div className={classes.filters}>
        <FormControl className={classes.formControl}>
          <TextField
            onChange={handleNameFilterChange}
            value={nameFilter}
            id="name-filter"
            label="Name"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="period-filter-label">Period</InputLabel>
          <Select
            value={value}
            renderValue={renderDate}
            className={classes.select}
            onChange={handleVacationPeriodFilterChange}
            labelId="period-filter-label"
            label="Period"
            id="period-filter"
          >
            <MenuItem key="clear" value="">
              Clear
            </MenuItem>

            {periods.map(value => (
              <MenuItem key={value.key} value={value.key}>
                {renderDate(value.key)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl}>
          <InputLabel id="status-filter-label">Status</InputLabel>
          <Select
            className={classes.select}
            value={statusFilter}
            onChange={handleChange}
            id="status-filter"
            multiple
            renderValue={statusFilter => renderStatusFilter(statusFilter)}
            labelId="status-filter-label"
            label="Status"
          >
            <MenuItem key={SELECT_ALL_VALUE} value={SELECT_ALL_VALUE}>
              <Checkbox checked={statusFilter.indexOf(SELECT_ALL_VALUE) > -1} color="primary" />
              <ListItemText primary={SELECT_ALL_VALUE} />
            </MenuItem>
            <Divider />
            {statusItems.map(value => (
              <MenuItem key={value} value={value}>
                <Checkbox checked={statusFilter.indexOf(value) > -1} color="primary" />
                <ListItemText primary={renderCapitalised(value)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

Controls.defaultProps = {
  vacationPeriod: '',
};

Controls.propTypes = {
  statusFilter: PropTypes.array.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  setNameFilter: PropTypes.func.isRequired,
  setPage: PropTypes.func.isRequired,
  nameFilter: PropTypes.string.isRequired,
  setVacationPeriod: PropTypes.func.isRequired,
  periods: PropTypes.array.isRequired,
  vacationPeriod: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default Controls;
