import { collection, getDocs, where, query, orderBy, getFirestore } from 'firebase/firestore';
import { dateRangesOverlapWithinYear, snapToArray } from '../../../utils';

const getUserOutOfOfficeRequests = async (firebaseApp, user) => {
  const database = getFirestore(firebaseApp);

  const queryRequests = query(
    collection(database, 'out_of_offices'),
    where('userId', '==', user.uid),
    orderBy('requestedAt', 'desc'),
  );

  const snapshot = await getDocs(queryRequests);
  return snapToArray(snapshot);
};

const isAnOverlapOutOfOfficeRequest = async (startDate, endDate, firebaseApp, user) => {
  let isAnOverlap = false;

  const outOfOfficeRequests = await getUserOutOfOfficeRequests(firebaseApp, user);

  outOfOfficeRequests.forEach(request => {
    // determine whether two date ranges overlap
    if (
      dateRangesOverlapWithinYear(
        new Date(startDate),
        new Date(endDate),
        request.startDate.toDate(),
        request.endDate.toDate(),
      )
    ) {
      console.error('There is an overlap between the date ranges');
      isAnOverlap = true;
    }
  });
  return isAnOverlap;
};

export { isAnOverlapOutOfOfficeRequest };
