import moment from 'moment';

export default (input, format = 'MMMM DD, YYYY') => {
  let date = input;
  if (!date) return '-';

  if (typeof date === 'object') {
    date = date.toDate();
  }

  return moment(date).format(format);
};
