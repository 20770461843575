import React from 'react';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'inline-block',
    borderRadius: '50%',
    flexGrow: 0,
    flexShrink: 0,
  },
  sm: {
    height: theme.spacing(1),
    width: theme.spacing(1),
  },
  md: {
    height: theme.spacing(2),
    width: theme.spacing(2),
  },
  lg: {
    height: theme.spacing(3),
    width: theme.spacing(3),
  },
  neutral: {
    backgroundColor: theme.palette.common.neutral,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  pending: {
    backgroundColor: theme.palette.pending.main,
  },
  info: {
    backgroundColor: theme.palette.info.main,
  },
  warning: {
    backgroundColor: theme.palette.warning.main,
  },
  danger: {
    backgroundColor: theme.palette.danger.main,
  },
  success: {
    backgroundColor: theme.palette.success.main,
  },
}));

const Status = props => {
  const { className, size, color, ...rest } = props;
  const classes = useStyles();

  const rootClassName = classNames(
    {
      [classes.root]: true,
      [classes[size]]: size,
      [classes[color]]: color,
    },
    className,
  );

  return <span {...rest} className={rootClassName} />;
};

Status.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['neutral', 'primary', 'info', 'success', 'warning', 'danger', 'pending']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

Status.defaultProps = {
  className: 'status-component',
  color: 'default',
  size: 'md',
};

export default Status;
