import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
    // borderBottom: `1px solid ${theme.palette.border}`,
    boxShadow: theme.custom.shadows.navbar,
    zIndex: theme.zIndex.appBar,
    flexGrow: 1,
    height: '64px',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  dot: {
    top: 3,
    right: 3,
  },
}));
