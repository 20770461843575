import { makeStyles } from '@material-ui/styles';
import { grey } from '../../theme/colors';

export default makeStyles(() => ({
  root: {
    background: 'none',
    border: 'none',
    boxShadow: 'none',
  },
  header: {
    '@media (max-width:480px)': {
      display: 'table',
      paddingBottom: '1.5rem',
    },
  },

  eduBudgetCell: {
    width: '12.5%',
  },
  title: {
    '@media (max-width:480px)': {
      '& h5': {
        width: '9rem',
        fontSize: '1.3rem',
      },
      '& h6': {
        marginLeft: '0rem',
        fontSize: '0.7rem',
        width: '3rem',
      },
    },
  },
  form: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'row',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  empty: {
    padding: 24,
    paddingTop: 0,
    fontWeight: 300,
    color: '#777',
  },
  select: {
    width: '10.5rem',
    '@media (max-width:480px)': {
      width: '100%',
    },
  },
  search: {
    marginRight: '2rem',
    '@media (max-width:480px)': {
      marginBottom: '1rem',
      marginTop: '0.5rem',
      width: '100%',
    },
  },
  filters: {
    display: 'flex',
    alignItems: 'baseline',
  },
  avatar: {
    width: '2rem',
    height: '2rem',
    marginRight: '1rem',
  },
  employeeCell: {
    display: 'flex',
    alignItems: 'center',
  },
  explanationText: {
    fontSize: '0.938rem',
  },
  dropdown: {
    width: '15rem',
  },
  filterArchivedBackground: {
    backgroundColor: grey[200],
  },
  actionArchiveBackground: {
    backgroundColor: '#FEF6F4',
  },
}));
