import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import {
  Typography,
  Paper,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  withStyles,
  LinearProgress,
} from '@material-ui/core';
import { useStyles } from './BillableDaysTile.css';
import { apiClient } from '../../../api-client';
import { useAuth } from '../../../auth';

// to be used from shared file later
const DarkTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: '0.5rem',
    fontSize: 12,
    lineHeight: '1.12rem',
    marginTop: '1rem',
    maxWidth: '10rem',
  },
}))(Tooltip);

const BillableDaysTile = () => {
  const classes = useStyles();
  const { user, userToken } = useAuth();
  const [billableDaysForLastSevenMonths, setBillableDaysForLastSevenMonths] = useState([]);
  const isLoaded = billableDaysForLastSevenMonths.length > 0;
  // current month excluded
  const billableDaysForLastSixMonths = billableDaysForLastSevenMonths.slice(0, -1);
  const numOfLoggedMonths = billableDaysForLastSixMonths.filter(item => item.isMonthLogged).length;
  const round = num => Math.round(num * 100) / 100;
  const averageBillableDaysForLastSixMonths =
    round(
      billableDaysForLastSixMonths.map(item => item.monthBillableDays).reduce((a, b) => a + b, 0) /
        numOfLoggedMonths,
    ) || 0;

  const billableDaysForCurrentMonth = billableDaysForLastSevenMonths.slice(-1)[0];
  const areLastSixMonthsBillable =
    billableDaysForLastSixMonths.filter(item => item.monthBillableDays > 0).length > 0;
  const areLastSixMonthsLogged =
    billableDaysForLastSixMonths.filter(item => item.isMonthLogged).length > 0;

  useEffect(() => {
    async function getBillableDaysForLastSevenMonths() {
      const url = `/tempo/billable-days?userEmail=${user.email}`;
      const response = await apiClient('GET', url, userToken);
      setBillableDaysForLastSevenMonths(response.billableDaysForLastSevenMonths);
    }
    getBillableDaysForLastSevenMonths();
  }, [user.email, userToken]);

  const renderLastSixMonths = () => {
    const getStartAndEndMonth = () => {
      const startMonth = billableDaysForLastSixMonths[0].month.slice(0, 3);
      const endMonth = billableDaysForLastSixMonths.slice(-1)[0].month.slice(0, 3);

      return `${startMonth}-${endMonth}`;
    };

    if (!areLastSixMonthsLogged) {
      return (
        <ListItem className={cx(classes.month, classes.notLogged)}>
          <ListItemText className={cx(classes.monthBillableDays, classes.monthNotLogged)}>
            0
          </ListItemText>
          <ListItemText className={cx(classes.monthName, classes.monthNotLogged)}>
            No calculations for last six months {getStartAndEndMonth()}
          </ListItemText>
        </ListItem>
      );
    }
    if (areLastSixMonthsBillable) {
      return billableDaysForLastSixMonths.map(item => (
        <DarkTooltip
          key={item.month}
          title={
            !item.isMonthLogged
              ? 'This month is not logged - not included in the average billable days'
              : ''
          }
        >
          <ListItem
            key={item.month}
            className={cx(classes.month, !item.isMonthLogged && classes.monthNotLogged)}
          >
            <ListItemText
              className={cx(
                classes.monthBillableDays,
                !item.isMonthLogged && classes.monthNotLogged,
              )}
            >
              {item.monthBillableDays}
            </ListItemText>
            <ListItemText
              className={cx(classes.monthName, !item.isMonthLogged && classes.monthNotLogged)}
            >
              {item.month.slice(0, 3)}
            </ListItemText>
          </ListItem>
        </DarkTooltip>
      ));
    }
    return (
      <ListItem className={classes.month}>
        <ListItemText className={cx(classes.monthBillableDays, classes.monthsNotBillable)}>
          0
        </ListItemText>
        <ListItemText className={cx(classes.monthName, classes.noBillableDays)}>
          No billable days for last six months {getStartAndEndMonth()}
        </ListItemText>
      </ListItem>
    );
  };

  const renderCurrentMonth = () => {
    return (
      <DarkTooltip
        key={billableDaysForCurrentMonth.month}
        title={
          !billableDaysForCurrentMonth.isMonthLogged
            ? 'Current month is not logged - not counted in the 6-month average'
            : 'Current month - not counted in the 6-month average'
        }
      >
        <ListItem
          key={billableDaysForCurrentMonth.month}
          className={cx(
            classes.month,
            classes.currentMonth,
            !billableDaysForCurrentMonth.isMonthLogged && classes.notLogged,
            (!areLastSixMonthsBillable || !areLastSixMonthsLogged) && classes.currentMonthWidth,
          )}
        >
          <ListItemText
            className={cx(
              classes.monthBillableDays,
              !billableDaysForCurrentMonth.isMonthLogged && classes.monthNotLogged,
            )}
          >
            {billableDaysForCurrentMonth.monthBillableDays}
          </ListItemText>
          <ListItemText
            className={cx(
              classes.monthName,
              !billableDaysForCurrentMonth.isMonthLogged && classes.monthNotLogged,
            )}
          >
            {billableDaysForCurrentMonth.month.slice(0, 3)}
          </ListItemText>
        </ListItem>
      </DarkTooltip>
    );
  };

  return (
    <Paper className={classes.root} elevation={1}>
      <div className={classes.header}>
        <div>
          <Typography className={classes.title} variant="body1">
            Billable days
          </Typography>
          <Typography variant="body2">Average for the last 6 months</Typography>
        </div>
        {isLoaded ? (
          <Typography className={cx(classes.title, classes.days)} variant="body1">
            {averageBillableDaysForLastSixMonths} days
          </Typography>
        ) : (
          <LinearProgress className={classes.titleProgressbar} />
        )}
      </div>
      {isLoaded ? (
        <List className={classes.months}>
          {renderLastSixMonths()}
          <div className={classes.separator} />
          {renderCurrentMonth()}
        </List>
      ) : (
        <>
          <LinearProgress className={classes.progress} />
          <LinearProgress className={classes.progress} />
        </>
      )}
    </Paper>
  );
};

export default BillableDaysTile;
