import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(theme => ({
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    background: 'white',
  },
  link: {
    color: theme.palette.primary.main,
    fontSize: '0.85rem',
    padding: '0 10px',
    lineHeight: 1,
    minWidth: ['auto', '!important'],
    minHeight: ['auto', '!important'],

    '& .MuiButton-label': {
      fontSize: '0.7rem',
    },

    '&:hover': {
      background: 'initial',
    },
  },
  badge: {
    top: '1.45rem',
    right: '-1.25rem',
  },
}));
