import React from 'react';
import { TextField } from '@material-ui/core';

const TextFieldComponent = props => {
  return (
    <TextField
      {...props}
      onKeyDown={e => {
        e.preventDefault();
        return false;
      }}
    />
  );
};

export default TextFieldComponent;
