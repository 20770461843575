// Material helpers
import { createTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';
import transitions from './transitions';
import shadows from './shadows';

const theme = createTheme({
  palette,
  typography,
  overrides,
  transitions,
  custom: {
    shadows,
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
