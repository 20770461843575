import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import useStyles from './ConfirmationDialog.css';

const ConfirmationDialog = ({ setDialogOpen, onArchive, onDismiss, isDialogOpen, member }) => {
  const classes = useStyles();

  return (
    <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
      <DialogTitle>
        <Typography className={classes.titleText}>
          Are you sure you want to archive this account?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => setDialogOpen(false)}
          className={classes.dialogClose}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText} id="confirmation-dialog-description">
          You are about to archive the account of{' '}
          <span className={classes.contentTextBold}>
            {member?.displayName} ({member?.email}).
          </span>{' '}
        </DialogContentText>
        <DialogActions>
          <Button className={classes.buttonCancel} onClick={onDismiss}>
            Cancel
          </Button>
          <Button className={classes.buttonArchive} onClick={onArchive}>
            Archive
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmationDialog;
