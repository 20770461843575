import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useFirebase as useFirebase_ } from '../utils/firebase';
import useFirebaseAuth from './useFirebaseAuth';

const AuthContext = React.createContext();
const providerSignature = Symbol('AuthContext');

const AuthProvider = ({ children, useFirebase }) => {
  const firebaseApp = useFirebase();
  const auth = useFirebaseAuth(firebaseApp);
  const [state, setState] = useState({});

  const updateUser = (context, update) =>
    setState({
      ...context,
      auth: {
        ...context.auth,
        user: {
          ...context.auth.user,
          ...update,
        },
      },
    });

  useMemo(() => {
    setState({
      signature: providerSignature,
      auth,
    });
  }, [auth]);

  const authContext = useMemo(() => ({ ...state, updateUser }), [state]);

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};
AuthProvider.propTypes = {
  children: PropTypes.element.isRequired,
  useFirebase: PropTypes.func,
};
AuthProvider.defaultProps = {
  useFirebase: useFirebase_,
};

const useAuth = () => {
  const { signature, auth } = useContext(AuthContext) || {};

  if (signature !== providerSignature) {
    throw new Error('useAuth must be a descendant of <AuthProvider/>');
  }
  return auth;
};

export { AuthProvider, useAuth, AuthContext };
