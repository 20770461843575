import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'auth';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { getCurrentVacationPeriod, getNextVacationPeriod } from './getVacationPeriods';
import { useStyles } from './VacationTile.css';
import { DarkTooltip } from '../../../components/DarkTooltip';

const VacationTileCurrent = ({ user }) => {
  const classes = useStyles();
  const { tenant } = useAuth();
  const TOTAL_VACATION_DAYS = tenant?.vacation.totalDays;

  const [currentVacationPeriod, setCurrentVacationPeriod] = useState('');
  const [nextVacationPeriod, setNextVacationPeriod] = useState('');

  useEffect(() => {
    setCurrentVacationPeriod(getCurrentVacationPeriod());
  }, [setCurrentVacationPeriod]);

  useEffect(() => {
    setNextVacationPeriod(getNextVacationPeriod());
  }, [setNextVacationPeriod]);

  return (
    <>
      <div className={classes.contentWrapper}>
        <div className={classes.content}>
          <Typography className={classes.value} variant="body1">
            <span>
              {(() => {
                if (user.availableDays < 0) {
                  return -user.availableDays;
                }
                if (user.availableDays > TOTAL_VACATION_DAYS) {
                  return 0;
                }
                return TOTAL_VACATION_DAYS - user.availableDays;
              })()}
              {TOTAL_VACATION_DAYS - user.availableDays === 1 || user.availableDays === -1
                ? ' day '
                : ' days '}
              used
            </span>
          </Typography>
          {user.availableDays > 0 || user.availableDays === 0 ? (
            <Typography className={classes.bottomLabel} variant="body2" component="div">
              {currentVacationPeriod}
            </Typography>
          ) : (
            user.availableDays < 0 && (
              <Typography className={classes.bottomLabel} variant="body2" component="div">
                {nextVacationPeriod}
              </Typography>
            )
          )}
        </div>
      </div>
      <Box position="relative" display="inline-flex">
        <CircularProgress
          size={75}
          thickness={0.5}
          className={(() => {
            if (user.availableDays < 0) {
              return classes.progressSecondary;
            }
            if (user.availableDays === 0) {
              return classes.progressTransparent;
            }
            return classes.progressPrimary;
          })()}
          variant="determinate"
          value={(() => {
            if (user.availableDays > TOTAL_VACATION_DAYS) {
              return 100;
            }
            if (user.availableDays < 0) {
              return ((TOTAL_VACATION_DAYS - user.availableDays) * 100) / TOTAL_VACATION_DAYS;
            }
            return 100 - ((TOTAL_VACATION_DAYS + user.availableDays) * 100) / TOTAL_VACATION_DAYS;
          })()}
        />
        <DarkTooltip
          title={(() => {
            if (user.availableDays === 0) {
              return 'No days left for the current period';
            }
            if (user.availableDays === -TOTAL_VACATION_DAYS) {
              return 'No days left for the next period';
            }
            if (user.availableDays < 0) {
              return 'Days left for the next period';
            }
            return 'Total number of available days';
          })()}
        >
          <Box
            top={7}
            left={7}
            bottom={7}
            right={7}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
            className={classes.counterCircle}
          >
            <Typography
              className={classes.counterUsed}
              variant="subtitle1"
              id="currentVacationDays"
            >
              {(() => {
                if (user.availableDays > TOTAL_VACATION_DAYS) {
                  return `${TOTAL_VACATION_DAYS}`;
                }
                if (user.availableDays === 0) {
                  return <span className={classes.counterDisabled}>{user.availableDays}</span>;
                }
                if (user.availableDays < 0) {
                  return (
                    <span
                      className={
                        user.availableDays === -TOTAL_VACATION_DAYS ? classes.counterDisabled : ''
                      }
                    >
                      {(-user.availableDays - TOTAL_VACATION_DAYS) * -1 || TOTAL_VACATION_DAYS}
                    </span>
                  );
                }
                return `${user.availableDays}`;
              })()}
            </Typography>
          </Box>
        </DarkTooltip>
      </Box>
    </>
  );
};

VacationTileCurrent.propTypes = {
  user: PropTypes.object,
};

VacationTileCurrent.defaultProps = {
  user: {
    availableDays: 0,
  },
};

export default VacationTileCurrent;
